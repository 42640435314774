export default function (instance, user) {
    const {can, cannot} = instance;
    const isAdmin = user?.roles.includes(`Admin`);
    const isTop = user?.roles.includes(`Top`);
    const isStaff = user?.roles.includes(`Staff`);
    const isPTO_BY = user?.roles.includes(`PTO_BY`);
    const isPTO_RU = user?.roles.includes(`PTO_RU`);
    const isCurator = user?.roles.includes(`Curator`);
    const isAudit_BY = user?.roles.includes(`Audit_BY`);
    const isAudit_RU = user?.roles.includes(`Audit_RU`);

    if (isAdmin) {
        can('manage', 'all'); // read-write access to everything
    }

    if (isTop) {
        can(`read`, `users`);
        can(`read`, `systemUsers`);
        can(`read`, `objects`);
        can(`read`, `objectsInProcess`);
        can(`read`, `workTypes`);
        can(`read`, `workShifts`);
        can(`read`, `completedWorks`);
        can(`read`, `targets`);
        can(`read`, `secondWorks`);
        can(`read`, `fuelLimits`);
        can(`read`, `gallery`);
        can(`read`, `usedFuels`);
        can(`read`, `chartsPage`);
    }

    if (isPTO_BY || isPTO_RU) {
        can(`read`, `users`);
        can(`create`, `objectsInProcess`);
        can(`update`, `objectsInProcess`);
        can(`read`, `objectsInProcess`);
        can(`create`, `workTypes`);
        can(`read`, `workTypes`);
        can(`read`, `workShifts`);
        can(`read`, `completedWorks`);
        can(`create`, `targets`);
        can(`read`, `targets`);
        can(`update`, `targets`);
        can(`delete`, `targets`);
        can(`read`, `secondWorks`);
        can(`read`, `fuelLimits`);
        can(`read`, `gallery`);
        can(`read`, `usedFuels`);
        can(`read`, `materials`);
        can(`create`, `materials`);
        can(`update`, `materials`);
        can(`delete`, `materials`);
        can(`create`, `materialTargets`);
        can(`read`, `materialTargets`);
        can(`update`, `materialTargets`);
        can(`delete`, `materialTargets`);
        can(`read`, `materialUsing`);
        can(`create`, `materialGroups`);
        can(`read`, `materialGroups`);
        can(`update`, `materialGroups`);
        can(`delete`, `materialGroups`);
        can(`read`, `chartsPage`);
    }

    if (isPTO_BY) {
        can(`create`, `objectBY`);
    }

    if (isPTO_RU) {
        can(`create`, `objectRU`);
    }

    if (isCurator) {
        can(`read`, `objectsInProcess`);
        can(`read`, `workTypes`);
        can(`read`, `workShifts`);
        can(`read`, `completedWorks`);
        can(`read`, `targets`);
        can(`read`, `secondWorks`);
        can(`read`, `fuelLimits`);
        can(`read`, `gallery`);
        can(`read`, `usedFuels`);
        can(`read`, `chartsPage`);
        can(`read`, `materials`);
        can(`read`, `materialTargets`);
        can(`read`, `materialUsing`);
        can(`read`, `materialGroups`);
    }

    if (isStaff) {
        can(`read`, `objectsInProcess`);
        can(`read`, `workTypes`);
        can(`read`, `targets`);
        can(`read`, `completedWorks`);
        can(`read`, `secondWorks`);
        can(`read`, `fuelLimits`);
        can(`read`, `gallery`);
        can(`read`, `usedFuels`);
        can(`read`, `chartsPage`);
    }

    if (isAudit_BY || isAudit_RU) {
        can(`read`, `users`);
        can(`read`, `objectsInProcess`);
        can(`read`, `workTypes`);
        can(`read`, `workShifts`);
        can(`read`, `completedWorks`);
        can(`read`, `targets`);
        can(`read`, `secondWorks`);
        can(`read`, `fuelLimits`);
        can(`read`, `gallery`);
        can(`read`, `usedFuels`);
        can(`read`, `chartsPage`);
        can(`read`, `materials`);
        can(`read`, `materialTargets`);
        can(`read`, `materialUsing`);
        can(`read`, `materialGroups`);
    }

    cannot('delete', 'Post', {published: true});
    return instance;
}