import api from './api'

export default {
    actions: {
        requests_getShifts(_, processId) {
            return api.getShifts(processId);
        },
        requests_getObjects() {
            return api.getObjects();
        },
        requests_createRequest(_, payload) {
            return api.createRequest(payload);
        },
        requests_updateRequest(_, {id, payload}) {
            return api.updateRequest(id, payload);
        },
        requests_clearStepper({commit}) {
            commit(`requests_setAvailableShifts`, []);
            commit(`requests_setSelectedShifts`, []);
            commit(`requests_setSelectedObject`, null);
            commit(`requests_setSendOnCreate`, false);
            commit(`requests_setEditRequest`, null);
            return Promise.resolve();
        },
        requests_getRequests() {
            return api.getRequests();
        },
        requests_getRequestById(_, id) {
            return api.getRequest(id);
        },
        requests_getLogs(_, id) {
            return api.getLogs(id);
        },
        requests_getComments(_, requestId) {
            return api.getComments(requestId);
        },
        requests_addComment(_, payload) {
            return api.addComment(payload);
        },
        requests_deleteComment(_, payload) {
            return api.deleteComment(payload);
        },
        requests_editComment(_, {id, payload}) {
            return api.updateComment(id, payload);
        },
        requests_reject(_, payload) {
            return api.reject(payload);
        },
        requests_approve(_, payload) {
            return api.approve(payload);
        },
        requests_sendToApprove(_, payload) {
            return api.sendToApprove(payload);
        }
    },
    state: {
        availableShifts: [],
        selectedShifts: [],
        selectedObject: null,
        sendOnCreate: false,
        sortOptions: [
            {
                text: `Id заявки`,
                value: `id`
            },
            {
                text: `Постановщик`,
                value: `author`
            },
            {
                text: `Согласовывает`,
                value: `inspector`
            },
            {
                text: `Дата создания`,
                value: `createdAt`
            },
            {
                text: `Статус заявки`,
                value: `status`
            }
        ],
        sortBy: `status`,
        sortDesc: false,
        filter: ``,
        editRequest: null,
        selectedTab: 0
    },
    mutations: {
        requests_setAvailableShifts(state, value) {
            state.availableShifts = value;
        },
        requests_setSelectedShifts(state, value) {
            state.selectedShifts = value;
        },
        requests_setSelectedObject(state, value) {
            state.selectedObject = value;
        },
        requests_setSendOnCreate(state, value) {
            state.sendOnCreate = value;
        },
        requests_setFilter(state, value) {
            state.filter = value;
        },
        requests_setSortBy(state, value) {
            state.sortBy = value;
        },
        requests_setSortDesc(state, value) {
            state.sortDesc = value;
        },
        requests_setEditRequest(state, value) {
            state.editRequest = value;
        },
        requests_setSelectedTab(state, value) {
            state.selectedTab = value;
        }
    }
}