<template>
    <div class="home">
        <h3 class="my-4 text-center" v-if="currentUser">Добро пожаловать {{ currentUser.firstName }} {{ currentUser.lastName }}</h3>
        <div class="d-flex justify-content-center mt-5">
            <img src="../assets/logo_ads_full.png" alt="ADS logo" class="logo">
            <img src="../assets/logo_argus_full.png" alt="Argus logo" class="logo">
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            currentUser() {
                return this.$store.getters?.currentUser;
            }
        },
        name: 'home'
    }
</script>

<style scoped>
    .logo {
        width: 400px;
        height: auto;
    }
</style>
