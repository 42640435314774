<template>
    <b-form-group
        label="Статус"
        label-for="status"
        label-class="small font-weight-bold"
    >
		<b-form-select v-if="canEdit" v-model="status" :options="options"></b-form-select>
        <span v-else>{{status.name}}</span>
    </b-form-group>
</template>

<script>
export default {
    name: "TaskStatus",
    computed: {
        status: {
            get() {
                return this.value;
            },
            set(v) {
                this.$emit(`input`, v);
            }
        },
        availableStatuses() {
            return this.$store.state.tasksManagement.availableStatuses;
        },
		options() {
			return this.availableStatuses.map(el => {
				return {
					value: el.id,
					text: el.name
				}
			})
		}
    },
    props: {
        value: Number,
        canEdit: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>

</style>