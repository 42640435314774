<template>
    <b-form-group
        label="Объект"
        label-for="object"
        label-class="small font-weight-bold"
    >
        <div v-if="value" class="px-1">
            <i class="fas fa-igloo mr-2"></i>
            <span>{{ value.object_name }}</span>
            <i v-if="canEdit" class="fas fa-times text-danger ml-2" @click="() => $emit(`input`, null)"></i>
        </div>
        <b-dropdown v-if="canEdit" id="dropdown-objects" no-caret variant="link">
            <template #button-content class="small p-0" v-if="!value">
                <i class="fas fa-plus mr-2"></i>
                <span>Добавить</span>
            </template>
            <b-form-input size="sm" v-model="search"></b-form-input>
            <div style="height: 300px; overflow: auto">
                <b-dropdown-item v-for="(obj, i) in objects" :key="i"
                                 @click="() => $emit(`input`, obj)">
                    <span class="small">{{ obj.object_name }}</span>
                </b-dropdown-item>
            </div>
        </b-dropdown>
    </b-form-group>
</template>

<script>
export default {
    name: "TaskBuildingObject",
    computed: {
        objects() {
            const {$store, search} = this;
            const {objectsInProcess} = $store.state;
            const searchValue = search.trim().toLowerCase();
            if (searchValue.length) {
                return objectsInProcess.filter(el => el.object_name.toLowerCase().includes(searchValue))
            } else {
                return objectsInProcess;
            }
        }
    },
    props: {
        value: Object,
        canEdit: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            search: ``
        }
    }
}
</script>

<style scoped>

</style>