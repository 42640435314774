<template>
    <div>
        <b-modal id="deleteMaterialTarget"
                 @ok="submit"
                 @show="beforeOpen"
                 ok-title="Ок"
                 ok-variant="success"
                 cancel-title="Отмена"
                 cancel-variant="danger"
        >
            <p class="my-4">
                Вы действительно хотите удалить объем материала
                <span class="text-danger">{{ material_name }}</span>
                по виду работ <span class="text-danger">{{ target_work_name }}</span>
                строительного объекта <span class="text-danger">{{ object_name }}</span>
                из системы?
            </p>
        </b-modal>
    </div>
</template>

<script>
    export default {
        computed: {
            selectedMaterialTargetId() {
                return this.$store.state.materials.selectedMaterialTargetId;
            }
        },
        data() {
            return {
                material_name: null,
                object_name: null,
                target_work_name: null
            }
        },
        methods: {
            clearForm() {
                this.name = null;
            },
            async beforeOpen() {
                const target = await this.$store.dispatch(`getMaterialTarget`, this.selectedMaterialTargetId);
                this.setTarget(target);
            },
            setTarget({material_name, object_name, target_work_name}) {
                this.material_name = material_name;
                this.object_name = object_name;
                this.target_work_name = target_work_name;
            },
            submit() {
                this.$store.dispatch(`deleteMaterialTarget`, this.selectedMaterialTargetId)
                    .then(() => this.$store.dispatch(`getMaterialTargets`));
            }
        },
        name: "DeleteMaterialTarget"
    }
</script>

<style scoped>

</style>