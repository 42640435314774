<template>
    <div>
        <VueDatePicker
                v-model="time"
                :locale="locale"
                button-validate="Ок"
                button-cancel="Отмена"
                validate
                placeholder="Выберите дату"
                clearable
                format="DD-MM-YYYY"
        />
    </div>
</template>

<script>
    import {VueDatePicker} from '@mathieustan/vue-datepicker';

    export default {
        name: "DatePicker",
        components: {
            VueDatePicker
        },
        computed: {
            time: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit(`input`, val);
                }
            }
        },
        data() {
            return {
                locale: {lang: 'ru', weekDays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']},
                currentDate: new Date()
            }
        },
        props: [`value`]

    }
</script>

<style scoped>

</style>