<template>
    <div>
        <b-table striped hover :items="options" :fields="fields">
            <template v-slot:cell(delete)="data">
                <i class="fas fa-trash-alt" @click="onDelete(data)"></i>
            </template>
        </b-table>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "UsersList",
        data() {
            return {
                fields: [
                    {
                        key: 'work_id',
                        label: 'ИД'
                    },
                    {
                        key: 'name',
                        label: 'Имя'
                    },
                    {
                        key: 'unit_of_measurement',
                        label: 'Единица измрения'
                    },
                    {
                        key: 'delete',
                        label: 'Удалить'
                    }
                ]
            }
        },
        props: {
            options: {
                type: Array,
                required: true
            }
        },
        methods: {
            onDelete(data) {
                const workId = data.item[`work_id`];
                axios.delete(`/api/types-of-work/${workId}`).then((res) => {
                    console.log(res);
                    this.$emit('workDeleted');
                }).catch(err => {
                    console.log(err);
                });
            }
        }
    }
</script>

<style scoped lang="stylus">
    .fa-trash-alt
        cursor pointer

</style>