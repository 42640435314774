<template>
    <b-form-group
        label="Помощники"
        label-for="executors"
        label-class="small font-weight-bold"
    >
        <UserCard v-for="(e, i) in items" :key="i" class="mb-1" :value="e" :canRemove="canEdit"
                  @remove="v => $emit(`remove`, v)"
        />
        <UserSelectDropdown v-if="canEdit" @change="v => $emit(`change`, v)" :filtered-users="filteredUsers"/>
    </b-form-group>
</template>

<script>
import UserCard from "@/views/Tasks/UserCard/UserCard";
import UserSelectDropdown from "@/views/Tasks/UserSelectDropdown/UserSelectDropdown";

export default {
    name: "TaskExecutors",
    components: {
        UserCard,
        UserSelectDropdown
    },
    computed: {
        filteredUsers() {
            return this.$store.state.tasksManagement.shortUsers.filter(el => !this.items.some(e => e.user_id === el.user_id));
        }
    },
    props: {
        items: Array,
        canEdit: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>

</style>