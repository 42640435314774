<template>
    <b-dropdown no-caret variant="link" id="user-select-dropdown" @shown="onShow">
        <template #button-content class="small p-0">
            <i class="fas fa-plus mr-2"></i>
            <span>Добавить</span>
        </template>
        <b-form-input size="sm" v-model="search" ref="searchField"></b-form-input>
        <div style="max-height: 300px; overflow: auto">
            <b-dropdown-item v-for="(user, i) in items" :key="i" @click="() => onSelect(user)">
                <span class="small">{{ user.first_name }} {{ user.last_name }}</span>
            </b-dropdown-item>
        </div>
    </b-dropdown>
</template>

<script>
export default {
    name: "UserSelectDropdown",
    computed: {
        items() {
            const {filteredUsers, users, search} = this;
            const searchValue = search.trim().toLowerCase();
            const arr = filteredUsers || users;
            if (searchValue.length) {
                return arr.filter(el => el.first_name.toLowerCase().includes(searchValue)
                    || el.last_name.toLowerCase().includes(searchValue)
                )
            } else {
                return arr;
            }
        },
        users() {
            return this.$store.state.tasksManagement.shortUsers;
        }
    },
    props: {
      filteredUsers: {
          type: Array,
          default: null
      }
    },
    data() {
        return {
            search: ``
        }
    },
    methods: {
        onSelect(v) {
            this.$emit(`change`, v);
            this.search = ``;
        },
        onShow() {
            this.$refs.searchField.focus();
        }
    }
}
</script>

<style scoped>

</style>