<template>
    <div>
        <b-modal id="createGroupWorkModal"
             title="Создание группы работ"
             @ok="submit()"
             @cancel="$emit(`hidden`)"
             @close="$emit(`hidden`)"
             ok-title="Ок"
             ok-variant="success"
             cancel-title="Отмена"
             cancel-variant="danger"
             no-close-on-backdrop
             no-close-on-esc
             :visible="show"
    >
        <WorkGroupItem ref="createGroupWorkModal"/>
    </b-modal>
    </div>
</template>

<script>
    import WorkGroupItem from "../../views/WorkGroups/WorkGoupItem/WorkGroupItem.vue";
    export default {
        name: "CreateWorkGroup",
        components: {
            WorkGroupItem
        },
        methods: {
            async submit() {
                const data = this.$refs.createGroupWorkModal.$data;
                const {id} = await this.$store.dispatch(`createWorkGroup`, data);
                this.$emit(`workGroupCreated`, id);
            }
        },
        props: {
            show: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style scoped>
</style>