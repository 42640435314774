export default function(options, search, keys) {
    if (search.length <= 2) {
        return options;
    } else {
        const words = search.split(` `);
        return options.filter(el => {
            let splitString = ``;
            keys.forEach(key => {
                splitString += `${el[key]} `;
            })
            return words.every(e => {
                if (splitString) {
                    return splitString.toLowerCase().indexOf(e.toLowerCase()) !== -1;
                } else {
                    return false
                }
            })
        })
    }
}