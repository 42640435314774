import ajax from '../../utils/ajax';

export default {
    actions: {
        async createMaterial(_, data) {
            await ajax.createMaterial(data);
        },
        async createMaterialGroups(_, data) {
            await ajax.createMaterialGroups(data);
        },
        async createMaterialTargets(_, data) {
            await ajax.createMaterialTargets(data);
        },
        async deleteMaterial(_, id) {
            await ajax.deleteMaterial(id);
        },
        async deleteMaterialTarget(_, id) {
            await ajax.deleteMaterialTarget(id);
        },
        async deleteMaterialGroup(_, id) {
            await ajax.deleteMaterialGroup(id);
        },
        async editMaterial({state}, data) {
            await ajax.editMaterial(state.selectedMaterialId, data);
        },
        async editMaterialGroup({state}, data) {
            await ajax.editMaterialGroup(state.selectedMaterialGroupId, data);
        },
        async editMaterialTargets({state}, data) {
            await ajax.editMaterialTarget(state.selectedMaterialTargetId, data);
        },
        async getMaterial(_, id) {
            return await ajax.getMaterial(id);
        },
        async getDirectory({commit}) {
            await ajax.getMaterials()
                .then(data => commit(`setDirectory`, data));
        },
        async getMaterialGroups({commit}) {
            await ajax.getMaterialGroups()
                .then(data => commit(`setMaterialGroups`, data));
        },
        async getMaterialGroup(_, id) {
            return await ajax.getMaterialGroup(id);
        },
        async getMaterialTargets({commit, rootState}) {
            await ajax.getMaterialTargets(rootState.showOnlyArchive)
                .then(data => commit(`setMaterialTargets`, data));
        },
        async getMaterialTarget(_, id) {
            return await ajax.getMaterialTarget(id);
        },
        async getMaterialUsage({commit, rootState}) {
            await ajax.getMaterialUsage(rootState.showOnlyArchive).then(data => commit(`setMaterialUsage`, data));
        }
    },
    state: {
        directory: [],
        groups: [],
        targets: [],
        usage: [],
        selectedMaterialId: null,
        selectedMaterialGroupId: null,
        selectedMaterialTargetId: null,
        selectedObject: null
    },
    mutations: {
        setDirectory(state, value) {
            state.directory = value;
        },
        setMaterialGroups(state, value) {
            state.groups = value;
        },
        setSelectedMaterialId(state, value) {
            state.selectedMaterialId = value;
        },
        setMaterialTargets(state, value) {
            state.targets = value;
        },
        setSelectedMaterialGroupId(state, value) {
            state.selectedMaterialGroupId = value;
        },
        setSelectedMaterialTargetId(state, value) {
            state.selectedMaterialTargetId = value;
        },
        setMaterialUsage(state, value) {
            state.usage = value;
        },
        setSelectedObject(state, value) {
            state.selectedObject = value;
        }
    }
}