<template>
    <b-form-group
        label="Прикрепленные файлы"
        label-for="files"
        label-class="small"
    >
        <div>
            <b-spinner label="Spinning" v-if="uploadFilesInProcess"></b-spinner>
            <FileList v-else :list="files" @remove="id => $emit(`removeFile`, id)" :can-edit="canEdit"/>
            <div class="d-flex justify-content-end p-2 align-items-baseline mt-3" v-if="canEdit">
                <b-form-file v-model="upload"
                             class="mt-3"
                             size="sm"
                             placeholder="Файл не выбран"
                             browse-text="Обзор"
                             @input="uploadClick"
                             name="file"
                ></b-form-file>
            </div>
        </div>
    </b-form-group>
</template>

<script>
import FileList from "./FileList/FileList";

export default {
    name: "TaskFiles",
    components: {
        FileList
    },
    props: {
        files: Array,
        canEdit: {
            type: Boolean,
            default: false
        },
        uploadFilesInProcess: Boolean
    },
    data() {
        return {
            upload: null
        }
    },
    methods: {
        uploadClick(file) {
            this.$emit(`onUpload`, file);
            this.upload = null;
        }
    }
}
</script>

<style scoped>
</style>