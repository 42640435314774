<template>
    <div>
        <AvailableFilters :data="filters" @addFilter="onAddFilter"/>
        <FilterOptions
                v-for="filter in selectedFilters"
                :key="filter.rowId"
                :filter="filter"
                :filterRowId="filter.rowId"
                :dataId="dataId"
                @deleteFilter="onDeleteFilter(filter.rowId)"
                :object="object"
        />
    </div>
</template>

<script>
    import AvailableFilters from "../../AavailableFilters/AvailableFilters.vue";
    import FilterOptions from "../../FilterOptions/FilterOptions.vue";

    export default {
        components: {
            AvailableFilters,
            FilterOptions
        },
        data() {
            return {
                selectedFilters: [],
            }
        },
        name: "ObjectContent",
        methods: {
            onAddFilter(val) {
                this.selectedFilters.push(val);
            },
            onDeleteFilter(id) {
                this.selectedFilters = this.selectedFilters.filter(el => el.rowId !== id);
            }
        },
        props: {
            dataId: String,
            object: Object,
            filters: Array
        }
    }
</script>

<style scoped>

</style>