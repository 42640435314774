<template>
    <div class="tg-webapp">
        <b-form-group
            id="startDate-form"
            label="Дата начала смены"
            label-for="startDate"
            label-class="label-class"
        >
            <input type="date" class="datepicker" id="startDate" v-model="startDate"/>
        </b-form-group>
        <b-form-group
            id="startTime-form"
            label="Время начала смены"
            label-for="startTime"
            label-class="label-class"
        >
            <input type="time" class="timepicker" id="startTime" v-model="startTime"/>
        </b-form-group>
        <b-form-group
            id="endDate-form"
            label="Дата окончания смены"
            label-for="endDate"
            label-class="label-class"
            class="mt-3"
        >
            <input type="date" class="datepicker" id="endDate" v-model="endDate" :min="getMinDate" :max="getMaxDate"/>
        </b-form-group>
        <b-form-group
            id="endTime-form"
            label="Время окончания смены"
            label-for="endTime"
            label-class="label-class"
        >
            <input type="time" class="timepicker" id="endTime" v-model="endTime"/>
        </b-form-group>
        <span class="text-danger" v-if="notValid">Дата окончания смены не может быть раньше начала смены</span>
        <b-button @click.once="send" class="d-block my-4 send-button" size="sm" :disabled="notValid">
            Отправить данные
        </b-button>
    </div>
</template>

<script>
export default {
    name: "TelegramDatepicker",
    computed: {
        notValid() {
            const {startDate, startTime, endDate, endTime, disabledData, disabledTime} = this;
            const emptyField = !startDate || !startTime || !endDate || !endTime;
            return emptyField || disabledData() || disabledTime();
        },
        getMinDate() {
            const {$moment, startDate} = this;
            return $moment(startDate).format(`YYYY-MM-DD`)
        },
        getMaxDate() {
            const { $moment } = this;
            return $moment(this.getMinDate).add(1, 'days').format('YYYY-MM-DD');
      }
    },
    data() {
        return {
            startDate: new Date(),
            startTime: new Date(),
            endDate: new Date(),
            endTime: new Date()
        }
    },
    methods: {
        send() {
            const {startDate, startTime, endDate, endTime, $moment} = this;
            const payload = {
                userId: new URLSearchParams(window.location.search).get(`user_id`),
                start: $moment(startDate + ' ' + startTime).format('YYYY-MM-DD HH:mm'),
                end: $moment(endDate + ' ' + endTime).format('YYYY-MM-DD HH:mm')
            };
            this.$store.dispatch(`sendDatePicker`, payload).finally(this.closeWebApp);
        },
        closeWebApp() {
            window.Telegram.WebApp.close();
        },
        disabledData() {
            const {$moment, startDate, endDate} = this;
            const a = $moment(endDate);
            const b = $moment(startDate);
            return a.isBefore(b, `millisecond`);
        },
        disabledTime() {
            const {$moment, startDate, endDate} = this;
            const a = $moment(endDate);
            const b = $moment(startDate);
            if (a.isSame(b, 'date')) {
                const {startTime, endTime} = this;
                const a_time = $moment(startTime, 'HH:mm');
                const b_time = $moment(endTime, 'HH:mm');
                return b_time.isBefore(a_time) || b_time.isSame(a_time)
            } else {
                return false
            }
        },
        updateEndDate() {
            this.endDate = this.startDate;
      },
        init() {
            this.setDefaultTime();
        },
        setDefaultTime() {
            const start = this.$moment();
            start.set(`hour`, 8);
            start.set(`minute`, 30);
            this.startDate = start.format('YYYY-MM-DD');
            this.startTime = start.format('HH:mm');
            const end = this.$moment();
            end.set(`hour`, 17);
            end.set(`minute`, 0);
            this.endDate = end.format('YYYY-MM-DD');
            this.endTime = end.format('HH:mm');
        }
    },
    mounted() {
        this.init();
    },
     watch: {
       startDate(newValue) {
         this.updateEndDate();
       }
     }
}
</script>

<style lang="scss">
.tg-webapp {
    background-color: var(--tg-theme-bg-color, #000);
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    .label-class {
        color: var(--tg-theme-text-color, #fff);
    }
    input {
        color-scheme: var(--tg-color-scheme, dark);
        width: 100%;
    }
    .form-group {
        min-width: 200px;
    }

    .send-button {
        color: var(--tg-theme-button-text-color, #fff);
        background-color: var(--tg-theme-button-color, #17a2b8);
        border-color: var(--tg-theme-button-color, #17a2b8);
    }
}
</style>