<template>
    <div>
        <div>
            <span class="mr-3">Время:</span>
            <input type="time" id="repeatTime" @input="timeChange" :value="time" required
                   :disabled="disabled"
                   :class="`${!disabled ? 'available-input' : 'disabled-input'}`"
            />
        </div>
        <b-form-group :disabled="disabled" class="mt-2" v-if="!onProcess">
            <b-form-radio v-model="selected" name="repeat-select" value="EveryDay">
                Ежедневно
            </b-form-radio>
            <b-form-radio v-model="selected" name="repeat-select" value="EveryWeek">
                Еженедельно
            </b-form-radio>
            <b-form-radio v-model="selected" name="repeat-select" value="EveryMonth">
                Ежемесячно
            </b-form-radio>
            <b-form-radio v-model="selected" name="repeat-select" value="EveryYear">
                Ежегодно
            </b-form-radio>
            <b-card v-if="selected === `EveryWeek`">
                <b-form-checkbox-group
                        id="checkbox-every-week"
                        :checked="daysWeek"
                        :options="days"
                        @change="onDaysWeekChange"
                        name="EveryWeek"
                />
            </b-card>
            <b-card v-if="selected === `EveryMonth`">
                <div class="d-flex align-items-baseline">
                    <span>Число месяца:</span>
                    <b-form-input
                            v-model="dayMonth"
                            :formatter="dayFormatter"
                            lazy-formatter
                            @input="setDayOfMonth"
                            size="sm"
                            class="w-25 ml-3"
                    />
                </div>
            </b-card>
            <b-card v-if="selected === `EveryYear`">
                <b-form-checkbox-group
                        id="checkbox-every-year"
                        :checked="selectedMonths"
                        :options="months"
                        @change="onMonthChange"
                        name="EveryYear"
                />
                <div class="d-flex align-items-baseline">
                    <span>Число месяца:</span>
                    <b-form-input
                            v-model="dayMonth"
                            :formatter="dayFormatter"
                            lazy-formatter
                            @input="setDayOfMonth"
                            size="sm"
                            class="w-25 ml-3"
                    />
                </div>
            </b-card>
        </b-form-group>
    </div>
</template>

<script>
export default {
    name: "MyCron",
    computed: {
        cronArray() {
            return this.cron.split(' ');
        },
        time() {
            return `${this.cronArray[1]}:${this.cronArray[0]}`;
        }
    },
    data() {
        return {
            cron: `00 10 * * *`,
            selected: `EveryDay`,
            daysWeek: [1],
            dayMonth: `1`,
            days: [
                {text: 'Понедельник', value: 1},
                {text: 'Вторник', value: 2},
                {text: 'Среда', value: 3},
                {text: 'Четверг', value: 4},
                {text: 'Пятница', value: 5},
                {text: 'Суббота', value: 6},
                {text: 'Воскресенье', value: 0}
            ],
            selectedMonths: [1],
            months: [
                {text: 'Январь', value: 1},
                {text: 'Февраль', value: 2},
                {text: 'Март', value: 3},
                {text: 'Апрель', value: 4},
                {text: 'Май', value: 5},
                {text: 'Июнь', value: 6},
                {text: 'Июль', value: 7},
                {text: 'Август', value: 8},
                {text: 'Сентябрь', value: 9},
                {text: 'Октябрь', value: 10},
                {text: 'Ноябрь', value: 11},
                {text: 'Декабрь', value: 12}
            ],
            onProcess: false
        }
    },
    methods: {
        timeChange(v) {
            if (!v.target.value) {
                v.target.value = `09:00`;
            }
            this.cron = this.cronArray.map((el, i) => {
                if (i === 0) {
                    return v.target.value.split(`:`)[1];
                }
                if (i === 1) {
                    return v.target.value.split(`:`)[0];
                }
                return el;
            }).join(' ');
        },
        setDaysOfWeek(v) {
            this.cron = this.cronArray.map((el, i) => {
                if (i === 4) {
                    return v;
                }
                return el;
            }).join(' ');
        },
        setDayOfMonth(v) {
            this.cron = this.cronArray.map((el, i) => {
                if (i === 2) {
                    return v;
                }
                return el;
            }).join(' ');
        },
        setMonths(v) {
            this.cron = this.cronArray.map((el, i) => {
                if (i === 3) {
                    return v;
                }
                return el;
            }).join(' ');
        },
        onDaysWeekChange(v) {
            this.setDaysOfWeek(v.length ? v.join(`,`) : `*`);
        },
        onMonthChange(v) {
            this.setMonths(v.length ? v.join(`,`) : `*`);
        },
        dayFormatter(v) {
            if (Number(v) < 1) {
                return 1;
            }
            if (Number(v) > 31) {
                return 31;
            }
            return v;
        },
        getSelectedVariantByCron(cron) {
            const arr = cron.split(' ');
            const dayOfMonth = arr[2];
            if (dayOfMonth !== '*') {
                this.selected = `EveryMonth`;
                this.dayMonth = dayOfMonth;
            }
            const month = arr[3];
            if (month !== '*') {
                this.selected = `EveryYear`;
                this.selectedMonths = month.split(`,`).map(el => Number(el));
            }
            const week = arr[4];
            if (week !== '*') {
                this.selected = `EveryWeek`;
                this.daysWeek = week.split(`,`).map(el => Number(el));
            }
        }
    },
    watch: {
        selected(v) {
            this.setDaysOfWeek(v === `EveryWeek` ? this.daysWeek.join(`,`) : `*`);
            this.setDayOfMonth([`EveryMonth`, `EveryYear`].includes(v) ? this.dayMonth : `*`);
            this.setMonths(v === `EveryYear` ? this.selectedMonths.join(`,`) : `*`);
        },
        cron(v) {
            this.$emit(`change`, v);
        },
        initCron(v) {
            if (v) {
                this.getSelectedVariantByCron(v);
                this.cron = v;
            }
        }
    },
    mounted() {
        if (this.initCron) {
            this.getSelectedVariantByCron(this.initCron);
            this.cron = this.initCron;
        }
    },
    props: {
        disabled: Boolean,
        initCron: {
            type: String,
            default: null
        }
    }
}
</script>

<style scoped>
.available-input {
    -webkit-text-fill-color: #000000;
    opacity: 1;
    background-color: #ffffff;
    border: 1px solid #000000;
}

.disabled-input {
    -webkit-text-fill-color: #000000;
    opacity: 0;
    background-color: #d1d1d1;
    border: 1px solid #d1d1d1;
}
</style>