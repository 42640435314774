<template>
    <div>
        <div class="d-flex my-2 justify-content-between">
            <b-input-group size="sm">
                <b-form-input
                        v-model="filter"
                        type="search"
                        id="filterInput"
                        placeholder="Введите для поиска"
                ></b-form-input>
            </b-input-group>
            <span class="text-success small mx-2">Найдено записей: {{ totalRows }}</span>
            <b-col sm="7" md="2" class="my-1">
                <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                ></b-pagination>
            </b-col>
            <b-col sm="5" md="3" class="my-1">
                <b-form-group
                        label="На странице"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="4"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="perPageSelect"
                        class="mb-0"
                >
                    <b-form-select
                            v-model="perPage"
                            id="perPageSelect"
                            size="sm"
                            :options="pageOptions"
                    ></b-form-select>
                </b-form-group>
            </b-col>
        </div>
        <b-table
                :fields="fields"
                :filter="filter"
                small striped
                :items="items"
                :current-page="currentPage"
                :per-page="perPage"
                @filtered="onFiltered"
        >
            <template v-slot:cell(date_time_begin)="data">
                {{ Date.parse(data.item.date_time_begin) | moment("DD.MM.YYYY dd HH:mm")}}
            </template>
            <template v-slot:cell(date_time_end)="data">
                {{ Date.parse(data.item.date_time_end) | moment("DD.MM.YYYY dd HH:mm")}}
            </template>
        </b-table>
    </div>
</template>

<script>
    export default {
        computed: {
            items() {
                return this.$store.state.workShifts;
            }
        },
        data() {
            return {
                fields: [
                    {
                        key: `shift_id`,
                        label: `Ид смены`,
                        sortable: true
                    },
                    {
                        key: `object_name`,
                        label: `Имя объекта`,
                        sortable: true
                    },
                    {
                        key: `quantity_of_workers`,
                        label: `Количество рабочих`,
                        sortable: true
                    },
                    {
                        key: `date_time_begin`,
                        label: `Время начала смены`,
                        sortable: true
                    },
                    {
                        key: `date_time_end`,
                        label: `Время окончания смены`,
                        sortable: true
                    },
                    {
                        key: `pause_time`,
                        label: `Время перерыва (мин.)`,
                        sortable: true
                    }
                ],
                filter: null,
                filterOn: [],
                perPage: 200,
                pageOptions: [5, 10, 15, 20, 100, 200],
                totalRows: 1,
                currentPage: 1
            }
        },
        mounted() {
            this.$store.dispatch(`getWorkShifts`);
            this.totalRows = this.items.length;
        },
        methods: {
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length
                this.currentPage = 1
            }
        },
        name: "WorkShifts"
    }
</script>

<style scoped>

</style>