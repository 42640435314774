<template>
    <div>
        <label class="sm font-weight-bold">Выбранные графики</label>
        <b-tabs content-class="mt-3" :value="activeTab">
            <b-tab v-for="(selectedData, i) in dataSelected"
                   :active="i === activeTab"
                   :key="i"
            >
                <template #title>
                   <span>
                       {{ selectedData.name }}
                   </span>
                    <i v-if="selectedData.description"
                            class="far fa-question-circle h6 text-info"
                            @click="$bvToast.show(`tooltip_${selectedData.id}`)"
                    ></i>
                    <b-toast :id="`tooltip_${selectedData.id}`" no-auto-hide toaster="b-toaster-top-center">
                        <div v-html="selectedData.description"></div>
                    </b-toast>
                </template>
               <div class="pb-2">
                   <span v-html="selectedData.formula"></span>
               </div>
                <b-card class="mb-2">
                    <FilterTabContent :dataId="selectedData.id"/>
                </b-card>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
    import FilterTabContent from "./FilterTabContent/FilterTabContent.vue";

    export default {
        computed: {
            dataSelected() {
                return this.$store.state.charts.dataSelected
            },
            activeTab() {
                return this.dataSelected.length - 1;
            }
        },
        components: {
            FilterTabContent
        },
        name: "FiltersTabs"
    }
</script>

<style>
    @page {
        margin-left: 1.18in;
        margin-right: 0.59in;
        margin-top: 0.39in
    }

    P {
        margin-bottom: 0.08in;
        direction: ltr;
        line-height: 100%;
        widows: 2;
        orphans: 2
    }

    P.cjk {
        font-size: 14pt
    }
</style>