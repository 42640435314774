<template>
    <div class="twoStep">
        <div class="d-flex justify-content-start mt-3">
          <b-button size="sm" @click="goToRequestsPage" class="mx-2 mt-2 mb-2">
            К списку заявок
          </b-button>
        </div>
      <b-card v-for="[name, shifts_] in Object.entries(availableObjectsFromShifts)" :key="name" :title="name"
                class="m-0 fit">
            <b-form-input id="search" size="sm" placeholder="Для поиска введите № отчёта, дату или фамилию..." v-model="search"/>
          <p class="text-danger font-weight-bold m-1">Можно выбрать несколько отчётов</p>
            <div class="table-parent">
                <b-table :items="getFilteredShifts(shifts_)" small striped :fields="fields">
                    <template v-slot:cell(check)="data">
                        <b-form-checkbox @change="v => onChecked(v, data.item)" :checked="isSelected(data.item)"/>
                    </template>
                    <template v-slot:cell(shift_id)="{item}">
                        <a :href="`https://t.me/c/${item.bp.common_chat_id.replace(`-100`, ``)}/${item.telegram_message_id}`"
                           target="_blank">
                            {{ item.shift_id }}
                        </a>
                    </template>
                    <template v-slot:cell(date_time_begin)="data">
                        {{ $moment(data.item.date_time_begin).format(`DD.MM.YYYY (dd) HH:mm`) }}
                    </template>
                    <template v-slot:cell(date_time_end)="data">
                        {{ $moment(data.item.date_time_end).format(`DD.MM.YYYY (dd) HH:mm`) }}
                    </template>
                    <template v-slot:cell(Author)="{item}">
                        <a :href="`https://t.me/+${item.Author.phone}`" target="_blank">
                            {{ item.Author.first_name }} {{ item.Author.last_name }}
                        </a>
                    </template>
                    <template v-slot:cell(bp.MainStaff)="{item}">
                        <a :href="`https://t.me/+${item.bp.MainStaff.phone}`" target="_blank">
                            {{ item.bp.MainStaff.first_name }} {{ item.bp.MainStaff.last_name }}
                        </a>
                    </template>
                    <template v-slot:cell(bp.Curator)="{item}">
                        <a :href="`https://t.me/+${item.bp.Curator.phone}`" target="_blank">
                            {{ item.bp.Curator.first_name }} {{ item.bp.Curator.last_name }}
                        </a>
                    </template>
                </b-table>
            </div>
        </b-card>
    </div>
</template>

<script>
export default {
    name: "TwoStep",
    computed: {
        shifts() {
            return this.$store.state.deleteShiftReport.availableShifts;
        },
        selectedShifts() {
            return this.$store.state.deleteShiftReport.selectedShifts;
        },
        availableObjectsFromShifts() {
            return this.shifts.reduce((groups, item) => {
                const key = item.bp.bo.name;
                if (!groups[key]) {
                    groups[key] = [];
                }
                groups[key].push(item);
                return groups;
            }, {});
        }
    },
    data() {
        return {
            fields: [
                {
                    key: `check`,
                    label: ``,
                    thClass: `w30px`
                },
                {
                    key: `shift_id`,
                    label: `№ отчёта`
                },
                {
                    key: `date_time_begin`,
                    label: `Начало смены`
                },
                {
                    key: `date_time_end`,
                    label: `Окончание смены`
                },
                {
                    key: `Author`,
                    label: `Автор отчета`
                },
                {
                    key: `bp.MainStaff`,
                    label: `Прораб`
                },
                {
                    key: `bp.Curator`,
                    label: `Куратор`
                }
            ],
            search: ``
        }
    },
    methods: {
        onChecked(v, item) {
            const {selectedShifts} = this;
            let arr = selectedShifts;
            if (v) {
                arr.push(Object.assign(item, {cause: ``}));
            } else {
                arr = arr.filter(el => el.shift_id !== item.shift_id);
            }
            this.$store.commit(`requests_setSelectedShifts`, arr);
        },
        isSelected({shift_id}) {
            return this.selectedShifts.some(el => el.shift_id === shift_id);
        },
        getFilteredShifts(shifts) {
            const {search, $moment} = this;
            if (!search || search.length < 3) {
                return shifts;
            }
            const s_low = search.toLowerCase().trim();
            return shifts.filter(el => {
                const start = $moment(el.date_time_begin).format(`DD.MM.YYYY (dd) HH:mm`);
                const end = $moment(el.date_time_end).format(`DD.MM.YYYY (dd) HH:mm`);
                const author = `${el.Author.first_name}${el.Author.last_name}`;
                const staff = `${el.bp.MainStaff.first_name}${el.bp.MainStaff.last_name}`;
                const curator = `${el.bp.Curator.first_name}${el.bp.Curator.last_name}`;
                return `${el.shift_id}${start}${end}${author}${staff}${curator}`.trim().toLowerCase().includes(s_low);
            });
        },
        goToRequestsPage() {
            const {$router, $route} = this;
            const {params} = $route;
            $router.push(`/requests/delete-shift-report#${params.id}`);
        }
    },
    mounted() {
        this.$emit('can-continue', {value: this.selectedShifts.length});
    },
    watch: {
        selectedShifts(v) {
            this.$emit('can-continue', {value: v.length});
        }
    }
}
</script>

<style scoped>
.twoStep {
    max-height: 50vh;
    overflow: auto;
}

@media (max-width: 760px) {
    .fit {
        width: fit-content;
    }
}
.twoStep {
  max-height: 50vh;
  overflow-y: auto;
  scrollbar-gutter: stable;
}

/* Стилизация полос прокрутки для Chromium-браузеров */
.twoStep::-webkit-scrollbar {
  width: 8px;
}

.twoStep::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

.twoStep::-webkit-scrollbar-track {
  background-color: transparent;
}
</style>