<template>
    <div>
        <b-modal id="editMaterialTarget"
                 title="Редактирование объема материалов"
                 @ok="submit"
                 @hide="clearForm"
                 ok-title="Ок"
                 ok-variant="success"
                 cancel-title="Отмена"
                 cancel-variant="danger"
                 no-close-on-backdrop
                 @show="beforeOpen"
        >
            <label for="object">Строительный объект</label>
            <v-select
                    id="object"
                    label="object_name"
                    value="building_process_id"
                    :options="objects"
                    v-model="selectedObject">
            </v-select>

            <label for="workTarget">Работы по объекту</label>
            <v-select
                    id="workTarget"
                    label="work_name"
                    value="target_works_id"
                    :options="workTargets"
                    v-model="selectedWorkTarget">
            </v-select>

            <label for="material">Материал</label>
            <v-select
                    id="material"
                    label="name"
                    value="id"
                    :options="materials"
                    v-model="selectedMaterial">
            </v-select>

            <label for="value">Значение</label>
            <b-form-input
                    v-model="value"
                    id="value"
                    size="sm"
                    type="number"
            ></b-form-input>

            <label for="value">Тревога сработает если отклонение превысит:</label>
            <b-form-input
                    v-model="limit"
                    id="limit"
                    size="sm"
                    type="number"
            ></b-form-input>
        </b-modal>
    </div>
</template>

<script>
    export default {
        computed: {
            materials() {
                return this.$store.state.materials.directory;
            },
            objects() {
                return this.$store.state.objectsInProcess;
            },
            selectedMaterialTargetId() {
                return this.$store.state.materials.selectedMaterialTargetId;
            },
            workTargets() {
                if (!this.selectedObject) {
                    return [];
                }
                const {building_process_id} = this.selectedObject;
                return this.$store.state.targets.filter(el => el.building_process_id === building_process_id);
            }
        },
        data() {
            return {
                selectedMaterial: null,
                selectedObject: null,
                selectedWorkTarget: null,
                value: 0,
                limit: 0
            }
        },
        methods: {
            clearForm() {
                this.selectedMaterial = null;
                this.selectedObject = null;
                this.selectedWorkTarget = null;
                this.value = null;
                this.limit = null;
            },
            async beforeOpen() {
                this.$store.dispatch(`getObjectsInProcess`);
                this.$store.dispatch(`getTargets`);
                this.$store.dispatch(`getDirectory`);
                const target = await this.$store.dispatch(`getMaterialTarget`, this.selectedMaterialTargetId);
                this.setTarget(target);
            },
            setTarget({building_process_id, material_id, material_name, target_work_id, value, limit, object_name, target_work_name}) {
                this.selectedMaterial = {
                    id: material_id,
                    name: material_name
                };
                this.selectedObject = {
                    building_process_id,
                    object_name
                }
                this.selectedWorkTarget = {
                    target_works_id: target_work_id,
                    work_name: target_work_name
                };
                this.value = value;
                this.limit = limit;
            },
            submit() {
                const data = {
                    targetWork: this.selectedWorkTarget?.target_works_id,
                    material: this.selectedMaterial?.id,
                    value: this.value,
                    limit: this.limit
                }
                this.$store.dispatch(`editMaterialTargets`, data)
                    .then(() => this.$store.dispatch(`getMaterialTargets`));
            }
        },
        name: "EditMaterialTarget"
    }
</script>

<style scoped>

</style>