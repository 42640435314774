<template>
    <div class="oneStep">
          <div class="d-flex justify-content-start mt-3">
            <b-button size="sm" @click="goToRequestsPage" class="mx-2 mt-2 mb-2">
              К списку заявок
            </b-button>
          </div>
        <b-overlay :show="onProcess" rounded="sm">
            <div class="mb-2">
                <b-dropdown id="availableObjects" no-caret variant="link" :dropright="true">
                    <template #button-content class="small p-0">
                        <b-button size="sm" variant="info" :disabled="Boolean(requestId)">
                            Выбрать строительный объект
                        </b-button>
                    </template>
                    <div>
                        <div class="px-3">
                            <b-form-input size="sm" v-model="find" class="mx-auto"
                                          placeholder="Введите название объекта"/>
                        </div>
                        <div style="overflow: auto; max-height: 300px">
                            <b-dropdown-item v-for="(obj, i) in availableObjects" :key="i"
                                             @click="() => onObjectSelected(obj)">
                                <span class="small">{{ obj.bo.name }}</span>
                            </b-dropdown-item>
                        </div>
                    </div>
                </b-dropdown>
            </div>
            <h4 v-if="selected" class="mt-5">Выбранный объект:
                <div>
                    <b-badge>{{ selected.bo.name }}</b-badge>
                </div>
            </h4>
        </b-overlay>
    </div>
</template>

<script>
export default {
    name: "OneStep",
    computed: {
        availableObjects() {
            const {objects, find} = this;
            return objects.filter(el => el.bo).filter(el => el.bo.name.toLowerCase().includes(find.toLowerCase()));
        },
        availableShifts() {
            return this.$store.state.deleteShiftReport.availableShifts;
        },
        selected() {
            return this.$store.state.deleteShiftReport.selectedObject;
        },
        onProcess() {
            return this.$store.state.onProcess;
        },
        requestId() {
            const {$route: {query}} = this;
            return query.request;
        },
        editRequest() {
            return this.$store.state.deleteShiftReport.editRequest;
        }
    },
    data() {
        return {
            find: ``,
            objects: []
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            const {$store, selected, requestId, getAvailableObjects, onObjectSelected} = this;
            if (selected) {
                this.$emit('can-continue', {value: true});
            }
            await getAvailableObjects();
            if (requestId) {
                const request = await $store.dispatch(`requests_getRequestById`, requestId);
                $store.commit(`requests_setEditRequest`, request);
                const object = this.objects.find(el => el.building_process_id === request.buildingProcess);
                await onObjectSelected(object);
                document.getElementsByClassName(`stepper-button next`)[0].click()
            }
        },
        getAvailableObjects() {
            return this.$store.dispatch(`requests_getObjects`)
                .then(objects => this.objects = objects);
        },
        onObjectSelected(item) {
            this.$store.commit(`setOnProcess`, true);
            this.$store.commit(`requests_setSelectedObject`, item);
            this.$store.commit(`requests_setSelectedShifts`, []);
            return this.updateShifts(item.building_process_id)
                .then(() => {
                    if (this.editRequest) {
                        const selected = [];
                        this.editRequest.causes.forEach(({shift, cause}) => {
                            const has = this.availableShifts.find(s => s.shift_id === shift);
                            if (has) {
                                selected.push(Object.assign(has, {cause}));
                            }
                        })
                        this.$store.commit(`requests_setSelectedShifts`, selected);
                    }
                })
                .then(() => this.$emit('can-continue', {value: true}))
                .finally(() => this.$store.commit(`setOnProcess`, false));
        },
        updateShifts(processId) {
            return this.$store.dispatch(`requests_getShifts`, processId)
                .then(shifts => this.$store.commit(`requests_setAvailableShifts`, shifts));
        },
        goToRequestsPage() {
            const {$router, $route} = this;
            const {params} = $route;
            $router.push(`/requests/delete-shift-report#${params.id}`);
        },
    }
}
</script>

<style scoped>
.oneStep {
    min-height: 30vh;
}
</style>