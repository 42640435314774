<template>
    <div>
        <label class="sm font-weight-bold">Выбранный период</label>
        <VueDatePicker
                v-model="time"
                :locale="locale"
                button-validate="Ок"
                button-cancel="Отмена"
                validate
                range
                placeholder="Выберите даты"
                clearable
                format="DD-MM-YYYY"
                :range-presets="presets"
        />
        <b-form-checkbox v-model="ignoreDate" name="ignoreDate" switch v-if="time">
           Не учитывать даты выполнения работ
        </b-form-checkbox>
    </div>
</template>

<script>
    import {VueDatePicker} from '@mathieustan/vue-datepicker';
    import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';

    const getLastDayOfMonth = (year, month) => {
        const date = new Date(year, month + 1, 0);
        return date.getDate();
    }
    export default {
        components: {VueDatePicker},
        data() {
            return {
                locale: {lang: 'ru', weekDays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']},
                currentDate: new Date()
            }
        },
        computed: {
            time: {
                get() {
                    return this.$store.state.charts.selectedDate;
                },
                set(value) {
                    this.$store.commit(`setChartsSelectedDate`, value)
                }
            },
            presets() {
                return [
                    {
                        name: 'Последние 5 дней',
                        dates: {
                            start: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate() - 5),
                            end: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate()),
                        }
                    },
                    {
                        name: 'Этот месяц',
                        dates: {
                            start: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1),
                            end: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate()),
                        }
                    },
                    {
                        name: 'Последние 30 дней',
                        dates: {
                            start: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate() - 30),
                            end: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate()),
                        }
                    },
                    {
                        name: 'Прошлый месяц',
                        dates: {
                            start: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() - 1, 1),
                            end: new Date(
                                this.currentDate.getFullYear(),
                                this.currentDate.getMonth() - 1,
                                getLastDayOfMonth(this.currentDate.getFullYear(), this.currentDate.getMonth() - 1)
                            )
                        }
                    },
                    {
                        name: 'Последние 3 месяца',
                        dates: {
                            start: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() - 3, this.currentDate.getDate()),
                            end: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate()),
                        }
                    },
                    {
                        name: 'Этот год',
                        dates: {
                            start: new Date(this.currentDate.getFullYear(), 0, 1),
                            end: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate()),
                        }
                    }

                ];
            },
            ignoreDate: {
                get() {
                    return this.$store.state.charts.ignoreDates;
                },
                set(val) {
                    this.$store.commit(`setIgnoreDates`, val)
                }
            }
        },
        name: "DateSelector"
    }
</script>

<style scoped>

</style>