<template>
    <div>
        <b-modal id="createMaterial"
                 title="Создание материала"
                 @ok="submit"
                 @hide="clearForm"
                 ok-title="Ок"
                 ok-variant="success"
                 cancel-title="Отмена"
                 cancel-variant="danger"
                 no-close-on-backdrop
                 @show="beforeOpen"
        >
            <label for="parentMaterial">Родительская группа материала</label>
            <v-select
                    label="name"
                    value="id"
                    :options="groups"
                    v-model="selectedParent">
            </v-select>
            <label for="name">Имя материала</label>
            <b-form-input
                    v-model="name"
                    placeholder="Клей для швов эпоксидный"
                    id="name"
                    size="sm"
            ></b-form-input>
            <label for="measures">Единица измерения</label>
            <b-form-input
                    v-model="measures"
                    placeholder="литр"
                    id="measures"
                    size="sm"
            ></b-form-input>
        </b-modal>
    </div>
</template>

<script>
    export default {
        computed: {
            groups() {
                return this.$store.state.materials.groups;
            }
        },
        data() {
            return {
                name: null,
                selectedParent: null,
                measures: null
            }
        },
        methods: {
            clearForm() {
                this.name = null;
                this.selectedParent = null;
                this.measures = null;
            },
            beforeOpen() {
                this.$store.dispatch(`getMaterialGroups`);
            },
            submit() {
                const data = {
                    name: this.name,
                    measures: this.measures,
                    parentGroup: this.selectedParent?.id
                }
                this.$store.dispatch(`createMaterial`, data)
                    .then(() => this.$store.dispatch(`getDirectory`));
            }
        },
        name: "CreateMaterial"
    }
</script>

<style scoped>

</style>