<template>
    <div>
        <b-modal id="createMaterialTarget"
                 title="Добавление объема материалов"
                 @ok="submit"
                 @hide="clearForm"
                 ok-title="Ок"
                 ok-variant="success"
                 cancel-title="Отмена"
                 cancel-variant="danger"
                 no-close-on-backdrop
                 @show="beforeOpen"
                 size="xl"
        >
            <label for="object">Строительный объект</label>
            <v-select
                    id="object"
                    label="object_name"
                    value="building_process_id"
                    :options="objects"
                    v-model="selectedObject"
            >
            </v-select>

            <label for="workTarget">Работы по объекту</label>
            <v-select
                    id="workTarget"
                    label="work_name"
                    value="target_works_id"
                    :options="workTargets"
                    v-model="selectedWorkTarget"
                    multiple
                    @option:selecting="onTargetSelect"
                    @option:deselecting="onTargetDelete"
            >
            </v-select>
            <div v-if="selectedWorkTarget.length" class="bg-light mt-2">
                <b-table
                        :items="tableItems"
                        :fields="targetsFields"
                        small
                        striped
                >
                    <template #cell(show_details)="row">
                        <b-button size="sm"
                                  @click="onShowDetailingClick(row)"
                                  class="mr-2"
                                  :class="row.detailsShowing ? `bg-danger` : `bg-success`"
                        >
                            {{ row.detailsShowing ? 'Скрыть' : 'Показать'}} материалы
                        </b-button>
                    </template>
                    <template #row-details="row">
                        <b-card>
                            <div class="d-flex justify-content-end">
                                <v-select
                                        id="material"
                                        label="name"
                                        value="id"
                                        :options="materials"
                                        v-model="selectedMaterial"
                                        class="w-25"
                                >
                                </v-select>
                                <i class="fas fa-plus-circle text-success h4 mx-2 mt-1"
                                   @click="addMaterialToTarget(row.item.target_works_id)"
                                   v-if="!hasMaterial(row.item.target_works_id)"
                                >
                                </i>
                                <i class="fas fa-minus-circle text-danger h4 mx-2 mt-1"
                                   @click="deleteMaterialFromTarget(row.item.target_works_id)"
                                   v-if="hasMaterial(row.item.target_works_id)"
                                >
                                </i>
                            </div>
                            <b-table
                                    :items="row.item.materials"
                                    :fields="materialFields"
                                    small
                            >
                                <template #cell(value)="data">
                                    <b-form-input
                                            v-model="data.item.value"
                                            type="number"
                                            size="sm"
                                            :disabled="data.item.oldPosition"
                                    >
                                    </b-form-input>
                                </template>
                                <template #cell(limit)="data">
                                    <b-form-input
                                            v-model="data.item.limit"
                                            type="number"
                                            size="sm"
                                            :disabled="data.item.oldPosition"
                                    >
                                    </b-form-input>
                                </template>
                            </b-table>
                        </b-card>
                    </template>
                </b-table>
            </div>
        </b-modal>
    </div>
</template>

<script>
    export default {
        computed: {
            materials() {
                return this.$store.state.materials.directory;
            },
            objects() {
                return this.$store.state.objectsInProcess;
            },
            selectedObject: {
                get() {
                    return this.$store.state.materials.selectedObject;
                },
                set(val) {
                    this.$store.commit(`setSelectedObject`, val);
                }
            },
            workTargets() {
                if (!this.selectedObject) {
                    return [];
                }
                const {building_process_id} = this.selectedObject;
                return this.$store.state.targets.filter(el => el.building_process_id === building_process_id);
            }
        },
        data() {
            return {
                materialFields: [
                    {
                        key: `name`,
                        label: `Имя материала`
                    },
                    {
                        key: `parent_group_name`,
                        label: `Имя группы`
                    },
                    {
                        key: `measures`,
                        label: `Ед. изм.`
                    },
                    {
                        key: `value`,
                        label: `Кол-во материала`,
                        thClass: `w-100px`
                    },
                    {
                        key: `limit`,
                        label: `Граница срабатывания тревоги, %`,
                        thClass: `w-20px`
                    }
                ],
                targetsFields: [
                    {
                        key: `work_name`,
                        label: `Имя работы`
                    },
                    {
                        key: `value`,
                        label: `Количество`
                    },
                    {
                        key: `unit_of_measurement`,
                        label: `Ед. изм.`
                    },
                    {
                        key: `show_details`,
                        label: ``
                    }
                ],
                selectedMaterial: null,
                selectedWorkTarget: [],
                tableItems: [],
                value: 0,
                limit: 0
            }
        },
        methods: {
            addMaterialToTarget(targetId) {
                const target = this.tableItems.find(el => el.target_works_id === targetId);
                let material = JSON.parse(JSON.stringify(this.selectedMaterial));
                material.value = 0;
                material.limit = 0;
                target.materials.push(material);
            },
            clearForm() {
                this.selectedMaterial = null;
                this.selectedWorkTarget = [];
                this.$store.commit(`setSelectedObject`, null);
                this.value = null;
                this.limit = null;
                this.tableItems = [];
            },
            deleteMaterialFromTarget(targetId) {
                const target = this.tableItems.find(el => el.target_works_id === targetId);
                target.materials = target.materials.filter(el => el.id !== this.selectedMaterial.id);
            },
            beforeOpen() {
                this.$store.dispatch(`getObjectsInProcess`);
                this.$store.dispatch(`getTargets`);
                this.$store.dispatch(`getDirectory`);
            },
            onShowDetailingClick(row) {
                row.toggleDetails();
            },
            onTargetDelete(target) {
                this.tableItems = this.tableItems.filter(el => el.target_works_id !== target.target_works_id)
            },
            onTargetSelect(target) {
                if (this.tableItems.find(el => el.target_works_id === target.target_works_id)) {
                    return;
                }
                const item = JSON.parse(JSON.stringify(target));
                item.materials = this.$store.state.materials.targets
                    .filter(el => el.target_work_id === target.target_works_id)
                    .map(el => {
                        return {
                            name: el.material_name,
                            limit: el.limit,
                            value: el.value,
                            parent_group_name: el.group_name,
                            measures: el.measures,
                            id: el.material_id,
                            oldPosition: true
                        }
                    });
                this.tableItems.push(item);
            },
            async submit() {
                const targets = [];
                this.tableItems.forEach(el => {
                    el.materials.forEach(material => {
                        const elem = {
                            targetWork: el.target_works_id,
                            material: material.id,
                            value: Number(material.value),
                            limit: Number(material.limit)
                        }
                        if (!material.oldPosition) {
                            targets.push(elem);
                        }
                    })
                })

                for (const target of targets) {
                    await this.$store.dispatch(`createMaterialTargets`, target)
                }
                this.$store.dispatch(`getMaterialTargets`);
            },
            hasMaterial(targetId) {
                const target = this.tableItems.find(el => el.target_works_id === targetId);
                return target
                    ? Boolean(target.materials.find(el => el.id === this.selectedMaterial?.id))
                    : false;
            }
        },
        name: "CreateMaterialTarget"
    }
</script>

<style>
    .w-20px {
        width: 20px;
    }

    .w-100px {
        width: 100px;
    }
</style>