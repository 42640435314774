<template>
    <div>
        <div class="d-flex justify-content-center align-items-end px-5">
            <a href="https://youtu.be/2V5i52g6RMs" target="_blank">
                <i class="fab fa-youtube mx-2 text-danger"></i>
                Видеоурок по разделу Графики
            </a>
        </div>
        <div class="d-flex">
            <DateSelector/>
            <DataSelector
                    class="mx-5 w-25"
                    v-if="selectedDate"
            />

        </div>
        <div class="text-center text-danger my-2" v-if="showChartsSpinner">
            <b-spinner class="align-middle mx-2"></b-spinner>
            <strong>Загрузка данных...</strong>
        </div>
        <FiltersTabs class="my-3" v-if="selectedDate"/>
        <ChartContainer/>
    </div>
</template>

<script>
    import DateSelector from "./DateSelector/DateSelector.vue";
    import DataSelector from "./DataSelector/DataSelector.vue";
    import FiltersTabs from "./FiltersTabs/FiltersTabs.vue";
    import ChartContainer from "./ChartContainer/ChartContainer.vue";

    export default {
        computed: {
            selectedDate() {
                return this.$store.state.charts.selectedDate;
            },
            showChartsSpinner() {
                return this.$store.state.charts.showChartsSpinner;
            }
        },
        components: {
            ChartContainer,
            DateSelector,
            DataSelector,
            FiltersTabs
        },
        mounted() {
            this.$store.commit(`setDataSelected`, []);
            this.$store.commit(`setDataSets`, []);
            this.$store.commit(`setChartsSelectedDate`, ``);
        },
        name: "Charts",
        watch: {
            selectedDate(val) {
                this.$store.commit(`setDataSelected`, []);
                this.$store.commit(`setDataSets`, []);
                if (val) {
                    this.$store.dispatch(`chartsGetMainData`)
                        .then(res => this.$store.commit(`setMainData`, res));
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    a {
        text-decoration: underline;
        .fa-youtube {
            font-size: 18px;
        }
    }

</style>