<template>
    <div class="p-5">
        <h1 class="text-center"> {{ userId
            ? `Редактирование пользователя системы`
            :`Добавление пользователя в систему`}}</h1>
        <div class="w-50 my-5 mx-auto">
            <b-form-group
                    label="Сотрудник"
            >
                <b-form-select
                        :disabled="Boolean(userId)"
                        text-field="full_name"
                        value-field="user_id"
                        size="sm"
                        :state="selectedUser !== null"
                        v-model="selectedUser"
                        :options="users"
                >
                    <template v-slot:first>
                        <b-form-select-option :value="null" disabled>-- Выберите сотрудника --</b-form-select-option>
                    </template>
                </b-form-select>
            </b-form-group>
            <b-form-group
                    label="Роль пользователя"
            >
                <b-form-select
                        multiple
                        text-field="role_name"
                        value-field="role_id"
                        size="sm"
                        :state="selectedRoles !== null"
                        v-model="selectedRoles"
                        :options="roles"
                        :select-size="roles.length + 1"
                >
                    <template v-slot:first>
                        <b-form-select-option :value="null" disabled>-- Выберите роль --</b-form-select-option>
                    </template>
                </b-form-select>
            </b-form-group>
            <b-form-group
                    label="Логин"
            >
                <b-form-input
                        size="sm"
                        :state="login !== null && login !== ''"
                        v-model="login" placeholder="Введите логин"></b-form-input>
            </b-form-group>
            <b-form-group
                    label="Пароль"
            >
                <b-form-input
                        size="sm"
                        :state="password !== null && password !== ''"
                        v-model="password" placeholder="Введите пароль"></b-form-input>
            </b-form-group>
            <b-form-group
                    label="Повторите пароль"
            >
                <b-form-input
                        id="password2"
                        size="sm"
                        :state="password2 !== null && isPasswordsEquals"
                        v-model="password2" placeholder="Повторите пароль">
                </b-form-input>
                <b-form-invalid-feedback id="password2">
                    Пароли не совпадают
                </b-form-invalid-feedback>
            </b-form-group>
            <div class="d-flex justify-content-end">
                <b-button :disabled="isSubmitDisable" @click="submit" variant="success" size="sm">
                    Отправить
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            userId() {
                return this.$route.query.userId
            },
            isPasswordsEquals() {
                return this.password === this.password2;
            },
            isSubmitDisable() {
                return !this.selectedRoles
                    || !this.login
                    || !this.isPasswordsEquals
                    || !this.password;
            },
            roles() {
                return this.$store.state.roles;
            },
            users() {
                return this.$store.state.users;
            }
        },
        data() {
            return {
                login: null,
                password: null,
                password2: null,
                selectedRoles: [],
                selectedUser: null
            }
        },
        methods: {
            async submit() {
                const action = this.userId ? `editSystemUser` : `createSystemUser`;
                await this.$store.dispatch(action, {
                    user_id: this.selectedUser,
                    login: this.login,
                    password: this.password,
                    roles: this.selectedRoles
                }).then(() => {
                    this.$router.push(`/systemUsers`);
                })
            }
        },
        async mounted() {
            this.$store.dispatch(`getUsers`);
            await this.$store.dispatch(`getRoles`);
            if (this.userId) {
                const user = await this.$store.dispatch(`getSystemUser`, this.userId)
                    .catch(err => this.$store.dispatch(`showErrorModal`, err))
                this.selectedUser = user.userId;
                const that = this;
                this.selectedRoles = user.roles.map(el => {
                    return that.roles.find(item => el === item.role_name)?.role_id;
                });
                this.login = user.login;
            }
        },
        name: "SystemUserForm"
    }
</script>

<style scoped>

</style>