<template>
    <b-card
            bg-variant="light"
            text-variant="black"
            class="buildingContainer"
    >
        <div class="overflow px-2">
            <ShiftImages v-for="(shift, $index) in shifts" :data="shift" :key="$index" class="my-1"/>
            <infinite-loading @infinite="infiniteHandler"></infinite-loading>
        </div>
    </b-card>
</template>
<script>
    import ShiftImages from "./ShiftImages/ShiftImages";
    import InfiniteLoading from 'vue-infinite-loading';

    export default {
        name: "ObjectImages",
        data() {
            return {
                shifts: [],
                page: 1,
                skip: 0
            }
        },
        components: {
            ShiftImages,
            InfiniteLoading
        },
        props: {
            data: Object
        },
        mounted() {
            this.shifts = this.data.shifts;
            this.skip = this.shifts.length;
        },
        methods: {
            async infiniteHandler($state) {
                const data = await this.$store.dispatch(`getImagesForBuilding`, {
                    id: this.data.building.id,
                    limit: 5,
                    skip: this.skip
                })
                if (data.length) {
                    this.page += 1;
                    this.skip += 5
                    data.forEach(el => {
                        this.shifts.push(el);
                    })
                    $state.loaded();
                } else {
                    $state.complete();
                }
            }
        }
    }
</script>

<style scoped>
    .buildingContainer {
        margin-bottom: 15px;
        position: relative;
        direction: rtl;
    }
    .overflow::-webkit-scrollbar {
        width: 12px;
    }

    .overflow::-webkit-scrollbar-track {
        background: inherit;        /* color of the tracking area */
    }

    .overflow::-webkit-scrollbar-thumb {
        background-color: #17a2b8;
        border-radius: 20px;
    }
    .overflow {
        max-height: 70vh;
        overflow-y: auto;
    }
</style>