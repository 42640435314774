<template>
    <div class="w-25 mx-auto p-5 border border-success mt-5">
        <label for="selectedStaff" class="mt-2">Прораб</label>
        <v-select
                append-to-body
                id="selectedStaff"
                v-model="selectedStaff"
                :options="users"
                value-field="user_id"
                label="full_name"
        >
        </v-select>
        <div class="d-flex justify-content-end mt-2" v-if="selectedStaff">
            <b-button size="sm" variant="danger"
                      @click.prevent="onDelete()"
                      v-if="$can('delete', 'users')">
                Удалить из базы
            </b-button>
        </div>
        <b-form-checkbox
                class="mt-3"
                id="checkbox-1"
                v-model="formVisible"
                name="checkbox-1"
                v-if="$can('create', 'users')">
        >
            Нужного сотрудника нет в списке
        </b-form-checkbox>
        <div class="p-4 mt-5 border border-warning" v-if="formVisible">
            <label for="firstName">Имя</label>
            <b-form-input v-model="firstName" id="firstName" size="sm"></b-form-input>
            <label for="lastName">Фамилия</label>
            <b-form-input v-model="lastName" id="lastName" size="sm"></b-form-input>
            <label for="phone">Телефон</label>
            <b-form-input v-model="phone" id="phone" size="sm"></b-form-input>
            <label for="isBot">Является ботом</label>
            <b-form-checkbox v-model="isBot" name="isBot" switch>
                {{ isBot ? `да` : `нет`}}
            </b-form-checkbox>
            <div class="d-flex justify-content-end my-2">
                <b-button size="sm" variant="success" @click="createUser">Создать</b-button>
            </div>
        </div>
        <b-modal id="deleteObject_stepTwo"
                 @ok="deleteStaff"
                 ok-title="Ок"
                 ok-variant="success"
                 cancel-title="Отмена"
                 cancel-variant="danger"
        >
            <p class="my-4" v-if="selectedStaff">
                Вы действительно хотите удалить сотрудника
                    <span class="font-weight-bold">{{ selectedStaff.full_name }}</span>
                из системы?
            </p>
        </b-modal>
    </div>
</template>

<script>
    export default {
        computed: {
            users() {
                return this.$store.state.users;
            },
            selectedStaff: {
                get() {
                    return this.$store.state.wizard.selectedStaff;
                },
                set(val) {
                    this.$store.commit(`setWizardSelectedStaff`, val);
                }
            }
        },
        data() {
            return {
                formVisible: false,
                firstName: null,
                lastName: null,
                phone: null,
                isBot: false
            }
        },
        methods: {
            async createUser() {
                const data = {
                    first_name: this.firstName,
                    last_name: this.lastName,
                    phone: this.phone,
                    userId: this.userId,
                    isBot: this.isBot
                };
                await this.$store.dispatch(`createUser`, data)
                    .then(res => {
                        this.$store.commit(`setWizardCreatedStaff`, res);
                        res.full_name = `${res.first_name} ${res.last_name}`;
                        this.$store.commit(`setWizardSelectedStaff`, res);
                        this.formVisible = false;
                        this.$store.dispatch(`getUsers`);
                    })
                    .catch(err => this.$store.dispatch(`showErrorModal`, err));
                this.$store.dispatch(`getUsers`);
            },
            deleteStaff() {
                this.$store.dispatch(`deleteUser`, this.selectedStaff.user_id);
                this.$store.commit(`setWizardSelectedStaff`, null);
                this.$store.dispatch(`getUsers`);
            },
            onDelete() {
                this.$bvModal.show(`deleteObject_stepTwo`);
            }
        },
        mounted() {
            this.$store.dispatch(`getUsers`);
            this.$emit(`can-continue`, {
                value: Boolean(this.selectedStaff)
            });
        },
        watch: {
            selectedStaff(val) {
                this.$emit(`can-continue`, {
                    value: Boolean(val)
                });
            }
        },
        name: "StepTwo"
    }
</script>

<style scoped>

</style>