<template>
    <div class="p-2">
        <div class="d-flex justify-content-end my-2">
            <b-button
                    v-b-modal.objectModal
                    size="sm"
                    variant="success"
                    v-if="$can('create', 'objects')"
            >Создать объект
            </b-button>
        </div>
        <div class="d-flex my-2 justify-content-between">
            <b-input-group size="sm">
                <b-form-input
                        v-model="filter"
                        type="search"
                        id="filterInput"
                        placeholder="Введите для поиска"
                ></b-form-input>
            </b-input-group>
            <b-col sm="7" md="2" class="my-1">
                <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                ></b-pagination>
            </b-col>
            <b-col sm="5" md="3" class="my-1">
                <b-form-group
                        label="На странице"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="4"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="perPageSelect"
                        class="mb-0"
                >
                    <b-form-select
                            v-model="perPage"
                            id="perPageSelect"
                            size="sm"
                            :options="pageOptions"
                    ></b-form-select>
                </b-form-group>
            </b-col>
        </div>
        <b-table
                small
                striped
                hover
                :items="objects"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                @filtered="onFiltered"
                :filter="filter"
        >
            <template v-slot:cell(actions)="data">
                <i class="fas fa-pen text-center text-success mx-2"
                   @click="onEdit(data)"
                   v-if="$can('update', 'objects')"
                ></i>
                <i class="fas fa-trash-alt text-danger text-center mx-2"
                   @click="onDelete(data)"
                   v-if="$can('delete', 'objects')"
                ></i>
            </template>
        </b-table>
        <div class="d-flex justify-content-end">
            <b-modal id="objectModal"
                     :title="isEdit ? `Редактирование объекта` : `Создание объекта`"
                     @ok="submit()"
                     @hide="clearForm()"
                     ok-title="Ок"
                     ok-variant="success"
                     cancel-title="Отмена"
                     cancel-variant="danger"
            >
                <label for="name">Имя объекта</label>
                <b-form-input
                        v-model="name"
                        placeholder="Дворец правосудия"
                        id="name"
                        size="sm"
                        :state="Boolean(name)"
                ></b-form-input>
                <label for="phone">Телефон</label>
                <b-form-input
                        v-model="phone"
                        placeholder="375291111111"
                        id="phone"
                        size="sm"
                        :state="Boolean(phone)"
                ></b-form-input>
                <label for="address">Адрес</label>
                <b-form-input
                        v-model="address"
                        placeholder="Одесса, ул.Симона Хереса 69"
                        id="address"
                        size="sm"
                        :state="Boolean(address)"
                ></b-form-input>
                <label for="address">Страна</label>
                <b-form-select
                        v-model="country"
                        id="country"
                        size="sm"
                        text-field="name"
                        value-field="id"
                        :options="countries"
                        :state="Boolean(country)"
                ></b-form-select>
            </b-modal>
            <b-modal id="deleteObject"
                     @ok="deleteObject"
                     ok-title="Ок"
                     ok-variant="success"
                     cancel-title="Отмена"
                     cancel-variant="danger"
            >
                <p class="my-4" v-if="object">
                    Вы действительно хотите удалить объект <span>{{ object.name }}</span> из системы?
                </p>
            </b-modal>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Objects",
        data() {
            return {
                address: null,
                countries: [
                    {
                        id: `BY`,
                        name: `Беларусь`
                    },
                    {
                        id: `RU`,
                        name: `Россия`
                    }
                ],
                country: `BY`,
                fields: [
                    {
                        label: `Имя объекта`,
                        key: `name`
                    },
                    {
                        label: `Адрес`,
                        key: `address`
                    },
                    {
                        label: `Страна`,
                        key: `country`
                    },
                    {
                        label: `Телефон управляющего`,
                        key: `phone`
                    },
                    {
                        label: ``,
                        key: `actions`,
                        class: `w-80px`
                    }
                ],
                name: null,
                object: null,
                phone: null,
                isEdit: false,
                objectId: null,
                filter: null,
                perPage: 200,
                pageOptions: [5, 10, 15, 20, 100, 200],
                totalRows: 1,
                currentPage: 1,
                filterOn: []
            }
        },
        computed: {
            objects() {
                return this.$store.state.objects;
            }
        },
        methods: {
            clearForm() {
                this.name = null;
                this.phone = null;
                this.address = null;
                this.country = `BY`;
                this.isEdit = false;
                this.objectId = null;
            },
            deleteObject() {
                this.$store.dispatch(`deleteObject`, this.object.building_id);
                this.$store.dispatch(`getObjects`);
            },
            onDelete(data) {
                this.object = data.item;
                this.$bvModal.show(`deleteObject`);
            },
            onEdit(data) {
                this.isEdit = true;
                const {name, phone, address, country, building_id} = data.item;
                this.name = name;
                this.phone = phone;
                this.address = address;
                this.country = country;
                this.objectId = building_id;
                this.$bvModal.show(`objectModal`);
            },
            async submit() {
                const {name, phone, address, country, objectId} = this;
                const data = {
                    name,
                    phone,
                    address,
                    country,
                    objectId
                };
                const action = this.isEdit ? `editObject` : `createObject`;
                await this.$store.dispatch(action, data).then(() => {
                    this.clearForm();
                    this.$store.dispatch(`getObjects`);
                }).catch(err => {
                    this.clearForm();
                    this.$store.dispatch(`showErrorModal`, err.response.data);
                });
            },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length
                this.currentPage = 1
            }
        },
        mounted() {
            this.$store.dispatch(`getObjects`);
            this.totalRows = this.objects.length;
        }
    }
</script>

<style scoped>
    .btn-circle {
        width: 70px;
        height: 70px;
        padding: 10px 16px;
        border-radius: 35px;
        font-size: 24px;
        line-height: 1.33;
        text-align: center;
    }
</style>