<template>
    <b-form-group
            label="Описание"
            label-for="description"
            label-class="small"
    >
		<i v-if="!isEdit" class="fas fa-pen ml-2 text-center small" title="Редактировать"
		   @click="onStartEditClick"/>
        <b-form-textarea
                v-if="canEdit && isEdit"
                id="description"
                v-model="description"
                placeholder="Напишите описание задачи..."
                rows="6"
                max-rows="6"
                size="sm"
                class="canPaste"
                ref="descriptionTextarea"
                @contextmenu="e => $emit(`rightClick`, {e, t: this.$refs[`descriptionTextarea`]})"
        ></b-form-textarea>
		<div class="d-flex justify-content-end mt-2">
			<i v-if="isEdit" class="fas fa-check text-success ml-2" title="Применить" @click="onOkClick"></i>
			<i v-if="isEdit" class="fas fa-ban ml-2 text-danger" title="Отменить" @click="onCancelEditClick"></i>
		</div>
        <div v-if="!isEdit" class="read-field" v-html="Urlify(description)"/>
    </b-form-group>
</template>

<script>
import Urlify from "@/utils/Urlify";
export default {
    name: "TaskDescription",
    computed: {
        description: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit(`input`, v);
            }
        }
    },
    props: {
        value: String,
        canEdit: {
            type: Boolean,
            default: false
        }
    },
	data() {
		return {
			isEdit: false,
			oldDescription: ``
		}
	},
	methods: {
		onStartEditClick() {
			this.isEdit = true;
			this.oldDescription = this.description;
		},
		onCancelEditClick() {
			this.isEdit = false;
			this.$emit(`input`, this.oldDescription);
		},
		onOkClick() {
			this.isEdit = false;
		},
        Urlify
	}
}
</script>

<style scoped>
#description::placeholder {
    opacity: 0.7;
}
.read-field {
	white-space: pre-wrap;
}
</style>