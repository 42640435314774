<template>
    <div>
        <div class="d-flex my-2 justify-content-between">
            <b-input-group size="sm">
                <b-form-input
                        v-model="filter"
                        debounce="200"
                        type="search"
                        id="filterInput"
                        placeholder="Введите для поиска"
                ></b-form-input>
            </b-input-group>
            <span class="text-success small mx-2">Найдено записей: {{ totalRows }}</span>
            <b-col sm="7" md="2" class="my-1">
                <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                ></b-pagination>
            </b-col>
            <b-col sm="5" md="3" class="my-1">
                <b-form-group
                        label="На странице"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="4"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="perPageSelect"
                        class="mb-0"
                >
                    <b-form-select
                            v-model="perPage"
                            id="perPageSelect"
                            size="sm"
                            :options="pageOptions"
                    ></b-form-select>
                </b-form-group>
            </b-col>
        </div>
        <b-table
                :fields="fields"
                :filter="filter"
                small
                striped
                :items="items"
                :current-page="currentPage"
                :per-page="perPage"
                @filtered="onFiltered"
                :busy="isBusy"
        >
            <template v-slot:cell(date_time_begin)="data">
                {{ Date.parse(data.item.date_time_begin) | moment("DD.MM.YYYY dd HH:mm")}}
            </template>
            <template v-slot:cell(date_time_end)="data">
                {{ Date.parse(data.item.date_time_end) | moment("DD.MM.YYYY dd HH:mm")}}
            </template>
            <template v-slot:cell(seconds_works)="data">
                <ul v-if="data.item.seconds_works">
                    <li :key="i" v-for="(item, i) in data.item.seconds_works.split(`-->`)">{{ item }}</li>
                </ul>
            </template>
            <template v-slot:cell(notes)="data">
                <ul v-if="data.item.notes">
                    <li :key="i" v-for="(item, i) in data.item.notes.split(`-->`)">{{ item }}</li>
                </ul>
            </template>
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle mx-2"></b-spinner>
                    <strong>Загрузка данных...</strong>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
    export default {
        computed: {
            works() {
                return this.$store.state.completedWorksMainAndSeconds;
            },
            items() {
                return this.$store.state.completedWorksMainAndSeconds;
            }
        },
        data() {
            return {
                fields: [
                    {
                        key: `shift_id`,
                        label: `Ид смены`,
                        sortable: true
                    },
                    {
                        key: `date_time_begin`,
                        label: `Время начала смены`,
                        sortable: true
                    },
                    {
                        key: `date_time_end`,
                        label: `Время окончания смены`,
                        sortable: true
                    },
                    {
                        key: `building_name`,
                        label: `Имя объекта`,
                        sortable: true
                    },
                    {
                        key: `group_name`,
                        label: `Имя группы работ`,
                        sortable: true
                    },
                    {
                        key: `work_name`,
                        label: `Имя работы`,
                        sortable: true
                    },
                    {
                        key: `value`,
                        label: `Выполнено`,
                        sortable: true
                    },
                    {
                        key: `unit_of_measurement`,
                        label: `Единица измерения`,
                        sortable: true
                    },
                    {
                        key: `person_counts`,
                        label: `Задействовано рабочих в данной работе`,
                        sortable: true
                    },
                    {
                        key: `seconds_works`,
                        label: `Вторичные работы`,
                        sortable: true
                    },
                    {
                        key: `notes`,
                        label: `Замечания`,
                        sortable: true
                    }
                ],
                filter: null,
                filterOn: [],
                perPage: 100,
                pageOptions: [5, 10, 15, 20, 100, 200],
                totalRows: 1,
                currentPage: 1,
                isBusy: true
            }
        },
        async mounted() {
            console.time('componentMount');
            this.isBusy = true;
            await this.$store.dispatch(`getCompletedWorksMainAndSeconds`);
            this.totalRows = this.items.length;
            this.isBusy = false;
            console.timeEnd('componentMount');
        },
        methods: {
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            format(val) {
                return val.replace(`.`, `/`);
            }
        },
        name: "CompletedWorks"
    }
</script>

<style scoped>

</style>