<template>
    <div class="filter_options d-flex align-items-end">
        <div class="w-25" v-if="!isParentFilter">
            <span>{{filter.name}}</span>
            <v-select
                    v-model="selected"
                    :multiple="true"
                    :options="options"
                    label="name"
                    :inputId="filter.key"
                    :disabled="hasFilterInDataSets"
            />
        </div>
        <div class="w-25 mx-2" v-if="actionBlockVisible">
            <b-button size="sm" @click="addChart" variant="success" v-if="!hasFilterInDataSets">
                Добавить на график
            </b-button>
            <b-button size="sm" @click="onDeleteFromChart" variant="danger" v-else>
                Удалить с графика
            </b-button>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            isParentFilter() {
                return this.filter.id === `buildingId`;
            },
            labels() {
                return this.$store.getters.getDataLabels.map(el => `${el.getFullYear()}-${el.getMonth() + 1}-${el.getDate()}`);
            },
            dataSets() {
                return this.$store.state.charts.dataSets;
            },
            hasFilterInDataSets() {
                return this.dataSets.some(el => el.id === this.chartId);
            },
            dataName() {
                return this.$store.state.charts.dataSelected.find(el => el.id === this.dataId).name;
            },
            ignoreDates() {
                return this.$store.state.charts.ignoreDates;
            },
            chartId() {
                return `x_${this.dataId}_${this.object.building_id}_${this.filter.id}_${this.selected.map(el => el[this.filter.key])}`
            },
            actionBlockVisible() {
                const length = this.selected.length;
                const isAllSelected = this.selected.some(el => el[this.filter.key] === `all`);
                if (this.filter.multiple) {
                    return length > 1
                        ? !isAllSelected
                        : length
                } else {
                    return length === 1;
                }
            }
        },
        name: "FilterOptions",
        async mounted() {
            const options = await this.$store.dispatch(`getFilterOptions`, {
                url: `${this.dataId}/filters/${this.filter.id}`,
                building_id: this.object.building_id
            });
            const excludeAllOption = [`efficiencyWork`, `efficiencyGroupWork`, `efficiencyByAnchor`];
            this.options = excludeAllOption.includes(this.dataId)
                ? options
                : [{
                    [this.filter.key]: `all`,
                    name: `Все`
                }].concat(options);

            if (this.isParentFilter) {
                this.selected = [
                    this.options[1]
                ]
            }
        },
        props: {
            dataId: String,
            filter: Object,
            filterRowId: Number,
            object: Object
        },
        data() {
            return {
                selected: [],
                options: []
            }
        },
        methods: {
            async addChart() {
                this.$store.commit(`setShowChartsSpinner`, true);
                const data = await this.$store.dispatch(`chartsGetData`, {
                    url: `${this.dataId}/data`,
                    key: this.filter.key,
                    value: this.selected.map(el => el[this.filter.key]),
                    building_id: this.object.building_id
                });
                const chartData = data.map(el => {
                    return {
                        x: el.date,
                        y: el.value
                    }
                })
                await this.$store.dispatch(`addChart`, {
                    chartData,
                    chartId: this.chartId,
                    chartLabel: this.selected.map(el => `${this.object.name} (${this.dataName}__${el.name} (${el.unit_of_measurement}))`).join(` / `),
                    dataId: this.dataId,
                    objectId: this.object.building_id
                })
                this.$store.commit(`setShowChartsSpinner`, false);
            },
            onDeleteFromChart() {
                const datasets = this.dataSets.filter(el => el.id !== this.chartId);
                this.$store.commit(`setDataSets`, datasets);
                this.$emit(`deleteFilter`);
            }
        }
    }
</script>

<style scoped>

</style>