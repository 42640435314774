<template>
    <b-form-group
        label="Постановщик"
        label-for="author"
        label-class="small font-weight-bold"
    >
        <UserCard :value="value"/>
    </b-form-group>
</template>

<script>
import UserCard from "@/views/Tasks/UserCard/UserCard";
export default {
    name: "TaskAuthor",
    components: {
        UserCard
    },
    props: {
        value: Object
    }
}
</script>

<style scoped>

</style>