<template>
    <div class="p-5 border border-success mt-5 mx-2">
        <div class="d-flex justify-content-end my-2">
            <b-button
                    v-b-modal.typeWorkModalWizard
                    size="sm"
                    variant="success"
                    v-if="$can('create', 'workTypes')"
            >
                Работы нет в списке
            </b-button>
        </div>
        <div>
            <span>Выбрать работу</span>
            <v-select
                    append-to-body
                    label="name"
                    :options="items"
                    :multiple="true"
                    v-model="targets"
                    :filter="(options, search) => searchText(options, search, [`group_name`, `name`])"
            >
                <template #option="{name, group_name}">
                    <span class="small font-weight-bold">Имя:</span> {{name}}
                    <span v-if="group_name">
                        <span class="small font-weight-bold">Группа:</span>
                         {{ group_name}}
                    </span>
                </template>
            </v-select>
        </div>
        <div class="mt-3" v-if="targets.length">
            <b-table
                    :items="targets"
                    :fields="fields"
            >
                <template v-slot:cell(name_project)="data">
                    <b-form-input v-model="data.item.name_project" size="sm"></b-form-input>
                </template>
                <template v-slot:cell(value)="data">
                    <b-form-input v-model="data.item.value" size="sm" type="number" class="w-50"></b-form-input>
                </template>
            </b-table>
        </div>
        <b-modal id="typeWorkModalWizard"
                 title="Создание работы"
                 @ok="createTypeWork()"
                 @hide="clearForm()"
                 ok-title="Ок"
                 ok-variant="success"
                 cancel-title="Отмена"
                 cancel-variant="danger"
                 :ok-disabled="!Boolean(name) || !Boolean(measurement)"
                 no-close-on-backdrop
        >
            <label for="name">Имя работы</label>
            <b-form-input
                    v-model="name"
                    placeholder="Стрижка бобра"
                    id="name"
                    size="sm"
                    :state="Boolean(name)"
            ></b-form-input>
            <label for="measurement">Единица изменения</label>
            <b-form-input
                    v-model="measurement"
                    placeholder="Пог. см."
                    id="measurement"
                    size="sm"
                    :state="Boolean(measurement)"
            ></b-form-input>
            <label for="workGroup">Группа работ</label>
            <v-select
                    :options="workGroups"
                    v-model="groupId"
                    :reduce="group => group.id"
                    label="name"
                    :filter="(options, search) => searchText(options, search, [`name`])"
            >
            </v-select>
            <b-button variant="info" size="sm" class="my-2" @click="showAddGroup"
                      v-if="$can('create', 'workGroups')">
                Группы работ нет в списке
            </b-button>
        </b-modal>
        <CreateWorkGroup
                :show="addGroupModalShow"
                @hidden="hideAddGroup"
                @workGroupCreated="workGroupCreated"
        />
    </div>
</template>

<script>
    import searchText from "../../../../utils/searchText";
    import CreateWorkGroup from "../../../../components/CreateWorkGroup/CreateWorkGroup.vue";

    export default {
        computed: {
            items() {
                return this.$store.state.workTypes;
            },
            targets: {
                get() {
                    return this.$store.state.wizard.targets;
                },
                set(val) {
                    this.$store.commit(`setWizardTargets`, val.map(el => {
                        el.name_project = el.name_project || ``;
                        el.value = el.value || 0;
                        return el;
                    }));
                }
            },
            workGroups() {
                return this.$store.state.workGroups;
            }
        },
        components: {
            CreateWorkGroup
        },
        data() {
            return {
                fields: [
                    {
                        key: `name`,
                        label: `Имя работы`
                    },
                    {
                        key: `group_name`,
                        label: `Группа работ`
                    },
                    {
                        key: `name_project`,
                        label: `Имя работы проектное`
                    },
                    {
                        key: `value`,
                        label: `Необходимо выполнить`
                    },
                    {
                        key: `unit_of_measurement`,
                        label: `Ед. изм.`
                    }
                ],
                groupId: null,
                name: ``,
                measurement: null,
                addGroupModalShow: false
            }
        },
        methods: {
            clearForm() {
                this.name = ``;
                this.measurement = null;
            },
            async createTypeWork() {
                const {measurement, name, groupId} = this;
                const data = {
                    name,
                    unit_of_measurement: measurement,
                    group_id: groupId
                };
                await this.$store.dispatch(`createWorkType`, data).then(() => {
                    this.clearForm();
                    this.$store.dispatch(`getWorkTypes`);
                }).catch(err => {
                    this.clearForm();
                    this.$store.dispatch(`showErrorModal`, err.response.data);
                });
            },
            searchText,
            showAddGroup() {
                this.addGroupModalShow = true;
            },
            hideAddGroup() {
                this.addGroupModalShow = false;
            },
            async workGroupCreated(createdGroupId) {
                await this.$store.dispatch(`getWorkGroups`);
                this.groupId = createdGroupId;
                this.hideAddGroup();
            }
        },
        mounted() {
            this.$store.dispatch(`getWorkTypes`);
            this.$store.dispatch(`getWorkGroups`);
            this.$emit(`can-continue`, {
                value: this.targets.length
            });
        },
        name: "StepSeven",
        watch: {
            targets(val) {
                this.$emit(`can-continue`, {
                    value: val.length
                });
            }
        }
    }
</script>

<style scoped>

</style>