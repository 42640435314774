<template>
    <div>
        <b-card v-for="[name, shifts_] in Object.entries(availableObjectsFromShifts)" :key="name" :title="name"
                class="m-0 scroll-container">
            <b-table :items="shifts_" small striped :fields="fields">
                <template v-slot:cell(shift_id)="{item}">
                    <a :href="`https://t.me/c/${item.bp.common_chat_id.replace(`-100`, ``)}/${item.telegram_message_id}`"
                       target="_blank">
                        {{ item.shift_id }}
                    </a>
                </template>
                <template v-slot:cell(date_time_begin)="data">
                    {{ $moment(data.item.date_time_end).format(`DD.MM.YYYY (dd) HH:mm`) }}
                </template>
                <template v-slot:cell(date_time_end)="data">
                    {{ $moment(data.item.date_time_end).format(`DD.MM.YYYY (dd) HH:mm`) }}
                </template>
                <template v-slot:cell(Author)="{item}">
                    <a :href="`https://t.me/+${item.Author.phone}`" target="_blank">
                        {{ item.Author.first_name }} {{ item.Author.last_name }}
                    </a>
                </template>
                <template v-slot:cell(bp.MainStaff)="{item}">
                    <a :href="`https://t.me/+${item.bp.MainStaff.phone}`" target="_blank">
                        {{ item.bp.MainStaff.first_name }} {{ item.bp.MainStaff.last_name }}
                    </a>
                </template>
                <template v-slot:cell(bp.Curator)="{item}">
                    <a :href="`https://t.me/+${item.bp.Curator.phone}`" target="_blank">
                        {{ item.bp.Curator.first_name }} {{ item.bp.Curator.last_name }}
                    </a>
                </template>
                <template v-slot:cell(cause)="{item}">
                    <b-form-textarea style="min-width: 200px" size="sm" :value="item.cause" :formatter="formatter"
                                     @input="v => onCauseChange(item, v)"/>
                    <span class="text-info small" v-if="item.cause">Использовано {{ item.cause.length }} из {{ limit }} символов</span>
                    <span class="text-danger small"
                          v-if="!item.cause">Обязательно для заполнения</span>
                </template>
            </b-table>
        </b-card>
        <div class="d-flex justify-content-end p-3" v-if="showSendOrCreateBlock">
          <b-form-group>
            <b-form-radio v-model="sendOnCreate" name="send-request" :value="false">
              Сохранить как черновик
              <small class="text-muted d-block mb-3">
                Ваша заявка будет сохранена, но не отправлена на согласование.<br>
                Черновик вы сможете отредактировать и отправить в любой момент.
              </small>
            </b-form-radio>
            <b-form-radio v-model="sendOnCreate" name="send-request" :value="true">
              {{ sendText }}
            </b-form-radio>
          </b-form-group>
        </div>
    </div>
</template>

<script>
export default {
    name: "ThreeStep",
    computed: {
        selectedShifts() {
          return this.$store.state.deleteShiftReport.selectedShifts;
        },
        availableObjectsFromShifts() {
          return this.selectedShifts.reduce((groups, item) => {
            const key = item.bp.bo.name;
            if (!groups[key]) {
              groups[key] = [];
            }
            groups[key].push(item);
            return groups;
          }, {});
        },
        isAdmin() {
            return this.$store.getters.isAdmin;
        },
        currentUser() {
            return this.$store.getters.currentUser;
        },
        selectedObject() {
            return this.$store.state.deleteShiftReport.selectedObject;
        },
        sendText() {
            const {currentUser, selectedObject} = this;
            return selectedObject.curator_id === currentUser.userId
                ? `Согласовать и исполнить`
                : `Отправить куратору на согласование`;
        },
        sendOnCreate: {
            get() {
                return this.$store.state.deleteShiftReport.sendOnCreate;
            },
            set(v) {
                this.$store.commit(`requests_setSendOnCreate`, v);
            }
        },
        editRequest() {
            return this.$store.state.deleteShiftReport.editRequest;
        },
        showSendOrCreateBlock() {
            if (this.editRequest) {
                return this.editRequest.status !== 0;
            } else {
                return this.selectedObject;
            }
        }
    },
    data() {
        return {
            fields: [
                {
                    key: `shift_id`,
                    label: `№ отчёта`
                },
                {
                    key: `cause`,
                    label: `Причина удаления`
                },
                {
                    key: `date_time_begin`,
                    label: `Начало смены`
                },
                {
                    key: `date_time_end`,
                    label: `Окончание смены`
                },
                {
                    key: `Author`,
                    label: `Автор отчета`
                },
                {
                    key: `bp.MainStaff`,
                    label: `Прораб`
                },
                {
                    key: `bp.Curator`,
                    label: `Куратор`
                }
            ],
            limit: 100
        }
    },
    mounted() {
        this.$emit('can-continue', {value: this.selectedShifts.every(el => el.cause)});
    },
    methods: {
        onCauseChange({shift_id}, v) {
            const {selectedShifts} = this;
            const n = selectedShifts.map(el => {
                if (el.shift_id === shift_id) {
                    el.cause = v;
                }
                return el;
            })
            this.$store.commit(`requests_setSelectedShifts`, n);
        },
        formatter(v) {
            const {limit} = this;
            return v.length > limit ? v.slice(0, limit) : v;
        }
    },
    watch: {
        selectedShifts(v) {
            this.$emit('can-continue', {value: v.every(el => el.cause)});
        }
    }
}
</script>

<style scoped>
.scroll-container {
  max-height: 50vh; /* Или любая другая высота, которая вам нужна */
  overflow-y: scroll;
  scrollbar-gutter: stable;
}

/* Стилизация полос прокрутки для Chromium-браузеров */
.scroll-container::-webkit-scrollbar {
  width: 8px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

.scroll-container::-webkit-scrollbar-track {
  background-color: transparent;
}
</style>
