<template>
  <b-card class="mt-2" header-class="p-2">
    <template #header>
      <div class="d-flex justify-content-between" :id="request.id">
        <div class="w-50 mr-3">
          <div>
                        <span class="small">Id:
                            <a href="" @click.prevent="$router.push(`/requests/delete-shift-report/${request.id}`)">
                                {{ request.id }}
                            </a>
                        </span>
          </div>
          <div>
            <span class="mt-2">{{ request.bp.bo.name }}</span>
          </div>
          <div>
            <b-badge class="mt-2" :variant="getStatusVariant(request.status)">
              {{ getStatusText(request.status) }}
            </b-badge>
          </div>
        </div>
        <div class="w-50">
          <div>
                        <span class="small">
                            Автор заявки:
                            <b v-html="getResponsibleName(request.Author)"></b>
                        </span>
          </div>
          <div>
                        <span class="small">
                            Согласовывает:
                            <b v-html="getResponsibleName(request.Inspector)"></b>
                        </span>
          </div>
          <div>
                        <span class="small">
                            Создана:
                            <b>{{ $moment(request.createdAt).format('YYYY-MM-DD (dd) HH:mm') }}</b>
                        </span>
          </div>
          <div>
                        <span class="small text-danger">
                            Прошло с момента создания:
                            <b>{{ Math.abs($moment(request.createdAt).diff($moment(), 'days')) }}</b>
                            {{ daysPlural(Math.abs($moment(request.createdAt).diff($moment(), 'days'))) }}
                        </span>
          </div>
        </div>
      </div>
    </template>
    <span>Номера отчётов для удаления:</span>
    <div v-for="(shift, i) in getLinks()" :key="i" v-html="shift" class="ml-1" />
    <div class="d-flex justify-content-end">
      <b-button
          size="sm"
          class="mx-2"
          variant="warning"
          v-if="request.status === 0"
          @click="() => $router.push(`/requests/delete-shift-report/create?request=${request.id}`)"
      >
        Редактировать заявку
      </b-button>
      <b-button
          size="sm"
          class="mx-2"
          variant="success"
          v-if="request.status === 3"
          @click="() => $router.push(`/requests/delete-shift-report/create?request=${request.id}`)"
      >
        Повторить заявку
      </b-button>
      <b-button
          size="sm"
          variant="info"
          @click.prevent="$router.push(`/requests/delete-shift-report/${request.id}`)"
      >
        Открыть заявку
      </b-button>
    </div>
  </b-card>
</template>

<script>
import daysPlural from "@/utils/daysPlural";

export default {
  name: "RequestShortItem",
  props: {
        request: Object
  },
    computed: {},
  methods: {
    getResponsibleName(user) {
            return user ?
                `<a href="https://t.me/+${user.phone}" target="_blank">${user.first_name} ${user.last_name}</a>`
          : ``;
    },
    daysPlural,
    getLinks() {
      const { request, $moment } = this;
            return request.causes.map(el => {
        const time = $moment(el.ws ? el.ws.date_time_begin : el.date_time_begin).format(`DD.MM.YYYY dd HH:mm`);
                return `<a href="https://t.me/c/${request.bp.common_chat_id.replace(`-100`, ``)}/${el.ws ? el.ws.telegram_message_id : el.telegram_message_id}"
                target="_blank">${el.shift}</a> от ${time} - ${el.cause}`;
            })
    },
    getStatusText(status) {
      switch (status) {
        case 0:
          return `Черновик`;
        case 1:
          return `На согласовании`;
        case 2:
          return `Согласована и исполнена`;
        case 3:
          return `Отказано`;
        case 4:
          return `Аннулирована`;
        default:
          return ``;
      }
    },
    getStatusVariant(status) {
      switch (status) {
        case 0:
          return 'secondary';
        case 1:
          return 'warning';
        case 2:
          return 'success';
        case 3:
          return 'danger';
        case 4:
          return 'danger';
        default:
          return 'secondary';
      }
    },
  },
  mounted() {
    if (this.$route.hash === `#${this.request.id}`) {
      this.$emit(`scrollToMe`, this.request);
    }
  },
};
</script>

<style scoped>
</style>
