import { render, staticRenderFns } from "./EditMaterialGroup.vue?vue&type=template&id=c2320962&scoped=true&"
import script from "./EditMaterialGroup.vue?vue&type=script&lang=js&"
export * from "./EditMaterialGroup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2320962",
  null
  
)

export default component.exports