<template>
    <div>
        <RequestsNavbar class="mb-2"
                        :sortOptions="sortOptions"
                        :sortBy="sortBy"
                        :sortDesc="sortDesc"
                        :filter="filter"
                        @sortByChange="onSortByChange"
                        @sortDescChange="onSortDescChange"
                        @filterChange="onFilterChange"
        />
        <b-tabs card v-model="selectedTab">
            <b-tab title="Я автор заявки" ref="myTask">
                <div class="d-flex justify-content-center">
                    <span class="text-success" v-if="!authorRequests.length && !onProcess">
                        У вас нет заявок в данной вкладке
                    </span>
                    <b-spinner label="Loading..." v-if="onProcess"></b-spinner>
                </div>
                <RequestShortItem v-for="(req, i) in sorted(authorRequests)" :request="req" :key="i"
                                  :id="`id_${req.id}`"
                                  :ref="`ref_${req.id}`" @scrollToMe="scrollToElement"/>
            </b-tab>
            <b-tab title="Я согласовываю" ref="inspector">
                <div class="d-flex justify-content-center">
                    <span class="text-success" v-if="!inspectorRequests.length && !onProcess">
                        У вас нет заявок в данной вкладке
                    </span>
                    <b-spinner label="Loading..." v-if="onProcess"></b-spinner>
                </div>
                <RequestShortItem v-for="(req, i) in sorted(inspectorRequests)" :request="req" :key="i"
                                  :id="`id_${req.id}`"
                                  :ref="`ref_${req.id}`" @scrollToMe="scrollToElement"/>
            </b-tab>
            <b-tab title="Всех пользователей" v-if="isAdmin" ref="all">
                <div class="d-flex justify-content-center">
                    <span class="text-success" v-if="!filtered.length && !onProcess">
                        У вас нет заявок в данной вкладке
                    </span>
                    <b-spinner label="Loading..." v-if="onProcess"></b-spinner>
                </div>
                <RequestShortItem v-for="(req, i) in sorted(filtered)" :request="req" :key="i" :id="`id_${req.id}`"
                                  :ref="`ref_${req.id}`" @scrollToMe="scrollToElement"/>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import RequestShortItem from './RequestShortItem/RequestShortItem';
import RequestsNavbar from "./RequestsNavbar/RequestsNavbar";

export default {
    name: "DeleteShiftReport",
    components: {
        RequestShortItem,
        RequestsNavbar
    },
    computed: {
        filtered() {
            const {filter, requests, $moment} = this;
            if (!filter || filter.length < 2) {
                return requests;
            }
            return requests.filter(el => {
                const {Author, Inspector, bp, causes} = el;
                let commonString = ``;
                if (Author) {
                    commonString += Author.first_name;
                    commonString += Author.last_name;
                }
                if (Inspector) {
                    commonString += Inspector.first_name;
                    commonString += Inspector.last_name;
                }
                if (bp) {
                    commonString += bp.bo.name;
                }
                if (causes.length) {
                    causes.forEach(el => {
                        commonString += el.shift;
                        if (el.ws) {
                            commonString += $moment(el.ws.date_time_begin).format(`DD.MM.YYYY`);
                        }
                        if (el.date_time_begin) {
                            commonString += $moment(el.date_time_begin).format(`DD.MM.YYYY`);
                        }
                    })
                }
                return commonString.toLowerCase().includes(filter.toLowerCase());
            })
        },
        user() {
            return this.$store.getters.currentUser;
        },
        isAdmin() {
            return this.$store.getters.isAdmin;
        },
        authorRequests() {
            const {user: {userId}} = this;
            return this.filtered.filter(el => el.author === userId);
        },
        inspectorRequests() {
            const {user: {userId}} = this;
            return this.filtered.filter(el => el.inspector === userId);
        },
        filter: {
            get() {
                return this.$store.state.deleteShiftReport.filter;
            },
            set(v) {
                this.$store.commit(`requests_setFilter`, v);
            }
        },
        sortOptions() {
            return this.$store.state.deleteShiftReport.sortOptions;
        },
        sortBy() {
            return this.$store.state.deleteShiftReport.sortBy;
        },
        sortDesc() {
            return this.$store.state.deleteShiftReport.sortDesc;
        },
        selectedTab: {
            get() {
                return this.$store.state.deleteShiftReport.selectedTab;
            },
            set(v) {
                this.$store.commit(`requests_setSelectedTab`, v);
            }
        }
    },
    data() {
        return {
            requests: [],
            onProcess: false
        }
    },
    mounted() {
        this.updateRequestList();
    },
    methods: {
        sorted(data) {
            const {sortDesc, sortBy, $_} = this;
            const newStatus = $_.sortBy(data.filter(el => el.status === 0), sortBy);
            const onProcessStatus = $_.sortBy(data.filter(el => el.status === 1), sortBy);
            const approveStatus = $_.sortBy(data.filter(el => el.status === 2), sortBy);
            const rejectStatus = $_.sortBy(data.filter(el => el.status === 3), sortBy);
            const cancelStatus = $_.sortBy(data.filter(el => el.status === 4), sortBy);
            return sortDesc
                ? onProcessStatus.reverse().concat(newStatus, approveStatus, rejectStatus, cancelStatus)
                : onProcessStatus.concat(newStatus.reverse(), approveStatus.reverse(), rejectStatus.reverse(), cancelStatus.reverse());
        },
        getRequests() {
            return this.$store.dispatch(`requests_getRequests`);
        },
        updateRequestList() {
            this.onProcess = true;
            return this.getRequests().then(list => this.requests = list).finally(() => this.onProcess = false);
        },
        scrollToElement(task) {
            const tabs = [`myTask`, `inspector`, `all`];
            const activeTab = this.$refs[tabs[this.selectedTab]];
            for (let i = 0; i < activeTab.$children.length; i++) {
                if (activeTab.$children[i].$attrs.id === `id_${task.id}`) {
                    this.$nextTick(() => {
                        if (!this.scrolled) {
                            activeTab.$children[i].$el.scrollIntoView({behavior: "smooth", block: "center"});
                        }
                        this.scrolled = true;
                    })
                }
            }
        },
        onSortByChange(v) {
            this.$store.commit(`requests_setSortBy`, v);
        },
        onSortDescChange(v) {
            this.$store.commit(`requests_setSortDesc`, v);
        },
        onFilterChange(v) {
            this.filter = v;
        }
    }
}
</script>

<style scoped>

</style>