<template>
    <div>
        <b-table striped hover :items="chats">
        </b-table>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "UserChats",
        data() {
            return {
                chats: []
            }
        },
        mounted() {
            axios.get(`/api/user-chats`).then(res => {
                this.chats = res.data;
            }).catch(err => {
                console.log(err);
            })
        }
    }
</script>

<style scoped>

</style>