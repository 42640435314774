<template>
    <div class="data_selector">
        <label class="sm font-weight-bold">Доступные графики</label>
        <v-select
                v-model="selected"
                :multiple="true"
                :options="options"
                label="name"
                inputId="id"
                @option:deselecting="val => onDataDeselecting(val)"
        />
    </div>
</template>

<script>
    export default {
        computed: {
            selected: {
                get() {
                    return this.$store.state.charts.dataSelected;
                },
                set(val) {
                    this.$store.commit(`setDataSelected`, val);
                }
            },
            options() {
                return this.$store.state.charts.mainData.data;
            }
        },
        methods: {
            onDataDeselecting({id}) {
                this.$store.dispatch(`deleteChart`, {
                    key: `dataId`,
                    value: id
                });
            }
        },
        name: "DataSelector"
    }
</script>

<style scoped>
    .data_selector {
        min-width: 350px;
        width: auto;
    }
</style>