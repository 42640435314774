import axios from 'axios';
import Vue from 'vue';
import {BModal} from 'bootstrap-vue';
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
Vue.component('b-modal', BModal);
axios.interceptors.response.use(r => r, (error) => {
    const vm = new Vue;
    const err = error.response.data.msg || error.response.data;
    const urlParsed = error.config.url.split(`/`);
    const method = error.config.method;
    const isPut = method === `put`;
    const isPhotos = urlParsed[2] === `photos`;
    const isAddUsersToTelegram = isPut && urlParsed[2] === `telegram` && urlParsed[3] === `groups`;
    if (error.response.status !== 401 && !isPhotos && !isAddUsersToTelegram) {
        vm.$bvModal.msgBoxOk(JSON.stringify(err), {
            okVariant: 'danger',
            title: error.message
        });
    }
    return Promise.reject(error);
});

export default axios;