<template>
    <div>
        <div class="d-flex justify-content-end my-2">
            <b-button
                    v-b-modal.createMaterialTarget
                    size="sm"
                    variant="success"
                    v-if="$can('create', 'materialTargets')"
            >
                Добавить объем материалов по договору
            </b-button>
        </div>
        <div class="d-flex my-2 justify-content-between">
            <b-input-group size="sm">
                <b-form-input
                        v-model="filter"
                        type="search"
                        id="filterInput"
                        placeholder="Введите для поиска"
                ></b-form-input>
            </b-input-group>
            <b-col sm="7" md="2" class="my-1">
                <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                ></b-pagination>
            </b-col>
            <b-col sm="5" md="3" class="my-1">
                <b-form-group
                        label="На странице"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="4"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="perPageSelect"
                        class="mb-0"
                >
                    <b-form-select
                            v-model="perPage"
                            id="perPageSelect"
                            size="sm"
                            :options="pageOptions"
                    ></b-form-select>
                </b-form-group>
            </b-col>
        </div>
        <b-table
                :fields="fields"
                :filter="filter"
                small
                striped
                :items="items"
                :current-page="currentPage"
                :per-page="perPage"
                @filtered="onFiltered"
        >
            <template v-slot:cell(actions)="data">
                <i class="fas fa-pen text-center text-success mx-2"
                   @click="onEdit(data.item)"
                   v-if="$can('update', 'materialTargets')"
                ></i>
                <i class="fas fa-trash-alt text-danger text-center mx-2"
                   @click="onDelete(data.item)"
                   v-if="$can('delete', 'materialTargets')"
                ></i>
            </template>
        </b-table>
        <delete-material-target></delete-material-target>
        <edit-material-target></edit-material-target>
    </div>
</template>

<script>
    import DeleteMaterialTarget from "./DeleteTarget/DeleteMaterialTarget.vue";
    import EditMaterialTarget from "./EditTarget/EditMaterialTarget.vue";
    export default {
        components: {
            DeleteMaterialTarget,
            EditMaterialTarget
        },
        computed: {
            items() {
                return this.$store.state.materials.targets;
            }
        },
        data() {
            return {
                fields: [
                    {
                        key: `object_name`,
                        label: `Имя объекта`,
                        sortable: true
                    },
                    {
                        key: `target_work_name`,
                        label: `Работа`,
                        sortable: true
                    },
                    {
                        key: `target_work_measures`,
                        label: `ЕИ`
                    },
                    {
                        key: `target_work_value`,
                        label: `Объем`
                    },
                    {
                        key: `material_name`,
                        label: `Имя материала`,
                        sortable: true
                    },
                    {
                        key: `measures`,
                        label: `ЕИ`
                    },
                    {
                        key: `value`,
                        label: `Кол-во материала`,
                        sortable: true
                    },
                    {
                        key: `limit`,
                        label: `Граница срабатывания тревоги, %`,
                        sortable: true
                    },
                    {
                        label: ``,
                        key: `actions`,
                        class: `w-80px`
                    }
                ],
                filter: null,
                filterOn: [],
                perPage: 200,
                pageOptions: [5, 10, 15, 20, 100, 200],
                totalRows: 1,
                currentPage: 1,
                value: null
            }
        },
        async mounted() {
            await this.$store.dispatch(`getMaterialTargets`);
            this.totalRows = this.items.length;
        },
        methods: {
            onDelete({id}) {
                this.$store.commit(`setSelectedMaterialTargetId`, id);
                this.$bvModal.show(`deleteMaterialTarget`);
            },
            onEdit({id}) {
                this.$store.commit(`setSelectedMaterialTargetId`, id);
                this.$bvModal.show(`editMaterialTarget`);
            },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length
                this.currentPage = 1
            }
        },
        name: "MaterialTargets"
    }
</script>

<style scoped>

</style>