<template>
    <div class="px-3">
        <div class="mb-4">
            <label class="sm font-weight-bold">Выберите объект</label>
            <v-select
                    v-model="selectedObjects"
                    :multiple="true"
                    :options="objects"
                    label="name"
                    inputId="id"
                    @option:deselecting="val => onObjectDeselecting(val)"
            />
            <ObjectTabs :objects="selectedObjects" :dataId="dataId" :activeTab="activeTab"/>
        </div>
    </div>
</template>

<script>
    import ObjectTabs from "../ObjectTabs/ObjectTabs.vue";

    export default {
        computed: {
            objects() {
                return [{building_id: `all`, name: `Все`}].concat(this.$store.state.charts.mainData.buildings);
            },
            labels() {
                return this.$store.getters.getDataLabels.map(el => `${el.getFullYear()}-${el.getMonth() + 1}-${el.getDate()}`);
            },
            ignoreDates() {
                return this.$store.state.charts.ignoreDates;
            },
            activeTab() {
                return this.selectedObjects.length - 1;
            }
        },
        components: {
            ObjectTabs
        },
        data() {
            return {
                selectedObjects: []
            }
        },
        methods: {
            onObjectDeselecting({building_id}) {
                this.$store.dispatch(`deleteChart`, {
                    key: `objectId`,
                    value: building_id
                });
            }
        },
        name: "FilterTabContent",
        props: {
            dataId: String
        }
    }
</script>

<style scoped>

</style>