import Vue from 'vue';
import axios from 'axios';

const token = localStorage.getItem('token');
if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
Vue.prototype.$http = axios;
Vue.config.productionTip = false;
Vue.config.performance = true;

export default Vue