<template>
    <b-form-group
        label-for="responsible"
        label-class="small font-weight-bold"
    >
      <template #label>
        Ответственный <span class="text-danger">*</span>
      </template>
      <UserCard v-if="value" :value="value" :canRemove="canEdit" @remove="() => $emit(`input`, null)"/>
        <UserSelectDropdown v-if="canEdit && !value" @change="v => $emit(`input`, v)"/>
    </b-form-group>
</template>

<script>
import UserCard from "@/views/Tasks/UserCard/UserCard";
import UserSelectDropdown from "@/views/Tasks/UserSelectDropdown/UserSelectDropdown";
export default {
    name: "TaskResponsible",
    components: {
        UserCard,
        UserSelectDropdown
    },
    props: {
        value: Object,
        canEdit: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>

</style>