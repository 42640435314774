<template>
    <div>
        <label for="name">Имя</label>
        <b-form-input
                v-model="name"
                placeholder="Замена покрытия быстрая плит бетонных"
                id="name"
                size="sm"
                :state="Boolean(name)"
                autocomplete="off"
        />

        <label for="group">Родительская группа</label>
        <b-form-input
                v-model="group"
                placeholder="Замена покрытия"
                id="groupInput"
                size="sm"
                :state="Boolean(group)"
                list="groupList"
        />
        <b-form-datalist id="groupList" :options="variants(workGroups, `group`)"/>

        <label for="unit">Ед. изм.</label>
        <b-form-input
                v-model="unit"
                placeholder="м2"
                id="unit"
                size="sm"
                :state="Boolean(unit)"
                list="unitList"
        />
        <b-form-datalist id="unitList" :options="variants(workGroups, `unit`)"/>

        <label for="method">Метод</label>
        <b-form-input
                v-model="method"
                placeholder="быстрая"
                id="method"
                size="sm"
                :state="Boolean(method)"
                list="methodList"
        />
        <b-form-datalist id="methodList" :options="variants(workGroups, `method`)"/>

        <label for="target">Цель</label>
        <b-form-input
                v-model="target"
                placeholder="скол бетонной плиты"
                id="target"
                size="sm"
                :state="Boolean(target)"
                list="targetList"
        />
        <b-form-datalist id="targetList" :options="variants(workGroups, `target`)"/>

        <label for="material">Материал</label>
        <b-form-input
                v-model="material"
                placeholder="бетон"
                id="material"
                size="sm"
                :state="Boolean(material)"
                list="materialList"
        />
        <b-form-datalist id="materialList" :options="variants(workGroups, `material`)"/>

        <label for="equipment">Инструмент</label>
        <b-form-input
                v-model="equipment"
                placeholder="Gomako или Guntert"
                id="equipment"
                size="sm"
                :state="Boolean(equipment)"
                list="equipmentList"
        />
        <b-form-datalist id="equipmentList" :options="variants(workGroups, `equipment`)"/>
    </div>
</template>

<script>
    import groupedVariants from "../../../utils/groupedVariants";
    export default {
        name: "WorkGroupItem",
        computed: {
            workGroups() {
                return this.$store.state.workGroups;
            }
        },
        data() {
            return {
                name: ``,
                group: ``,
                unit: ``,
                method: ``,
                target: ``,
                material: ``,
                equipment: ``
            }
        },
        methods: {
            variants: groupedVariants
        },
        mounted() {
            if (this.item) {
                const {name, group, unit, method, target, material, equipment} = this.item;
                this.name = name;
                this.group = group;
                this.unit = unit;
                this.method = method;
                this.target = target;
                this.material = material;
                this.equipment = equipment;
            }
        },
        props: [
            `item`
        ]
    }
</script>

<style scoped>

</style>