<template>
    <b-card>
        <b-table small striped :items="items" :fields="fields">
            <template v-slot:cell(folder_path)="data">
                <a :href="`file:///${data.item.folder_path}`" target="_blank">
                    {{data.item.folder_path}}
                </a>
            </template>
        </b-table>
    </b-card>
</template>

<script>
    export default {
        name: "detailTable",
        data() {
            return {
                fields: [
                    {
                        key: `staff_phone`,
                        label: `Телефон прораба`
                    },
                    {
                        key: `curator_phone`,
                        label: `Телефон куратора`
                    },
                    {
                        key: `folder_path`,
                        label: `Рабочая папка`
                    }
                ]
            }
        },
        props: {
            items: Array
        }
    }
</script>

<style scoped>

</style>