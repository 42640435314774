<template>
    <b-card
            border-variant="primary"
            :header="getShiftTitle(data)"
            body-class="p-0"
    >
        <Images :shiftId="data.shiftId" :images="data.files"/>
    </b-card>
</template>

<script>
    import Images from "../../images/Images";
    export default {
        name: "ShiftImages",
        components: {
            Images
        },
        methods: {
            getShiftTitle({shiftId, dateTime}) {
                return `Смена ${shiftId} от ${this.$moment(dateTime).format("DD.MM.YYYY dddd HH:mm")}`
            }
        },
        props: {
            data: Object
        }
    }
</script>

<style scoped>

</style>