<template>
    <div>
        <canvas ref="chartCanvas" width="auto" height="auto"></canvas>
        <div class="p-2 d-flex justify-content-end">
            <b-button variant="success" @click="downloadImage" size="sm">Скачать изображение</b-button>
        </div>
    </div>
</template>

<script>
    import Chart from 'chart.js/auto';

    export default {
        name: "CustomCharts",
        computed: {
            selectedData() {
                return this.$store.state.charts.dataSelected;
            },
            selectedDate() {
                return this.$store.state.charts.selectedDate;
            },
            ignoreDates() {
                return this.$store.state.charts.ignoreDates;
            }
        },
        data() {
            return {
                chart: null
            }
        },
        mounted() {
            const scales = this.getScales();
            this.render(this.datasets, scales);
        },
        methods: {
            render(datasets, scales) {
                if (this.chart) {
                    this.chart.destroy();
                }
                const data = {
                    "data": {
                        datasets
                    },
                    "options": {
                        scales,
                        responsive: true,
                        maintainAspectRatio: true,
                        animation: {
                            duration: 0
                        },
                        hover: {
                            animationDuration: 0
                        },
                        responsiveAnimationDuration: 0
                    },
                    "type": "line"
                }
                this.chart = new Chart(this.$refs.chartCanvas, data);
            },
            getScales() {
                const today = new Date;
                const todayString = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
                const getMinDate = dataset => {
                    const filtered = dataset.data.filter(el => el.y !== null);
                    return filtered.length ? filtered[0].x : todayString;
                }
                const getMaxDate = dataset => {
                    const filtered = dataset.data.filter(el => el.y !== null);
                    return filtered.length ? filtered.reverse()[0].x : todayString;
                }
                let scales = {}
                if (this.ignoreDates) {
                    this.datasets.forEach(el => {
                        el.xAxisID = el.id;
                        scales[el.xAxisID] = {
                            type: "time",
                            min: getMinDate(el),
                            max: getMaxDate(el),
                            time: {
                                stepSize: 1,
                                unit: 'day',
                                displayFormats: {
                                    day: 'DD-MM-YYYY'
                                }
                            },
                            title: {
                                display: true,
                                text: el.label
                            }
                        }
                    })
                } else {
                    this.datasets.forEach(el => {
                        el.xAxisID = `x_axis`;
                    })
                    scales.x_axis = {
                        type: "time",
                        time: {
                            stepSize: 1,
                            unit: 'day',
                            displayFormats: {
                                day: 'DD-MM-YYYY'
                            }
                        },
                        min: this.$store.getters.getMinDate,
                        max: this.$store.getters.getMaxDate
                    };
                }
                return scales;
            },
            downloadImage() {
                const image = this.chart.toBase64Image();
                var a = document.createElement('a');
                a.href = image;
                a.download = 'График.png';
                a.click();
            }
        },
        props: {
            datasets: Array
        },
        watch: {
            datasets(val) {
                if (val.length) {
                    const scales = this.getScales();
                    this.render(val, scales);
                }
            },
            ignoreDates() {
                const scales = this.getScales();
                this.render(this.datasets, scales);
            }
        }
    }
</script>

<style scoped>

</style>