import api from '../../../../utils/api';

export default new class Ajax {
    getShifts(processId) {
        return api.get(`/api/requests/delete-shift-report/shifts`, {
            params: {
                building_process_id: processId
            }
        }).then(({data}) => data);
    }

    getObjects() {
        return api.get(`/api/requests/delete-shift-report/building-objects`).then(({data}) => data);
    }

    createRequest(payload) {
        return api.post(`/api/requests/delete-shift-report`, payload).then(({data}) => data);
    }

    updateRequest(id, payload) {
        return api.put(`/api/requests/delete-shift-report/id/${id}`, payload).then(({data}) => data);
    }

    getRequests() {
        return api.get(`/api/requests/delete-shift-report`).then(({data}) => data);
    }

    getRequest(id) {
        return api.get(`/api/requests/delete-shift-report/id/${id}`).then(({data}) => data);
    }

    getLogs(id) {
        return api.get(`/api/requests/delete-shift-report/logs`, {
            params: {
                requestId: id
            }
        }).then(({data}) => data);
    }

    getComments(requestId) {
        return api.get(`/api/requests/delete-shift-report/comments/${requestId}`).then(({data}) => data);
    }

    addComment(payload) {
        return api.post(`/api/requests/delete-shift-report/comments`, payload).then(({data}) => data);
    }

    deleteComment({id, request}) {
        return api.delete(`/api/requests/delete-shift-report/comments/${id}`, {
            params: {
                requestId: request.id
            }
        }).then(({data}) => data);
    }

    updateComment(id, payload) {
        return api.put(`/api/requests/delete-shift-report/comments/${id}`, payload).then(({data}) => data);
    }

    reject(payload) {
        return api.post(`/api/requests/delete-shift-report/reject`, payload).then(({data}) => data);
    }

    approve(payload) {
        return api.post(`/api/requests/delete-shift-report/approve`, payload).then(({data}) => data);
    }

    sendToApprove(payload) {
        return api.post(`/api/requests/delete-shift-report/send-to-approve`, payload).then(({data}) => data);
    }

    editComment
}