<template>
    <div class="p-5 border border-success mt-5 w-50 mx-auto">
        <div>
            <b class="text-danger ">Оба поля обязательны к заполнению!</b>
        </div>
        <label for="contractName" class="mt-2">Номер договора</label>
        <b-form-input
                size="sm"
                v-model="contractName"
                placeholder="Укажите номер договора"
                id="contractName"
        ></b-form-input>
        <label for="contractDate" class="mt-2">Дата договора</label>
        <DatePicker v-model="contractDate"/>
    </div>
</template>

<script>
    import DatePicker from "../../../../components/DatePicker/DatePicker.vue";

    export default {
        components: {
            DatePicker
        },
        computed: {
            contractName: {
                get() {
                    return this.$store.state.wizard.contract.name;
                },
                set(val) {
                    this.$store.commit(`setWizardContractName`, val);
                }
            },
            contractDate: {
                get() {
                    return this.$store.state.wizard.contract.date;
                },
                set(val) {
                    this.$store.commit(`setWizardContractDate`, val);
                }
            },
        },
        mounted() {
            this.$emit(`can-continue`, {
                value: false
            });
        },
        watch: {
            contractName(val) {
                this.$emit(`can-continue`, {
                    value: val && this.contractDate
                });
            },
            contractDate(val) {
                this.$emit(`can-continue`, {
                    value: val && this.contractName
                });
            }
        },
        name: "StepEight"
    }
</script>

<style scoped>

</style>