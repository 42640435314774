<template>
    <div class="w-25 mx-auto p-3 border border-success mt-5">
        <div>
            <label for="selectedObject">Объект</label>
            <v-select
                    append-to-body
                    label="name"
                    :options="objects"
                    v-model="selectedObject">
            </v-select>
            <div class="d-flex justify-content-end mt-2" v-if="selectedObject">
                <b-button size="sm" variant="danger"
                          @click.prevent="onDelete()"
                          v-if="$can('delete', 'objects')">Удалить из базы
                </b-button>
            </div>
        </div>
        <b-form-checkbox
                class="mt-3"
                id="checkbox-1"
                v-model="formVisible"
                name="checkbox-1"
        >
            Нужного объекта нет в списке
        </b-form-checkbox>
        <div class="p-4 mt-5 border border-warning" v-if="formVisible">
            <label for="name">Имя объекта</label>
            <b-form-input
                    v-model="name"
                    placeholder="Дворец правосудия"
                    id="name"
                    size="sm"
                    :state="Boolean(name)"
            ></b-form-input>
            <label for="phone">Телефон</label>
            <b-form-input
                    v-model="phone"
                    placeholder="375291111111"
                    id="phone"
                    size="sm"
                    :state="Boolean(phone)"
            ></b-form-input>
            <label for="address">Адрес</label>
            <b-form-input
                    v-model="address"
                    placeholder="Одесса, ул.Симона Хереса 69"
                    id="address"
                    size="sm"
                    :state="Boolean(address)"
            ></b-form-input>
            <label for="address">Страна</label>
            <b-form-select
                    v-model="country"
                    id="country"
                    size="sm"
                    text-field="name"
                    value-field="id"
                    :options="countries"
                    :state="Boolean(country)"
            ></b-form-select>
            <div class="d-flex justify-content-end my-2">
                <b-button size="sm" variant="success" @click="createObject">Создать</b-button>
            </div>
        </div>
        <b-modal id="deleteObject_stepOne"
                 @ok="deleteObject"
                 ok-title="Ок"
                 ok-variant="success"
                 cancel-title="Отмена"
                 cancel-variant="danger"
        >
            <p class="my-4" v-if="selectedObject">
                Вы действительно хотите удалить объект <span class="font-weight-bold">{{ selectedObject.name }}</span>
                из системы?
            </p>
        </b-modal>
    </div>
</template>

<script>
    export default {
        computed: {
            objects() {
                return this.$store.state.objects;
            },
            selectedObject: {
                get() {
                    return this.$store.state.wizard.selectedObject;
                },
                set(val) {
                    this.$store.commit(`setWizardSelectedObject`, val);
                }
            }
        },
        data() {
            return {
                address: null,
                country: null,
                countries: [
                    {
                        id: `BY`,
                        name: `Беларусь`
                    },
                    {
                        id: `RU`,
                        name: `Россия`
                    }
                ],
                formVisible: true,
                name: null,
                phone: null
            }
        },
        mounted() {
            this.$store.dispatch(`getObjects`);
            this.$emit(`can-continue`, {
                value: Boolean(this.selectedObject)
            });
            this.countries = this.filteredCountries();
            this.country = this.countries[0].id;
        },
        methods: {
            filteredCountries() {
                const arr = [];
                if (this.$can(`create`, `objectBY`)) {
                    arr.push({
                        id: `BY`,
                        name: `Беларусь`
                    })
                }
                if (this.$can(`create`, `objectRU`)) {
                    arr.push({
                        id: `RU`,
                        name: `Россия`
                    })
                }
                return arr;
            },
            clearForm() {
                this.address = null;
                this.country = `BY`;
                this.formVisible = false;
                this.name = null;
                this.phone = null;
            },
            async createObject() {
                const {name, phone, address, country} = this;
                const data = {
                    name,
                    phone,
                    address,
                    country
                };
                await this.$store.dispatch(`createObject`, data).then((res) => {
                    this.$store.commit(`setWizardCreatedObject`, res.data);
                    this.$store.commit(`setWizardSelectedObject`, res.data);
                    this.formVisible = false;
                    this.$store.dispatch(`getObjects`);
                }).catch(err => {
                    this.$store.dispatch(`showErrorModal`, err.response.data);
                });
            },
            deleteObject() {
                this.$store.dispatch(`deleteObject`, this.selectedObject.building_id);
                this.$store.commit(`setWizardSelectedObject`, null);
                this.$store.dispatch(`getObjects`);
            },
            onDelete() {
                this.$bvModal.show(`deleteObject_stepOne`);
            }
        },
        name: "StepOne",
        watch: {
            selectedObject(val) {
                this.$emit(`can-continue`, {
                    value: Boolean(val)
                });
            }
        }
    }
</script>

<style scoped>

</style>