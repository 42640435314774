var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"twoStep"},[_c('div',{staticClass:"d-flex justify-content-start mt-3"},[_c('b-button',{staticClass:"mx-2 mt-2 mb-2",attrs:{"size":"sm"},on:{"click":_vm.goToRequestsPage}},[_vm._v(" К списку заявок ")])],1),_vm._l((Object.entries(_vm.availableObjectsFromShifts)),function(ref){
var name = ref[0];
var shifts_ = ref[1];
return _c('b-card',{key:name,staticClass:"m-0 fit",attrs:{"title":name}},[_c('b-form-input',{attrs:{"id":"search","size":"sm","placeholder":"Для поиска введите № отчёта, дату или фамилию..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('p',{staticClass:"text-danger font-weight-bold m-1"},[_vm._v("Можно выбрать несколько отчётов")]),_c('div',{staticClass:"table-parent"},[_c('b-table',{attrs:{"items":_vm.getFilteredShifts(shifts_),"small":"","striped":"","fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(check)",fn:function(data){return [_c('b-form-checkbox',{attrs:{"checked":_vm.isSelected(data.item)},on:{"change":function (v) { return _vm.onChecked(v, data.item); }}})]}},{key:"cell(shift_id)",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("https://t.me/c/" + (item.bp.common_chat_id.replace("-100", "")) + "/" + (item.telegram_message_id)),"target":"_blank"}},[_vm._v(" "+_vm._s(item.shift_id)+" ")])]}},{key:"cell(date_time_begin)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$moment(data.item.date_time_begin).format("DD.MM.YYYY (dd) HH:mm"))+" ")]}},{key:"cell(date_time_end)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$moment(data.item.date_time_end).format("DD.MM.YYYY (dd) HH:mm"))+" ")]}},{key:"cell(Author)",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("https://t.me/+" + (item.Author.phone)),"target":"_blank"}},[_vm._v(" "+_vm._s(item.Author.first_name)+" "+_vm._s(item.Author.last_name)+" ")])]}},{key:"cell(bp.MainStaff)",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("https://t.me/+" + (item.bp.MainStaff.phone)),"target":"_blank"}},[_vm._v(" "+_vm._s(item.bp.MainStaff.first_name)+" "+_vm._s(item.bp.MainStaff.last_name)+" ")])]}},{key:"cell(bp.Curator)",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("https://t.me/+" + (item.bp.Curator.phone)),"target":"_blank"}},[_vm._v(" "+_vm._s(item.bp.Curator.first_name)+" "+_vm._s(item.bp.Curator.last_name)+" ")])]}}],null,true)})],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }