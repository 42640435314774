<template>
    <div>
        <div class="d-flex justify-content-end my-2">
            <b-button
                v-b-modal.objectInProcessModal
                size="sm"
                variant="success"
                v-if="$can('create', 'objectsInProcess') && $store.getters.isAdmin"
            >Создать объект в работе
            </b-button>
        </div>
        <div class="d-flex my-2 justify-content-between">
            <b-input-group size="sm">
                <b-form-input
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Введите для поиска"
                ></b-form-input>
            </b-input-group>
            <b-col sm="7" md="2" class="my-1">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="fill"
                    size="sm"
                    class="my-0"
                ></b-pagination>
            </b-col>
            <b-col sm="5" md="3" class="my-1">
                <b-form-group
                    label="На странице"
                    label-cols-sm="6"
                    label-cols-md="4"
                    label-cols-lg="4"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="perPageSelect"
                    class="mb-0"
                >
                    <b-form-select
                        v-model="perPage"
                        id="perPageSelect"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select>
                </b-form-group>
            </b-col>
        </div>
        <b-table
            small
            striped
            :items="items"
            :fields="fields"
            sort-by="object_name"
            :current-page="currentPage"
            :per-page="perPage"
            @filtered="onFiltered"
            :filter="filter"
        >
            <template #cell(plus)="row">
                <i class="fas mx-2" :class="row.detailsShowing ? 'fa-minus' : 'fa-plus'" @click="row.toggleDetails"/>
            </template>
            <template v-slot:cell(staff_full_name)="data">
                <a :href="`https://t.me/+${data.item.staff_phone}`" target="_blank">
                    {{ data.item.staff_first_name }} {{ data.item.staff_last_name }}
                </a>
            </template>
            <template v-slot:cell(curator_full_name)="data">
                <a :href="`https://t.me/+${data.item.curator_phone}`" target="_blank">
                    {{ data.item.curator_first_name }} {{ data.item.curator_last_name }}
                </a>
            </template>
            <template v-slot:cell(contract_date)="data">
                <span v-if="data.item.contract_date">
                     {{ Date.parse(data.item.contract_date) | moment("DD.MM.YYYY") }}
                </span>
            </template>
            <template v-slot:cell(is_active)="data">
                <b-form-checkbox
                    :id="`isActive_${data.item.building_process_id}`"
                    v-model="data.item.is_active"
                    :name="`isActive_${data.item.building_process_id}`"
                    :disabled="!$can('update', 'objectsInProcess')"
                    @change="(value) => changeActivity(data.item, value)"
                    class="text-center"
                >
                </b-form-checkbox>
            </template>
            <template v-slot:cell(common_chat_id)="data">
                <span v-if="data.item.common_chat_id && data.item.common_chat_id !== `null`">
                     <a :href="`https://t.me/c/${data.item.common_chat_id.replace(`-100`, ``)}/999999999`"
                        target="_blank">
                            {{ data.item.common_chat_id }}
                     </a>
                </span>
            </template>
            <template v-slot:cell(need_notification)="data">
                <b-form-checkbox
                    :id="`need_notification${data.item.building_process_id}`"
                    v-model="data.item.need_notification"
                    :name="`need_notification${data.item.building_process_id}`"
                    :disabled="!$can('update', 'objectsInProcess')"
                    @change="(value) => changeNotification(data.item, value)"
                    class="text-center"
                >
                </b-form-checkbox>
            </template>
            <template v-slot:cell(actions)="data">
                <i class="fas fa-pen text-center text-success mx-2"
                   @click="onEdit(data)"
                   v-if="$can('update', 'objectsInProcess')"
                ></i>
                <i class="fas fa-trash-alt text-danger text-center mx-2"
                   @click="onDelete(data)"
                   v-if="$can('delete', 'objectsInProcess')"
                ></i>
            </template>
            <template #row-details="row">
                <detailTable :items="[row.item]"/>
            </template>
        </b-table>
        <div class="d-flex justify-content-end">
            <b-modal id="objectInProcessModal"
                     :title="isEdit ? `Редактирование объекта в работе` : `Создание объекта в работе`"
                     @ok="submit"
                     ok-title="Ок"
                     ok-variant="success"
                     cancel-title="Отмена"
                     cancel-variant="danger"
                     @hide="clearForm"
                     @show="beforeOpen"
            >
                <label for="selectedObject">Объект</label>
                <b-form-select
                    id="selectedObject"
                    v-model="selectedObject"
                    :options="objects"
                    size="sm"
                    value-field="building_id"
                    text-field="name"
                >
                    <template v-slot:first>
                        <b-form-select-option :value="null" disabled>-- Выберите строительный объект --
                        </b-form-select-option>
                    </template>
                </b-form-select>
                <label for="selectedStaff" class="mt-2">Прораб</label>
                <v-select
                    label="full_name"
                    value-field="user_id"
                    :value="users.find(el => el.user_id === selectedStaff)"
                    :options="users"
                    :clearable="false"
                    @input="v => selectedStaff = v ? v.user_id : null"
                >
                </v-select>
                <label for="selectedCurator" class="mt-2">Куратор</label>
                <v-select
                    label="full_name"
                    :value="users.find(el => el.user_id === selectedCurator)"
                    :options="users"
                    :clearable="false"
                    @input="v => selectedCurator = v ? v.user_id : null"
                >
                </v-select>
                <label for="folder" class="mt-2">Рабочая папка объекта</label>
                <b-form-input size="sm" v-model="folder" placeholder="Укажите рабочую папку" lazy-formatter
                              :formatter="folderFormatter"></b-form-input>
                <label for="reportTime" class="mt-2">Время подачи отчета</label>
                <b-form-timepicker id="reportTime" v-model="reportTime"></b-form-timepicker>
                <label for="reportTime" class="mt-2">ID группы объекта в Telegram</label>
                <b-form-input size="sm" v-model="chatId" placeholder="Укажите чат" type="number"></b-form-input>
                <label for="contractName" class="mt-2">Номер договора</label>
                <b-form-input
                    size="sm"
                    v-model="contractName"
                    placeholder="Укажите номер договора"
                    id="contractName"
                ></b-form-input>
                <label for="contractDate" class="mt-2">Дата договора</label>
                <DatePicker v-model="contractDate"/>
            </b-modal>
            <b-modal
                id="deleteObjectInProcess"
                @ok="deleteObjectInProcess"
                ok-title="Ок"
                ok-variant="success"
                cancel-title="Отмена"
                cancel-variant="danger">
                <p class="my-4" v-if="objectName">
                    Вы действительно хотите удалить процесс по объекту <span class="text-danger">{{ objectName }}</span>
                    из
                    системы?
                </p>
            </b-modal>
        </div>
    </div>
</template>

<script>
import detailTable from "./detailTable/detailTable.vue";
import DatePicker from "../../components/DatePicker/DatePicker.vue";

export default {
    components: {
        detailTable,
        DatePicker
    },
    computed: {
        items() {
            return this.$store.state.objectsInProcess;
        },
        objects() {
            return this.$store.state.objects;
        },
        users() {
            return this.$store.state.users;
        }
    },
    data() {
        return {
            chatId: null,
            fields: [
                {
                    key: `plus`,
                    label: ``
                },
                {
                    key: `object_name`,
                    label: `Имя объекта`
                },
                {
                    key: `staff_full_name`,
                    label: `Прораб`
                },
                {
                    key: `curator_full_name`,
                    label: `Куратор`
                },
                {
                    key: `contract_name`,
                    label: `Номер договора`
                },
                {
                    key: `contract_date`,
                    label: `Дата договора`
                },
                {
                    key: `object_country`,
                    label: `Страна`
                },
                {
                    key: `report_time`,
                    label: `Время отчета`
                },
                {
                    key: `common_chat_id`,
                    label: `Группа в Telegram`
                },
                {
                    key: `is_active`,
                    label: `Действующий`
                },
                {
                    key: `need_notification`,
                    label: `Напоминание о подаче отчета`
                },
                {
                    label: ``,
                    key: `actions`,
                    class: `w-80px`
                }
            ],
            folder: null,
            reportTime: null,
            selectedCurator: null,
            selectedObject: null,
            selectedStaff: null,
            isEdit: false,
            processId: null,
            objectName: null,
            contractName: null,
            contractDate: null,
            filter: null,
            perPage: 200,
            pageOptions: [5, 10, 15, 20, 100, 200],
            totalRows: 1,
            currentPage: 1,
            filterOn: [],
            originData: null
        }
    },
    methods: {
        clearForm() {
            this.chatId = null;
            this.folder = null;
            this.reportTime = null;
            this.selectedCurator = null;
            this.selectedObject = null;
            this.selectedStaff = null;
            this.isEdit = false;
            this.processId = null;
            this.contractName = null;
            this.contractDate = null;
        },
        changeActivity({building_process_id}, value) {
            this.$store.dispatch(`changeActivityObjectInProcess`, {building_process_id, value});
            if (!value) {
                this.changeNotification({building_process_id}, value);
                this.$store.dispatch(`deleteActiveReportByProcess`, building_process_id)
            } else {
                this.$store.dispatch(`createReportManagement`, building_process_id)
            }
            this.$store.dispatch(`restartNotifications`);
            this.$store.dispatch(`getObjectsInProcess`);
        },
        changeNotification({building_process_id}, value) {
            this.$store.dispatch(`changeNotificationObjectInProcess`, {building_process_id, value});
            this.$store.dispatch(`restartNotifications`);
            this.$store.dispatch(`getObjectsInProcess`);
        },
        deleteObjectInProcess() {
            const {processId} = this;
            this.$store.dispatch(`deleteObjectInProcess`, processId)
                .then(() => this.$store.dispatch(`deleteActiveReportByProcess`, processId))
                .then(() => this.$store.dispatch(`getObjectsInProcess`));
        },
        beforeOpen() {
            this.$store.dispatch(`getObjects`);
            this.$store.dispatch(`getUsers`);
        },
        onDelete(data) {
            this.processId = data.item.building_process_id;
            this.objectName = data.item.object_name;
            this.$bvModal.show(`deleteObjectInProcess`);
        },
        onEdit(data) {
            this.originData = data.item;
            this.isEdit = true;
            const {
                common_chat_id,
                folder_path,
                report_time,
                curator_id,
                building_id,
                main_staff_id,
                building_process_id,
                contract_name,
                contract_date
            } = data.item;
            this.chatId = common_chat_id;
            this.folder = folder_path;
            this.reportTime = report_time;
            this.selectedCurator = curator_id;
            this.selectedObject = building_id;
            this.selectedStaff = main_staff_id;
            this.processId = building_process_id;
            this.$bvModal.show(`objectInProcessModal`);
            this.contractName = contract_name;
            this.contractDate = contract_date;
        },
        async submit() {
            const {
                selectedObject,
                selectedCurator,
                selectedStaff,
                folder,
                reportTime,
                chatId,
                processId,
                contractName,
                contractDate,
                isEdit
            } = this;
            const payload = {
                building_id: selectedObject,
                curator_id: selectedCurator,
                main_staff_id: selectedStaff,
                folder_path: folder,
                report_time: reportTime,
                common_chat_id: chatId,
                building_process_id: processId,
                contract: {
                    name: contractName,
                    date: contractDate
                }
            }
            const action = isEdit
                ? `editObjectInProcess`
                : `createObjectsInProcess`;
            await this.$store.dispatch(action, payload)
                .then(res => {
                    this.$store.dispatch(`createReportManagement`, res.building_process_id || processId)
                    this.$store.dispatch(`restartNotifications`);
                    this.$store.dispatch(`getObjectsInProcess`);
                })
                .then(() => {
                    if (isEdit) {
                        return this.inviteUsersToTelegram(payload);
                    }
                })
                .catch(err => this.$store.dispatch(`showErrorModal`, err.response.data))
                .finally(() => this.clearForm());
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        folderFormatter(val) {
            let mutatedString = val.replace(/\\/g, "/");
            if (mutatedString.slice(-1) !== `/`) {
                mutatedString += `/`;
            }
            return mutatedString;
        },
        async inviteUsersToTelegram(payload) {
            let {originData: {common_chat_id, main_staff_id, curator_id, object_name}} = this;
            const participants = [];
            const staffChanged = main_staff_id !== payload.main_staff_id;
            const curatorChanged = curator_id !== payload.curator_id;
            if (staffChanged) {
                const user = this.users.find(el => el.user_id === payload.main_staff_id);
                user.isStaff = true;
                participants.push(user);
            }
            if (curatorChanged) {
                const user = this.users.find(el => el.user_id === payload.curator_id);
                user.isCurator = true;
                participants.push(user);
            }
            if (participants.length) {
                const payload = {participants};
                this.$store.dispatch(`addUsersToTelegram`, {id: common_chat_id, payload})
                    .then(({users}) => {
                        let msg = ``;
                        const curator = participants.find(el => el.isCurator);
                        const staff = participants.find(el => el.isStaff);
                        if (curator) {
                            if (users.includes(curator.phone)) {
                                msg += `Куратор успешно добавлен в Telegram группу <a href="https://t.me/c/${common_chat_id.replace(`-100`, ``)}/999999999" target="_blank">${object_name}</a>`
                            } else {
                                const {full_name} = curator;
                                msg += `Куратора <a href="https://t.me/+${curator.phone}" target="_blank">${full_name}</a> не удалось добавить из-за настроек конфиденциальности`
                            }
                        }
                        if (staff) {
                            if (users.includes(staff.phone)) {
                                msg += `<p>Прораб успешно добавлен в Telegram группу <a href="https://t.me/c/${common_chat_id.replace(`-100`, ``)}/999999999" target="_blank">${object_name}</a>`
                            } else {
                                const {full_name} = staff;
                                msg += `Прораба <a href="https://t.me/+${staff.phone}" target="_blank">${full_name}</a> не удалось добавить из-за настроек конфиденциальности`
                            }
                        }
                        const messageNodes = this.$createElement(
                            'div',
                            {
                                domProps: {innerHTML: msg}
                            }
                        )
                        this.$bvModal.msgBoxOk([messageNodes]);
                    })
                    .catch(err => {
                        let msg = err.response.data.msg;
                        if (participants.length) {
                            participants.forEach(el => {
                                const {full_name, phone} = el;
                                msg += `<p><a href="https://t.me/+${phone}" target="_blank">${full_name}</a>`
                            })
                        }
                        const messageNodes = this.$createElement(
                            'div',
                            {
                                domProps: {innerHTML: msg}
                            }
                        )
                        this.$bvModal.msgBoxOk([messageNodes], {
                            okVariant: 'danger',
                            title: `Ошибка редактирования группы Телеграм`
                        })
                    });
            }
            this.originData = null;
        }
    },
    mounted() {
        this.$store.dispatch(`getObjectsInProcess`);
        this.totalRows = this.items.length;
    },
    name: "ObjectsInProcess"
}
</script>

<style scoped>

</style>
