import api from './api';

export default new class Ajax {

    async addRoleToUser(userId, roleId) {
        return await api.post(`/api/user-has-roles`, {
            role_id: roleId,
            user_id: userId
        })
            .then(res => res.data)
            .catch(res => res)
    }

    async changeActivityObjectInProcess(id, value) {
        return await api.put(`/api/objects-in-process/change-activity/${id}`, {is_active: value});
    }

    async createMaterial(data) {
        return await api.post(`/api/materials`, data);
    }

    async createMaterialGroups(data) {
        return await api.post(`/api/material-groups`, data);
    }

    async createMaterialTargets(data) {
        return await api.post(`/api/material-targets`, data);
    }

    async changeNotificationObjectInProcess(id, value) {
        return await api.put(`/api/objects-in-process/change-notification/${id}`, {need_notification: value});
    }

    changeUserActive(id, payload) {
        return api.put(`/api/users/change-active/${id}`, payload).then(res => res.data);
    }

    async createObject(data) {
        return await api.post(`/api/objects`, data);
    }

    async createObjectsInProcess(data) {
        return api.post(`/api/objects-in-process`, data).then(res => res.data);
    }

    async createSystemUser(data) {
        return await api.post(`/api/system-users`, data);
    }

    createUserGroup(payload) {
        return api.post(`/api/user-groups`, payload).then(({data}) => data);
    }

    async createTarget(data) {
        return await api.post(`/api/targets`, data);
    }

    createTelegramGroup(payload) {
        return api.post(`/api/telegram/groups`, payload).then(({data}) => data);
    }

    addUsersToTelegram(id, payload) {
        return api.put(`/api/telegram/groups/${id}`, payload).then(({data}) => data);
    }

    async createWorkType(data) {
        return api.post(`/api/types-of-work`, data);
    }

    async createWorkGroup(data) {
        return api.post(`/api/work-groups`, data).then(res => res.data);
    }

    async createUser(data) {
        return api.post(`/api/users`, data);
    }

    async deleteMaterial(id) {
        return api.delete(`/api/materials/${id}`);
    }

    async deleteMaterialGroup(id) {
        return api.delete(`/api/material-groups/${id}`);
    }

    async deleteMaterialTarget(id) {
        return api.delete(`/api/material-targets/${id}`);
    }

    async deleteRolesByUser(id) {
        return api.delete(`/api/user-has-roles/user/${id}`);
    }

    async deleteObject(id) {
        return api.delete(`/api/objects/${id}`);
    }

    async deleteObjectInProcess(id) {
        return api.delete(`/api/objects-in-process/${id}`);
    }

    async deleteRolesByUserId(userId) {
        return api.delete(`/api/user-has-roles/user/${userId}`);
    }

    async deleteSystemUser(userId) {
        return await api.delete(`/api/system-users/${userId}`);
    }

    async deleteTarget(data) {
        const {building_process_id, work_id} = data;
        return api.delete(`/api/targets`, {
            params: {
                building_process_id,
                work_id
            }
        })
    }

    async deleteUser(userId) {
        return api.delete(`/api/users/${userId}`);
    }

    deleteUserGroup(id) {
        return api.delete(`/api/user-groups/${id}`);
    }

    async deleteWorkType(workId) {
        return api.delete(`/api/types-of-work/${workId}`);
    }

    async editMaterial(id, data) {
        return api.put(`/api/materials/${id}`, data);
    }

    async editMaterialGroup(id, data) {
        return api.put(`/api/material-groups/${id}`, data);
    }

    async editMaterialTarget(id, data) {
        return api.put(`/api/material-targets/${id}`, data);
    }

    async editObject(objectId, data) {
        return api.put(`/api/objects/${objectId}`, data);
    }

    async editObjectInProcess(processId, data) {
        return api.put(`/api/objects-in-process/${processId}`, data);
    }

    async editSystemUser(userId, data) {
        return await api.put(`/api/system-users/${userId}`, data);
    }

    async editTarget(data) {
        return api.put(`/api/targets`, data);
    }

    editUserGroup(id, payload) {
        return api.put(`/api/user-groups/${id}`, payload);
    }

    getUserGroups() {
        return api.get(`/api/user-groups`).then(({data}) => data);
    }

    async editWorkType(workId, data) {
        return api.put(`/api/types-of-work/${workId}`, data);
    }

    async editWorkGroup(id, data) {
        return api.put(`/api/work-groups/${id}`, data);
    }

    async getCompletedWorks() {
        return api.get(`/api/current-works`).then(res => res.data).catch(res => res);
    }

    async getCompletedWorksWithSecondWorks(archive) {
        return api.get(`/api/current-works/with-second-works`, {
            params: {
                archive
            }
        }).then(res => res.data).catch(res => res);
    }

    async getFuelLimits(archive) {
        return api.get(`/api/fuel-limits`, {
            params: {
                archive
            }
        }).then(res => res.data).catch(res => res);
    }

    async getImages(limit, skip, query_building) {
        return api.get(`/api/photos/report`, {
            params: {
                limit,
                skip,
                query_building
            }
        }).then(res => res.data).catch(res => res);
    }

    async getImagesForBuilding(id, limit, skip) {
        return api.get(`/api/photos/building/${id}`, {
            params: {
                limit,
                skip
            }
        }).then(res => res.data).catch(res => res);
    }

    async getImage(id) {
        return api.get(`/api/photos/${id}`, {responseType: 'blob'}).then(res => res.data).catch(res => res);
    }

    async getMiniImage(id) {
        return api.get(`/api/photos/mini/${id}`, {responseType: 'blob'}).then(res => res.data).catch(res => res);
    }

    async getMe() {
        return await api.get(`/auth/get-me`).then(res => {
            return res.data[0];
        })
    }

    async getRoles() {
        return await api.get(`/api/user-roles`).then(res => {
            return res.data;
        }).catch(res => {
            return res;
        })
    }

    async getSystemUsers() {
        return await api.get(`/api/system-users`).then(res => res.data);
    }

    async getSystemUser(userId) {
        return await api.get(`/api/system-users/${userId}`);
    }

    async getUsedFuels(archive) {
        return api.get(`/api/current-fuel`, {
            params: {
                archive
            }
        }).then(res => res.data);
    }

    async getUsers(full = false) {
        return api.get(`/api/users`, {
            params: {
                full
            }
        }).then(res => res.data);
    }

    async getObjects() {
        return api.get(`/api/objects`).then(res => {
            return res.data;
        }).catch(res => {
            return res;
        })
    }

    async getObjectsInProcess(archive = false) {
        return api.get(`/api/objects-in-process`, {
            params: {
                archive
            }
        }).then(res => {
            return res.data;
        }).catch(res => {
            return res;
        })
    }

    async getSecondWorks() {
        return api.get(`/api/works-notes`)
            .then(res => res.data)
            .catch(res => res);
    }

    async getTargets(archive) {
        return api.get(`/api/targets`, {
            params: {
                archive
            }
        }).then(res => res.data).catch(res => res);
    }

    async getMaterial(id) {
        return api.get(`/api/materials/${id}`)
            .then(res => res.data)
            .catch(res => res)
    }

    async getMaterials() {
        return api.get(`/api/materials`)
            .then(res => res.data)
            .catch(res => res)
    }

    async getMaterialGroups() {
        return api.get(`/api/material-groups`)
            .then(res => res.data)
            .catch(res => res)
    }

    async getMaterialGroup(id) {
        return api.get(`/api/material-groups/${id}`)
            .then(res => res.data)
            .catch(res => res)
    }

    async getMaterialTargets(archive) {
        return api.get(`/api/material-targets`, {
            params: {
                archive
            }
        })
            .then(res => res.data)
            .catch(res => res)
    }

    async getMaterialTarget(id) {
        return api.get(`/api/material-targets/id/${id}`)
            .then(res => res.data)
            .catch(res => res)
    }

    async getMaterialUsage(archive) {
        return api.get(`/api/current-materials`,
            {
                params: {
                    archive
                }
            })
            .then(res => res.data)
            .catch(res => res)
    }

    async getWorkShifts(archive) {
        return api.get(`/api/works-shifts`, {
            params: {
                withoutDateFormat: true,
                archive
            }
        }).then(res => res.data).catch(res => res);
    }

    async getWorkTypes() {
        return api.get(`/api/types-of-work`).then(res => res.data).catch(res => res);
    }

    async getWorkGroups() {
        return api.get(`/api/work-groups`).then(res => res.data).catch(res => res)
    }

    async login(payload) {
        return api.post(`/auth/login`, payload);
    }

    setHeaderAuth(token) {
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    async restartNotifications() {
        return api.get(`/api/report-time/restart`);
    }

    async updateUser(userId, user) {
        return api.put(`/api/users/${userId}`, user).then(res => res.data).catch(res => res);
    }

    async chartsGetMainData(start, end) {
        return api.get(`/api/charts`, {
            params: {
                start,
                end
            }
        })
            .then(res => res.data)
            .catch(res => res);
    }

    async chartsGetFilterOptions(url, start, end, building_id) {
        return api.get(`/api/charts/${url}`, {
            params: {
                start,
                end,
                building_id
            }
        })
            .then(res => res.data)
            .catch(res => res);
    }

    async chartsGetData(start, end, url, key, value, building_id) {
        return api.get(`/api/charts/${url}`, {
            params: {
                start,
                end,
                [key]: value,
                building_id
            }
        })
            .then(res => res.data)
            .catch(res => res);
    }

    getAvailableReportsManagement() {
        return api.get(`/api/reports-management/available-reports`).then(({data}) => data);
    }

    getActiveReports() {
        return api.get(`/api/reports-management/active-reports`).then(({data}) => data);
    }

    fillReportsTableByCurrentProcesses(reportId) {
        return api.get(`/api/reports-management/fill-table-by-current-active-processes/report-id/${reportId}`);
    }

    deleteActiveReport(id) {
        return api.delete(`/api/reports-management/active-reports/${id}`).then(({data}) => data);
    }

    deleteActiveReportByProcess(id) {
        return api.delete(`/api/reports-management/active-reports/building-process-id/${id}`).then(({data}) => data);
    }

    runReportsForcibly() {
        return api.get(`/api/reports-management/run-reports-forcibly`).then(({data}) => data)
    }

    createReportManagement(payload) {
        return api.post(`/api/reports-management/active-reports`, payload).then(({data}) => data);
    }

    getAvailableAlertsManagement() {
        return api.get(`/api/alerts-management/available-alerts`).then(({data}) => data);
    }

    getActiveAlerts() {
        return api.get(`/api/alerts-management/active-alerts`).then(({data}) => data);
    }

    fillAlertsTableByCurrentProcesses(alertId) {
        return api.get(`/api/alerts-management/fill-table-by-current-active-processes/alert-id/${alertId}`);
    }

    deleteActiveAlert(id) {
        return api.delete(`/api/alerts-management/active-alerts/${id}`).then(({data}) => data);
    }

    deleteActiveAlertByProcess(id) {
        return api.delete(`/api/alerts-management/active-alerts/building-process-id/${id}`).then(({data}) => data);
    }

    createAlertManagement(payload) {
        return api.post(`/api/reports-management/active-alerts`, payload).then(({data}) => data);
    }

    sendDatePicker(payload) {
        return api.post(`/telegram-datepicker`, payload).then(({data}) => data);
    }

    createTask(payload) {
        return api.post(`/api/tasks-management`, payload).then(({data}) => data);
    }

    updateTask(id, payload) {
        return api.put(`/api/tasks-management/${id}`, payload).then(({data}) => data);
    }

    getTasks() {
        return api.get(`/api/tasks-management`).then(({data}) => data);
    }

    getTaskById(id) {
        return api.get(`/api/tasks-management/${id}`).then(({data}) => data);
    }

    uploadTaskFile(payload, taskId) {
        return api.post(`/api/tasks-management/files`, payload, {
            params: {
                taskId
            }
        }).then(({data}) => data);
    }

    addComment(payload) {
        return api.post(`/api/tasks-management/comments`, payload).then(({data}) => data);
    }

    getComments(taskId) {
        return api.get(`/api/tasks-management/comments/${taskId}`).then(({data}) => data);
    }

    getEventMessages(taskId) {
        return api.get(`/api/tasks-management/event-messages/${taskId}`).then(({data}) => data);
    }

    getLogs(taskId) {
        return api.get(`/api/tasks-management/logs/${taskId}`).then(({data}) => data);
    }

    updateComment(id, payload) {
        return api.put(`/api/tasks-management/comments/${id}`, payload).then(({data}) => data);
    }

    deleteFile({id, taskId, commentId}) {
        return api.delete(`/api/tasks-management/files/${id}`, {
            params: {
                taskId,
                commentId
            }
        }).then(({data}) => data);
    }

    deleteComment({id, task}) {
        return api.delete(`/api/tasks-management/comments/${id}`, {
            params: {
                taskId: task.id
            }
        }).then(({data}) => data);
    }

    getShortUsers() {
        return api.get(`/api/users/short`).then(({data}) => data);
    }

}