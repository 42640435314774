<template>
    <div>
        <div class="d-flex justify-content-end my-1">
            <b-button size="sm" variant="danger" @click.prevent="abortWizard">Прервать создание объекта</b-button>
        </div>
        <horizontal-stepper
                :steps="demoSteps"
                @completed-step="completeStep"
                @active-step="isStepActive"
                @stepper-finished="createObject"
                :keep-alive="false"
                locale="ru"
        >
        </horizontal-stepper>
    </div>
</template>

<script>
    import HorizontalStepper from 'vue-stepper';
    import StepOne from "./Steps/1/StepOne.vue";
    import StepTwo from "./Steps/2/StepTwo.vue";
    import StepThree from "./Steps/3/StepThree.vue";
    import StepFour from "./Steps/4/StepFour.vue";
    import StepFive from "./Steps/5/StepFive.vue";
    import StepSix from "./Steps/6/StepSix.vue";
    import StepSeven from "./Steps/7/StepSeven.vue";
    import StepEight from "./Steps/8/StepEight.vue";

    export default {
        components: {
            HorizontalStepper
        },
        computed: {
            selectedObject() {
                return this.$store.state.wizard.selectedObject;
            },
            selectedStaff() {
                return this.$store.state.wizard.selectedStaff;
            },
            selectedCurator() {
                return this.$store.state.wizard.selectedCurator;
            },
            targets() {
                return this.$store.state.wizard.targets;
            },
            folder() {
                return this.$store.state.wizard.folder;
            },
            time() {
                return this.$store.state.wizard.time;
            },
            chatId() {
                return this.$store.state.wizard.chatId;
            },
            contract() {
                return this.$store.state.wizard.contract;
            }
        },
        data() {
            return {
                demoSteps: [
                    {
                        icon: 'work',
                        name: 'first',
                        title: 'Строительный объект',
                        subtitle: ``,
                        component: StepOne,
                        completed: false

                    },
                    {
                        icon: 'work',
                        name: 'second',
                        title: 'Прораб',
                        subtitle: '',
                        component: StepTwo,
                        completed: false
                    },
                    {
                        icon: 'work',
                        name: 'three',
                        title: 'Куратор',
                        subtitle: '',
                        component: StepThree,
                        completed: false
                    },
                    {
                        icon: 'work',
                        name: 'four',
                        title: 'Рабочая папка',
                        subtitle: '',
                        component: StepFour,
                        completed: false
                    },
                    {
                        icon: 'work',
                        name: 'five',
                        title: 'Время подачи отчета',
                        subtitle: '',
                        component: StepFive,
                        completed: false
                    },
                    {
                        icon: 'work',
                        name: 'six',
                        title: 'ID чата',
                        subtitle: '',
                        component: StepSix,
                        completed: false
                    },
                    {
                        icon: 'work',
                        name: 'seven',
                        title: 'Объем работ по договору',
                        subtitle: '',
                        component: StepSeven,
                        completed: false
                    },
                    {
                        icon: 'work',
                        name: 'eight',
                        title: 'Реквизиты договора',
                        subtitle: '',
                        component: StepEight,
                        completed: false
                    }
                ]
            }
        },
        methods: {
            // Executed when @completed-step event is triggered
            completeStep(payload) {
                this.demoSteps.forEach((step) => {
                    if (step.name === payload.name) {
                        step.completed = true;
                    }
                    if (step.name === `first`) {
                        step.subtitle = this.selectedObject?.name;
                    }

                    if (step.name === `second`) {
                        step.subtitle = this.selectedStaff?.full_name;
                    }

                    if (step.name === `three`) {
                        step.subtitle = this.selectedCurator?.full_name;
                    }

                    if (step.name === `four`) {
                        step.subtitle = this.folder;
                    }

                    if (step.name === `five`) {
                        step.subtitle = `${this.time.HH}:${this.time.mm}`;
                    }

                    if (step.name === `six`) {
                        step.subtitle = this.chatId;
                    }
                })
            },
            // Executed when @active-step event is triggered
            isStepActive(payload) {
                this.demoSteps.forEach((step) => {
                    if (step.name === payload.name) {
                        if (step.completed === true) {
                            step.completed = false;
                        }
                    }
                })
            },
            async createObject() {
                const data = {
                    building_id: this.selectedObject.building_id,
                    curator_id: this.selectedCurator.user_id,
                    main_staff_id: this.selectedStaff.user_id,
                    folder_path: this.folder,
                    report_time: `${this.time.HH}:${this.time.mm}`,
                    common_chat_id: this.chatId,
                    contract: this.contract
                }
                const process = await this.$store.dispatch(`createObjectsInProcess`, data).catch(() => this.abortWizard());

                if (process) {
                    await this.$store.dispatch(`createReportManagement`, process.building_process_id);
                    for (const reportId of [0, 1, 2]) {
                        await this.$store.dispatch(`fillAlertsTableByCurrentProcesses`, reportId);
                    }
                    await this.addTargets(process.building_process_id);
                    const object = this.$store.state.objects.find(el => el.building_id === process.building_id);
                    this.$store.commit(`setSelectedObject`, {
                        building_id: object.building_id,
                        building_process_id: process.building_process_id,
                        object_name: object.name
                    });
                    this.$store.dispatch(`restartNotifications`);
                    this.$store.dispatch(`clearWizard`);
                    this.$bvModal.show(`createMaterialTarget`);
                    this.$router.push(`/material-targets`);
                }
            },
            async addTargets(building_process_id) {
                for (const target of this.targets) {
                    const {name_project, value, work_id} = target
                    const data = {
                        building_process_id,
                        work_id,
                        work_name_project: name_project,
                        value
                    };
                    await this.$store.dispatch(`createTarget`, data)
                        .catch(err => this.$store.dispatch(`showErrorModal`, err.response.data));
                }
                return Promise.resolve();
            },
            abortWizard() {
                const {createdObject, createdStaff, createdCurator} = this.$store.state.wizard;
                if (createdObject) {
                    this.$store.dispatch(`deleteObject`, createdObject?.building_id);
                }
                if (createdStaff) {
                    this.$store.dispatch(`deleteUser`, createdStaff?.user_id);
                }
                if (createdCurator) {
                    this.$store.dispatch(`deleteUser`, createdCurator?.user_id);
                }

                this.$store.dispatch(`clearWizard`);
            }
        },
        name: "CreateObjectWizard"
    }
</script>

<style>
    .step-subtitle {
        font-size: 12px;
    }
    .step-title h4 {
        font-size: 1rem;
    }
</style>