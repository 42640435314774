import { render, staticRenderFns } from "./SystemUserForm.vue?vue&type=template&id=4f20e538&scoped=true&"
import script from "./SystemUserForm.vue?vue&type=script&lang=js&"
export * from "./SystemUserForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f20e538",
  null
  
)

export default component.exports