<template>
    <b-container fluid>
        <b-row>
            <b-form @submit.prevent="onSubmit" class="col-lg-4">
                <b-form-group
                        id="login"
                        label="Логин:"
                        label-for="login"
                >
                    <b-form-input
                            id="login"
                            v-model="form.login"
                            type="text"
                            required
                            placeholder="Введите логин"
                            autocomplete="off"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                        id="password"
                        label="Пароль:"
                        label-for="password"
                >
                    <b-form-input
                            id="password"
                            v-model="form.password"
                            type="password"
                            required
                            placeholder="Введите пароль"
                            autocomplete="off"
                    ></b-form-input>
                </b-form-group>
                <b-container fluid class="text-center">
                    <b-row cols="1">
                        <b-button type="submit" variant="primary">Войти по логину и паролю</b-button>
                        <div class="my-2">ИЛИ</div>
                        <vue-telegram-login
                                mode="callback"
                                telegram-login="ArgusVision_Bot"
                                @callback="yourCallbackFunction"/>
                    </b-row>
                </b-container>
            </b-form>
            <div>
                <img src="../assets/logo_ads_full.png" alt="ADS logo" class="logo">
                <img src="../assets/logo_argus_full.png" alt="Argus logo" class="logo">
            </div>
        </b-row>
    </b-container>
</template>

<script>
import {vueTelegramLogin} from 'vue-telegram-login'

export default {
    name: "Login",
    components: {
        vueTelegramLogin
    },
    data() {
        return {
            form: {
                login: '',
                password: ''
            }
        }
    },
    methods: {
        async onSubmit() {
            const payload = {
                login: this.form.login,
                password: this.form.password
            }
            this.login(payload);
        },
        yourCallbackFunction(tgUser) {
            this.login({tgUser});
        },
        login(payload) {
            this.$store.dispatch('login', payload).then(() => {
                this.$store.dispatch(`updateAbility`, this);
                this.$store.dispatch(`abortWizard`);
                this.$router.push('/');
            }).catch(err => {
                this.$store.dispatch(`showErrorModal`, err.response.data);
            });
        }
    }
}
</script>

<style scoped>
.logo {
    width: 300px;
    height: auto;
}

</style>