<template>
    <div>
        <b-avatar size="sm" class="mr-2"></b-avatar>
        <a :href="`https://t.me/+${value.phone}`" target="_blank">
            {{ value.first_name }} {{ value.last_name }}
        </a>
        <i class="fas fa-times text-danger ml-2" v-if="canRemove" @click="$emit(`remove`, value)"></i>
    </div>
</template>

<script>
export default {
    name: "UserCard",
    props: {
        value: Object,
        canRemove: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>

</style>