<template>
    <div>
        <label class="sm font-weight-bold" v-if="objects.length">Выбранные объекты</label>
        <b-tabs content-class="mt-3" :value="activeTab">
            <b-tab v-for="(selectedObject, i) in objects"
                   :title="selectedObject.name"
                   :key="i"
                   :active="i === activeTab"
            >
                <ObjectContent :object="selectedObject" :filters="filters" :dataId="dataId"/>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
    import ObjectContent from "./ObjectContent/ObjectContent.vue";

    export default {
        components: {
            ObjectContent
        },
        computed: {
            filters() {
                return this.$store.state.charts.mainData.data.find(el => el.id === this.dataId).filters;
            }
        },
        name: "ObjectTabs",
        props: {
            objects: Array,
            dataId: String,
            activeTab: Number
        },
    }
</script>

<style scoped>

</style>