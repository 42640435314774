import ajax from '../../utils/ajax';

export default {
    actions: {
        createTask(_, payload) {
            return ajax.createTask(payload);
        },
        updateTask(_, {id, payload}) {
            return ajax.updateTask(id, payload);
        },
        getTasks() {
            return ajax.getTasks();
        },
        getTaskById(_, id) {
            return ajax.getTaskById(id);
        },
        uploadTaskFile(_, data) {
            const {payload, taskId} = data;
            return ajax.uploadTaskFile(payload, taskId);

        },
        addComment(_, payload) {
            return ajax.addComment(payload);
        },
        getComments(_, taskId) {
            return ajax.getComments(taskId);
        },
        editComment(_, {id, payload}) {
            return ajax.updateComment(id, payload);
        },
        deleteComment(_, payload) {
            return ajax.deleteComment(payload);
        },
        deleteFile(_, payload) {
            return ajax.deleteFile(payload);
        },
        getShortUsers() {
            return ajax.getShortUsers();
        },
        getEventMessages(_, taskId) {
            return ajax.getEventMessages(taskId);
        },
        getTaskLogs(_, taskId) {
            return ajax.getLogs(taskId);
        }
    },
    state: {
        availableStatuses: [
            {
                id: 0,
                name: `В работе`
            },
            {
                id: 1,
                name: `Закрыта`
            }
        ],
        shortUsers: [],
        selectedTab: 0,
        filter: ``,
        sortOptions: [
            {
                text: `Id задачи`,
                value: `id`
            },
            {
                text: `Название задачи`,
                value: `name`
            },
            {
                text: `Название объекта`,
                value: `objectName`
            },
            {
                text: `Постановщик`,
                value: `author`
            },
            {
                text: `Ответственный`,
                value: `responsible_person`
            },
            {
                text: `Дата создания`,
                value: `createdAt`
            },
            {
                text: `Дата окончания (повтора)`,
                value: `endDate`
            },
            {
                text: `Статус задачи`,
                value: `status`
            }
        ],
        sortBy: `endDate`,
        sortDesc: false
    },
    mutations: {
        setShortUsers(state, value) {
            state.shortUsers = value;
        },
        tasksSetSelectedTab(state, value) {
            state.selectedTab = value;
        },
        setFilter(state, value) {
            state.filter = value;
        },
        tasksSetSortBy(state, value) {
            state.sortBy = value;
        },
        tasksSetSortDesc(state, value) {
            state.sortDesc = value;
        }
    }
}