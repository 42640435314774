<template>
    <b-modal id="error-modal"
             header-bg-variant="danger"
             header-text-variant="light"
             footer-bg-variant="light"
             footer-text-variant="dark"
             v-model="show"
    >
        <p class="my-4">{{ text }}</p>
    </b-modal>
</template>

<script>
    export default {
        computed: {
            show: {
                get() {
                    return this.$store.state.showErrorModal;
                },
                set() {
                    this.$store.dispatch(`hideErrorModal`);
                }
            },
            text() {
                return this.$store.state.errorModalText;
            }
        },
        name: "ErrorModal"
    }
</script>

<style scoped>

</style>