<template>
    <b-card class="user-group mx-3 position-relative" :header="item.name" header-class="p-2" body-class="p-2">
        <template #header>
            <div class="d-flex justify-content-between">
                <span>{{ item.name }}<i class="fas fa-pencil-alt mx-2" @click="onChangeGroupName"></i></span>
                <i class="fas fa-trash-alt text-danger" @click="$emit('groupDelete')"></i>
            </div>
        </template>
        <b-card header="Участники" body-class="current-users p-2" header-class="p-1">
            <div v-for="user in item.users" :key="user.id" class="d-flex justify-content-between">
                <span>{{ user.first_name }} {{ user.last_name }}</span>
                <div>
                    <i :class="`fas fa-user-nurse mx-3 ${user.ChatGroups.IsAdmin ? 'text-success' : 'text-danger'}`"
                       title="администратор"
                       @click="() => onChangeAdminClick(user)"
                    ></i>
                    <i class="fas fa-user-minus" @click="$emit(`removeUser`, user.user_id)"></i>
                </div>
            </div>
        </b-card>
        <b-card header="Общий список" class="my-2" body-class="p-2" header-class="p-1">
            <b-form-input size="sm" class="mb-2" v-model="search"></b-form-input>
            <div class="available-users">
                <div v-for="item in availableUsers" :key="item.user_id" class="d-flex justify-content-between">
                    <span>{{ item.first_name }} {{ item.last_name }}</span>
                    <i class="fas fa-user-plus" @click="$emit(`addUser`, item.user_id)"></i>
                </div>
            </div>
        </b-card>
    </b-card>
</template>

<script>
export default {
    name: "GroupCard",
    data() {
        return {
            search: ``
        }
    },
    computed: {
        allUsers() {
            return this.$store.state.users.filter(el => !el.isBot && el.active);
        },
        availableUsers() {
            const users = this.item.users.length
                ? this.allUsers.filter(el => this.item.users.some(it => it.user_id !== el.user_id))
                : this.allUsers;
            const searchValue = this.search.trim().toLowerCase();
            if (searchValue.length) {
                return users.filter(el => el.first_name.toLowerCase().includes(searchValue)
                    || el.last_name.toLowerCase().includes(searchValue)
                )
            } else {
                return users;
            }
        }
    },
    props: {
        item: {
            type: Object
        }
    },
    methods: {
        onChangeGroupName() {
            const name = prompt(`Введите название группы`);
            this.$emit(`changeName`, name);
        },
        onChangeAdminClick(user) {
            user.ChatGroups.IsAdmin = Boolean(!user.ChatGroups.IsAdmin);
            this.$emit(`editAdmin`, user);
        }
    }
}
</script>

<style scoped>
.current-users {
    height: 250px;
    overflow: auto;
}

.available-users {
    overflow: auto;
    height: 250px;
}

.fa-user-plus:hover {
    color: #28a745
}

.fa-user-minus:hover {
    color: #dc3545;
}
</style>