<template>
    <b-container>
        <b-row>
            <b-col>
                <div class="d-flex justify-content-between">
                    <b-form-select v-model="selectedForFill" :options="availableAlerts" class="w-50"></b-form-select>
                    <b-button size="sm" variant="success" class="mx-2" @click.once="onFillReportTableClick">
                        Добавить выбранное уведомление по всем активным стройкам
                    </b-button>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group label="Отображать:" v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                        id="availableAlerts"
                        v-model="selected"
                        :options="availableAlerts"
                        name="availableAlerts"
                    ></b-form-checkbox-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-table small :items="items" :fields="fields">
                <template #cell(alertId)="row">
                    {{ getReportName(row.item.alertId) }}
                </template>
                <template #cell(createdAt)="row">
                    {{ Date.parse(row.item.createdAt) | moment("DD.MM.YYYY dd HH:mm:ss") }}
                </template>
                <template #cell(updatedAt)="row">
                    {{ Date.parse(row.item.createdAt) | moment("DD.MM.YYYY dd HH:mm:ss") }}
                </template>
                <template #cell(actions)="row">
                    <i class="fas fa-trash-alt" @click="onDeleteClick(row.item)"></i>
                </template>
            </b-table>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: "AlertsManagement",
    computed: {
        items() {
            return this.activeAlerts.filter(el => this.selected.includes(el.alertId));
        }
    },
    data() {
        return {
            selected: [],
            selectedForFill: 0,
            availableAlerts: [],
            activeAlerts: [],
            fields: [
                {
                    key: `alertId`,
                    label: `Имя уведомления`
                },
                {
                    key: `buildingName`,
                    label: `Имя объекта`,
                    sortable: true
                },
                {
                    key: `createdAt`,
                    label: `Отчет добавлен`,
                    sortable: true
                },
                {
                    key: `updatedAt`,
                    label: `Отчет изменен`,
                    sortable: true
                },
                {
                    key: `actions`,
                    label: ``
                }
            ]
        }
    },
    mounted() {
        this.$store.dispatch(`getAvailableAlertsManagement`)
            .then(reports => this.availableAlerts = reports.map(({id, name}) => ({text: name, value: id})))
            .then(() => this.availableAlerts.forEach(el => this.selected.push(el.value)));
        this.getActiveAlerts();
    },
    methods: {
        getReportName(id) {
            return this.availableAlerts.find(el => el.value === id)?.text;
        },
        onFillReportTableClick() {
            const alertId = this.selectedForFill;
            this.$store.dispatch(`fillAlertsTableByCurrentProcesses`, alertId).then(this.getActiveAlerts);
        },
        getActiveAlerts() {
            this.$store.dispatch(`getActiveAlerts`).then(reports => this.activeAlerts = reports);
        },
        onDeleteClick({id}) {
            this.$bvModal.msgBoxConfirm(`Вы действительно хотите отключить отчет ${id}?`)
                .then(confirm => {
                    if (confirm) {
                        this.$store.dispatch(`deleteActiveAlert`, id).then(this.getActiveAlerts)
                    }
                })
        }
    }
}
</script>

<style scoped>
.fa-trash-alt:hover {
    color: red
}
</style>