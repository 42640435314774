import ajax from '../../utils/ajax';

export default {
    actions: {
        async chartsGetMainData({state}) {
            return await ajax.chartsGetMainData(state.selectedDate.start, state.selectedDate.end);
        },
        async getFilterOptions({state}, {url, building_id}) {
            return await ajax.chartsGetFilterOptions(url, state.selectedDate.start, state.selectedDate.end, building_id);
        },
        async chartsGetData({state}, {url, key, value, building_id}) {
            return await ajax.chartsGetData(state.selectedDate.start, state.selectedDate.end, url, key, value, building_id);
        },
        async addChart({commit, state}, {chartData, chartId, chartLabel, dataId, objectId}) {
            const chart = {
                id: chartId,
                data: chartData,
                label: chartLabel,
                backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                fill: false,
                dataId,
                objectId
            }
            const newDataSet = state.dataSets;
            newDataSet.push(chart);
            commit(`setDataSets`, newDataSet);
        },
        deleteChart({state, commit}, {key, value}) {
            commit(`setDataSets`, state.dataSets.filter(el => el[key] !== value));
        }
    },
    getters: {
        getDataLabels(state) {
            if (!state.selectedDate || !state.selectedDate.start || !state.selectedDate.end) {
                return [];
            }
            const {start, end} = state.selectedDate;

            return getDates(new Date(start), new Date(end));
        },
        getMinDate(state) {
            const today = new Date;
            let minDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
            state.dataSets.forEach(dataset => {
                const filtered = dataset.data.filter(el => el.y !== null);
                if (filtered.length && filtered[0].x && !compareDate(filtered[0].x, minDate)) {
                    minDate = filtered[0].x;
                }
            })
            return minDate;
        },
        getMaxDate(state) {
            const dates = [];
            state.dataSets.forEach(dataset => {
                const filtered = dataset.data.filter(el => el.y !== null);
                if (filtered.length) {
                    dates.push(filtered.reverse()[0].x);
                }
            })
            const today = new Date;
            return dates.length
                ? dates.sort((a, b) =>  new Date(a) - new Date(b)).reverse()[0]
                : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
        }
    },
    state: {
        mainData: [],
        selectedDate: ``,
        dataSets: [],
        showChartsSpinner: false,
        dataSelected: [],
        ignoreDates: false
    },
    mutations: {
        setChartsSelectedDate(state, value) {
            state.selectedDate = value;
        },
        setDataSets(state, value) {
            state.dataSets = value;
        },
        setDataSelected(state, value) {
            state.dataSelected = value;
        },
        setMainData(state, value) {
            state.mainData = value;
        },
        setShowChartsSpinner(state, value) {
            state.showChartsSpinner = value;
        },
        setIgnoreDates(state, value) {
            state.ignoreDates = value;
        }
    }
}

function getDates(startDate, endDate) {
    const dates = []
    let currentDate = startDate
    const addDays = function (days) {
        const date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)
        return date
    }
    while (currentDate <= endDate) {
        dates.push(currentDate)
        currentDate = addDays.call(currentDate, 1)
    }
    return dates
}

function compareDate(a, b) {
    const dateA = new Date(a);
    const dateB = new Date(b);
    dateA.setHours(0, 0, 0, 0);
    dateB.setHours(0, 0, 0, 0);
    return dateA > dateB
}