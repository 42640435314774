<template>
    <div>
        <b-modal id="editMaterialGroup"
                 title="Редактирование группы материала"
                 @ok="submit"
                 @hide="clearForm"
                 ok-title="Ок"
                 ok-variant="success"
                 cancel-title="Отмена"
                 cancel-variant="danger"
                 no-close-on-backdrop
                 @show="beforeOpen"
        >
            <label for="name">Имя группы материала</label>
            <b-form-input
                    v-model="name"
                    placeholder="Смеси сыпучие"
                    id="name"
                    size="sm"
            ></b-form-input>
        </b-modal>
    </div>
</template>

<script>
    export default {
        computed: {
            selectedMaterialGroupId() {
                return this.$store.state.materials.selectedMaterialGroupId;
            }
        },
        data() {
            return {
                name: null
            }
        },
        methods: {
            clearForm() {
                this.name = null;
            },
            async beforeOpen() {
                const group = await this.$store.dispatch(`getMaterialGroup`, this.selectedMaterialGroupId);
                this.setMaterial(group);
            },
            setMaterial({name}) {
                this.name = name;
            },
            submit() {
                const data = {
                    name: this.name
                }
                this.$store.dispatch(`editMaterialGroup`, data)
                    .then(() => this.$store.dispatch(`getMaterialGroups`));
            }
        },
        name: "EditMaterialGroup"
    }
</script>

<style scoped>

</style>