<template>
    <div class="w-25 mx-auto p-5 border border-success mt-5">
        <label for="reportTime" class="mt-2">Время подачи отчета</label>
        <div>
            <vue-timepicker
                    manual-input
                    :format="format"
                    v-model="time"
                    hide-dropdown
            ></vue-timepicker>
        </div>
    </div>
</template>

<script>
    import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
    export default {
        components: {
            VueTimepicker
        },
        computed: {
          time: {
              get() {
                  return this.$store.state.wizard.time;
              },
              set(val) {
                  this.$store.commit(`setWizardTime`, val);
              }
          }
        },
        data () {
            return {
                format: 'HH:mm'
            }
        },
        mounted() {
            this.$emit(`can-continue`, {
                value: true
            });
        },
        name: "StepFive"
    }
</script>

<style scoped>
</style>