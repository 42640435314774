<template>
    <div class="p-2">
        <div class="d-flex justify-content-end my-2">
            <b-button
                    @click="$router.push(`/systemUserForm`)"
                    size="sm"
                    variant="success"
                    v-if="$can('create', 'systemUsers')"
            >Добавить пользователя системы
            </b-button>
        </div>
        <div class="d-flex my-2 justify-content-between">
            <b-input-group size="sm">
                <b-form-input
                        v-model="filter"
                        type="search"
                        id="filterInput"
                        placeholder="Введите для поиска"
                ></b-form-input>
            </b-input-group>
            <b-col sm="7" md="2" class="my-1">
                <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                ></b-pagination>
            </b-col>
            <b-col sm="5" md="3" class="my-1">
                <b-form-group
                        label="На странице"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="4"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="perPageSelect"
                        class="mb-0"
                >
                    <b-form-select
                            v-model="perPage"
                            id="perPageSelect"
                            size="sm"
                            :options="pageOptions"
                    ></b-form-select>
                </b-form-group>
            </b-col>
        </div>
        <b-table
                :fields="fields"
                :items="items"
                striped
                small
                :current-page="currentPage"
                :per-page="perPage"
                @filtered="onFiltered"
                :filter="filter"
        >
            <template v-slot:cell(actions)="data">
                <div class="d-flex justify-content-around">
                    <i class="fas fa-pen text-center text-success mx-2"
                       v-if="$can('update', 'systemUsers')"
                       @click="$router.push(`/systemUserForm?userId=${data.item.userId}`)"
                    ></i>
                    <i class="fas fa-trash-alt text-danger text-center"
                       v-if="$can('delete', 'systemUsers')"
                       @click="basketClick(data.item)"></i>
                </div>
            </template>
        </b-table>
        <b-modal id="deleteSystemUser"
                 @ok="deleteUser"
                 ok-title="Ок"
                 ok-variant="success"
                 cancel-title="Отмена"
                 cancel-variant="danger"
        >
            <p class="my-4" v-if="user">
                Вы действительно хотите удалить пользователя <span class="text-danger">{{ user.login }}</span> из
                системы?
            </p>
        </b-modal>
    </div>
</template>

<script>
    export default {
        computed: {
            items() {
                return this.$store.state.systemUsers;
            }
        },
        data() {
            return {
                fields: [
                    {
                        key: `firstName`,
                        label: `Имя`
                    },
                    {
                        key: `lastName`,
                        label: `Фамилия`
                    },
                    {
                        key: `login`,
                        label: `Логин`
                    },
                    {
                        key: `phone`,
                        label: `Телефон`
                    },
                    {
                        key: `roles`,
                        label: `Роли`
                    },
                    {
                        key: `actions`,
                        label: ``,
                        thClass: `w-30px`
                    }
                ],
                user: null,
                filter: null,
                perPage: 200,
                pageOptions: [5, 10, 15, 20, 100, 200],
                totalRows: 1,
                currentPage: 1,
                filterOn: []
            }
        },
        name: "SystemUsers",
        methods: {
            basketClick(item) {
                this.user = item;
                this.$bvModal.show(`deleteSystemUser`);
            },
            async deleteUser() {
                await this.$store.dispatch(`deleteSystemUser`, this.user);
                this.$store.dispatch(`getSystemUsers`);
            },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length
                this.currentPage = 1
            }
        },
        mounted() {
            this.$store.dispatch(`getSystemUsers`);
            this.totalRows = this.items.length;
        }
    }
</script>

<style>
    .w-30px {
        width: 30px;
    }

</style>