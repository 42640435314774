<template>
    <div>
        <LineChart v-if="dataSets.length" :datasets="dataSets"/>
    </div>
</template>

<script>
    import LineChart from "../LineChart/LineChart.vue";
    export default {
        components: {
            LineChart
        },
        computed: {
            dataSets() {
                return this.$store.state.charts.dataSets;
            }
        },
        name: "ChartContainer"
    }
</script>

<style scoped>

</style>