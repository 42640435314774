<template>
    <div>
        <div class="pb-2">
            <b-form-input
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Поиск по имени объекта"
                    autocomplete="off"
                    debounce="1000"
            ></b-form-input>
        </div>
        <div role="tablist" class="objects_container">
            <div v-for="(object, $index) in objects" :key="$index" class="m-2">
                <b-button variant="info" class="w-100" @click="onToggleClick($index)" role="tab">
                    {{object.building.name}}
                </b-button>
                <b-collapse :id="`collapse_${object.building.id}`"
                            :visible="visible.includes($index)"
                            accordion="my-accordion"
                            role="tabpanel"
                >
                    <object-images :data="object" v-if="visible.includes($index)"/>
                </b-collapse>
            </div>
            <infinite-loading @infinite="getObjects"/>
        </div>
    </div>
</template>

<script>
    import 'lightgallery.js'
    import 'lightgallery.js/dist/css/lightgallery.css'
    import ObjectImages from "./ObjectImages/ObjectImages";
    import InfiniteLoading from 'vue-infinite-loading';
    import searchText from "../../utils/searchText";

    export default {
        name: "Gallery",
        data() {
            return {
                index: null,
                limit: 20,
                page: 1,
                records: 0,
                objects: [],
                filter: null,
                visible: [0]
            };
        },
        components: {
            ObjectImages,
            InfiniteLoading
        },
        methods: {
            async getImage(id) {
                const response = await this.$store.dispatch(`getImage`, id);
                if (response.isAxiosError) {
                    return;
                }
                return URL.createObjectURL(response);
            },
            async getObjects($state) {
                const {limit, page, filter} = this;
                const data = await this.$store.dispatch(`getImages`, {
                    skip: limit * (page - 1),
                    limit,
                    query_building: filter
                });
                if (data.items.length) {
                    this.page += 1;
                    data.items.forEach(el => {
                        this.objects.push(el);
                    })
                    if ($state) {
                        $state.loaded();
                    }
                } else {
                    if ($state) {
                        $state.complete();
                    }
                }
            },
            searchText,
            onToggleClick(index) {
                if (this.visible.includes(index)) {
                    this.visible = this.visible.filter(el => el !== index);
                } else {
                    this.visible.push(index);
                }
            }
        },
        watch: {
            filter() {
                this.objects = [];
                this.page = 1;
                this.getObjects();
            }
        }
    }
</script>

<style scoped>

    .objects_container {
        overflow-y: auto;
        height: 85vh;
    }

    .objects_container::-webkit-scrollbar {
        display: block;
        width: 20px;
    }

    .objects_container::-webkit-scrollbar-track {
        display: block;
        background: white;
        width: 20px;
    }

    .objects_container::-webkit-scrollbar-thumb {
        display: block;
        background-color: #17a2b8;
        border-radius: 20px;
        width: 20px;
    }

</style>