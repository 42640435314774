<template>
    <div class="d-flex">
        <b-card class="user-group new-group">
            <b-form-input size="sm" placeholder="Название новой группы" v-model="groupName"/>
            <i class="fas fa-plus" @click="onCreateClick"></i>
        </b-card>
        <GroupCard v-for="group in groups" :key="group.id"
                   :item="group"
                   @changeName="name => onChangeGroupName(group.id, name)"
                   @groupDelete="() => onDeleteGroupClick(group.id)"
                   @addUser="id => onAddUserClick(id, group.id)"
                   @removeUser="id => onRemoveUserClick(id, group.id)"
                   @editAdmin="user => onAdminEdit(user, group.id)"
        />
    </div>
</template>

<script>
import GroupCard from "@/views/UserGroups/GroupCard/GroupCard";

export default {
    name: "UserGroups",
    components: {
        GroupCard
    },
    data() {
        return {
            groupName: null,
            groups: []
        }
    },
    methods: {
        onCreateClick() {
            let {groupName, $store, getGroups} = this;
            const name = groupName || prompt(`Введите название группы`);
            if (!name) {
                return;
            }
            const payload = {
                name
            }
            $store.dispatch(`createUserGroup`, payload).then(getGroups);
        },
        getGroups() {
            this.$store.dispatch(`getUserGroups`).then(groups => this.groups = groups);
        },
        onDeleteGroupClick(id) {
            this.$bvModal.msgBoxConfirm(`Вы уверены что хотите удалить группу?`)
                .then(confirm => {
                    if (confirm) {
                        return this.$store.dispatch(`deleteUserGroup`, id);
                    }
                })
                .then(() => this.getGroups());
        },
        onAddUserClick(userId, groupId) {
            const payload = {
                users: [{user_id: userId, action: `create`}]
            };
            this.$store.dispatch(`editUserGroup`, {groupId, payload})
                .then(() => this.getGroups());
        },
        onAdminEdit(user, groupId) {
            const payload = {
                users: [{user_id: user.user_id, isAdmin: user.ChatGroups.IsAdmin, action: `edit`}]
            };
            this.$store.dispatch(`editUserGroup`, {groupId, payload})
                .then(() => this.getGroups());
        },
        onRemoveUserClick(userId, groupId) {
            const payload = {
                users: [{user_id: userId, action: `remove`}]
            };
            this.$store.dispatch(`editUserGroup`, {groupId, payload})
                .then(() => this.getGroups());
        },
        onChangeGroupName(groupId, name) {
            const payload = {
               name
            };
            this.$store.dispatch(`editUserGroup`, {groupId, payload})
                .then(() => this.getGroups());
        }
    },
    mounted() {
        this.$store.dispatch(`getUsers`);
        this.getGroups();
    }
}
</script>

<style scoped lang="scss">
.user-group {
    width: 300px;
}

.new-group {
    .fa-plus {
        font-size: 60px;
        margin-top: calc(50% - 60px);
    }

    .fa-plus:hover {
        font-size: 80px;
        margin-top: calc(50% - 70px);
    }

    text-align: center;
    height: 300px;
}
</style>