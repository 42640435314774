<template>
    <div class="w-75 mx-auto p-1 mt-2">
        <SuperChat @chatCreated="onChatCreated"/>
        <div><h4 class="text-success">Или</h4></div>
        <label for="reportTime">ID группы объекта в Telegram</label>
        <b-form-input size="sm" v-model="chatId" placeholder="Укажите чат" type="number"></b-form-input>
    </div>
</template>

<script>
    import SuperChat from "@/components/SuperChat/SuperChat";
    const errorText = `Группа создана, но пользователей из списка ниже (обозначенных красным крестиком) не удалось добавить так как их настройки конфиденциальности не позволяют это сделать.
                  Данным пользователям необходимо изменить настройки своего Telegram аккаунта перейдя в "Настройки - Конфиденциальность - Группы и каналы - Кто может добавлять меня - установить Все".
                  После этих действий администратор группы сможет добавить их самостоятельно.`;
    const successText = `Группа успешно создана.`
    export default {
        components: {
            SuperChat
        },
        computed: {
            chatId: {
                get() {
                    return this.$store.state.wizard.chatId;
                },
                set(val) {
                    this.$store.commit(`setWizardChatId`, val);
                }
            }
        },
        methods: {
            onChatCreated({chatId, hasNotAddedUsers}) {
                this.showToast(hasNotAddedUsers);
                this.$store.commit(`setWizardChatId`, chatId);
                this.$emit(`can-continue`, {
                    value: true
                });
            },
            showToast(hasNotAddedUsers) {
                this.$bvToast.toast(hasNotAddedUsers ? errorText : successText, {
                    title: ``,
                    variant: hasNotAddedUsers ? `danger` : `success`,
                    solid: true,
                    noAutoHide: true
                })
            }
        },
        mounted() {
            this.$emit(`can-continue`, {
                value: false
            });
        },
        watch: {
            chatId(val) {
                this.$emit(`can-continue`, {
                    value: Boolean(val)
                });
            }
        },
        name: "StepSix"
    }
</script>

<style scoped>

</style>