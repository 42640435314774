<template>
    <div>
        <div class="mx-auto my-2 w-25">
            <h3>Виды работ</h3>
        </div>
        <div class="d-flex justify-content-end my-2">
            <b-button
                    v-b-modal.typeWorkModal
                    size="sm"
                    variant="success"
                    v-if="$can('create', 'workTypes')"
            >Создать тип работы
            </b-button>
        </div>
        <div class="d-flex my-2 justify-content-between">
            <b-input-group size="sm">
                <b-form-input
                        v-model="filter"
                        type="search"
                        id="filterInput"
                        placeholder="Введите для поиска"
                ></b-form-input>
            </b-input-group>
            <b-col sm="7" md="2" class="my-1">
                <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                ></b-pagination>
            </b-col>
            <b-col sm="5" md="3" class="my-1">
                <b-form-group
                        label="На странице"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="4"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="perPageSelect"
                        class="mb-0"
                >
                    <b-form-select
                            v-model="perPage"
                            id="perPageSelect"
                            size="sm"
                            :options="pageOptions"
                    ></b-form-select>
                </b-form-group>
            </b-col>
        </div>
        <b-table
                :fields="fields"
                striped
                small
                :items="workTypes"
                :current-page="currentPage"
                :per-page="perPage"
                @filtered="onFiltered"
                :filter="filter"
        >
            <template v-slot:cell(actions)="data">
                <i class="fas fa-pen text-center text-success mx-2"
                   @click="onEdit(data)"
                   v-if="$can('update', 'workTypes')"
                ></i>
                <i class="fas fa-trash-alt text-danger text-center mx-2"
                   @click="onDelete(data)"
                   v-if="$can('delete', 'workTypes')"
                ></i>
            </template>
        </b-table>
        <div class="d-flex justify-content-end">
            <b-modal id="typeWorkModal"
                     :title="isEdit ? `Редактирование работы` : `Создание работы`"
                     @ok="submit()"
                     @hide="clearForm()"
                     ok-title="Ок"
                     ok-variant="success"
                     cancel-title="Отмена"
                     cancel-variant="danger"
                     :ok-disabled="!Boolean(name) || !Boolean(measurement)"
                     no-close-on-backdrop
            >
                <label for="name">Имя работы</label>
                <b-form-input
                        v-model="name"
                        placeholder="Стрижка бобра"
                        id="name"
                        size="sm"
                        :state="Boolean(name)"
                ></b-form-input>
                <label for="measurement">Единица изменения</label>
                <b-form-input
                        v-model="measurement"
                        placeholder="Пог. см."
                        id="measurement"
                        size="sm"
                        :state="Boolean(measurement)"
                ></b-form-input>
                <label for="workGroup">Группа работ</label>
                <v-select
                        :options="workGroups"
                        v-model="workGroup"
                        :reduce="group => group.id"
                        label="name"
                        :filter="(options, search) => searchText(options, search, [`name`])"
                >
                </v-select>
                <b-button variant="info" size="sm" class="my-2" @click="showAddGroup"
                          v-if="$can('create', 'workGroups')">
                    Группы работ нет в списке
                </b-button>
            </b-modal>
            <b-modal id="deleteWorkType"
                     @ok="deleteWorkType"
                     ok-title="Ок"
                     ok-variant="success"
                     cancel-title="Отмена"
                     cancel-variant="danger"
            >
                <p class="my-4" v-if="workType">
                    Вы действительно хотите удалить работу <span class="text-danger">{{ workType.name }}</span> из системы?
                </p>
            </b-modal>
        </div>
        <CreateWorkGroup
                :show="addGroupModalShow"
                @hidden="hideAddGroup"
                @workGroupCreated="workGroupCreated"
        />
    </div>
</template>

<script>
    import searchText from "../../utils/searchText";
    import CreateWorkGroup from "../../components/CreateWorkGroup/CreateWorkGroup.vue";
    export default {
        computed: {
            workTypes() {
                return this.$store.state.workTypes;
            },
            workGroups() {
                return this.$store.state.workGroups;
            }
        },
        components: {
            CreateWorkGroup
        },
        data() {
            return {
                fields: [
                    {
                        key: `name`,
                        label: `Имя`,
                        sortable: true
                    },
                    {
                        key: `unit_of_measurement`,
                        label: `Единица измерения`
                    },
                    {
                        key: `group`,
                        label: `Группа`,
                        sortable: true
                    },
                    {
                        key: `group_name`,
                        label: `Имя группы`,
                        sortable: true
                    },
                    {
                        label: ``,
                        key: `actions`,
                        class: `w-80px`
                    }
                ],
                isEdit: false,
                measurement: null,
                name: null,
                workType: null,
                workGroup: null,
                addGroupModalShow: false,
                filter: null,
                perPage: 200,
                pageOptions: [5, 10, 15, 20, 100, 200],
                totalRows: 1,
                currentPage: 1,
                filterOn: []
            }
        },
        methods: {
            clearForm() {
                this.isEdit = false;
                this.name = null;
                this.measurement = null;
                this.workType = null;
                this.workGroup = null;
            },
            deleteWorkType() {
                this.$store.dispatch(`deleteWorkType`, this.workType.work_id);
                this.$store.dispatch(`getWorkTypes`);
            },
            onDelete(data) {
                this.workType = data.item;
                this.$bvModal.show(`deleteWorkType`);
            },
            onEdit(data) {
                this.isEdit = true;
                this.workType = data.item;
                const {name, unit_of_measurement, group_id} = data.item;
                this.name = name;
                this.measurement = unit_of_measurement;
                this.workGroup = group_id;
                this.$bvModal.show(`typeWorkModal`);
            },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
            async submit() {
                const {measurement, name, workType, workGroup} = this;
                const data = {
                    name,
                    unit_of_measurement: measurement,
                    work_id: workType?.work_id,
                    group_id: workGroup
                };
                const action = this.isEdit ? `editWorkType` : `createWorkType`;
                await this.$store.dispatch(action, data).then(() => {
                    this.clearForm();
                    this.$store.dispatch(`getWorkTypes`);
                }).catch(err => {
                    this.clearForm();
                    this.$store.dispatch(`showErrorModal`, err.response.data);
                });
            },
            searchText,
            showAddGroup() {
                this.addGroupModalShow = true;
            },
            hideAddGroup() {
                this.addGroupModalShow = false;
            },
            async workGroupCreated(createdGroupId) {
                await this.$store.dispatch(`getWorkGroups`);
                this.workGroup = createdGroupId;
                this.hideAddGroup();
            }
        },
        async mounted() {
            await this.$store.dispatch(`getWorkTypes`);
            this.totalRows = this.workTypes.length;
            this.$store.dispatch(`getWorkGroups`);
        },
        name: "WorkTypes"
    }
</script>

<style scoped>

</style>