<template>
    <div>
        <b-modal id="deleteMaterialGroup"
                 @ok="submit"
                 @show="beforeOpen"
                 ok-title="Ок"
                 ok-variant="success"
                 cancel-title="Отмена"
                 cancel-variant="danger"
        >
            <p class="my-4">
                Вы действительно хотите удалить группу
                <span class="text-danger">{{ name }}</span>
                из системы?
            </p>
        </b-modal>
    </div>
</template>

<script>
    export default {
        computed: {
            selectedMaterialGroupId() {
                return this.$store.state.materials.selectedMaterialGroupId;
            }
        },
        data() {
            return {
                name: null
            }
        },
        methods: {
            clearForm() {
                this.name = null;
            },
            async beforeOpen() {
                const group = await this.$store.dispatch(`getMaterialGroup`, this.selectedMaterialGroupId);
                this.setGroup(group);
            },
            setGroup({name}) {
                this.name = name;
            },
            submit() {
                this.$store.dispatch(`deleteMaterialGroup`, this.selectedMaterialGroupId)
                    .then(() => this.$store.dispatch(`getMaterialGroups`));
            }
        },
        name: "DeleteMaterialGroup"
    }
</script>

<style scoped>

</style>