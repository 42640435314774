<template>
    <div>
        <h1>Виды работ</h1>
        <types-work-list
                :options="options"
                @workDeleted="getOptions()"
        ></types-work-list>
        <div class="d-flex justify-content-end">
            <b-button v-b-modal.objectModal class="btn btn-success btn-circle mx-5">+</b-button>
            <b-modal id="objectModal" title="Новый объект" @ok="submit()">
                <label for="name">Имя работы</label>
                <b-form-input v-model="fields.name.value" placeholder="Замена бетонного покрытия (медленная)"
                              id="name"></b-form-input>
                <label for="unit_of_measurement">Единица измерения</label>
                <b-form-input v-model="fields.unit_of_measurement.value" placeholder="м2"
                              id="unit_of_measurement"></b-form-input>
            </b-modal>
        </div>
    </div>
</template>

<script>
    import typesWorkList from '../components/TypesWorkList.vue';
    import axios from 'axios';

    export default {
        name: "TypesWork",
        data() {
            return {
                fields: {
                    name: {
                        value: null
                    },
                    unit_of_measurement: {
                        value: null
                    }
                },
                options: []
            }
        },
        components: {
            typesWorkList
        },
        mounted() {
            this.getOptions();
        },
        methods: {
            submit() {
                const data = {
                    name: this.fields.name.value,
                    unit_of_measurement: this.fields.unit_of_measurement.value
                };
                axios.post(`/api/types-of-work`, data).then(res => {
                    console.log(res);
                }).catch(err => {
                    console.log(err);
                });
                this.getOptions();
            },
            getOptions() {
                axios.get('/api/types-of-work').then(res => {
                    this.options = res.data;
                }).catch(err => {
                    console.log(err);
                });
            }
        }
    }
</script>

<style scoped>
    .btn-circle {
        width: 70px;
        height: 70px;
        padding: 10px 16px;
        border-radius: 35px;
        font-size: 24px;
        line-height: 1.33;
        text-align: center;
    }
</style>