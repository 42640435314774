<template>
	<b-form-group
		label-for="name"
		label-class="lead"
	>
    <template #label>
      Название задачи: <span class="text-danger">*</span>
    </template>
		<div class="d-flex align-items-center">
			<b-input v-if="isEdit" v-model="name" size="sm" trim id="name"
                     placeholder="Напишите название задачи..."
                     ref="taskHeader"
                     @contextmenu="e => $emit(`rightClick`, {e, t: this.$refs[`taskHeader`]})"
            />
			<span v-else>{{ name }}</span>
			<i v-if="!isEdit" class="fas fa-pen ml-2 text-center small" title="Редактировать"
			   @click="onStartEditClick"/>
			<i v-if="isEdit" class="fas fa-check text-success ml-2" title="Применить" @click="onOkClick"></i>
			<i v-if="isEdit" class="fas fa-ban ml-2 text-danger" title="Отменить" @click="onCancelEditClick"></i>
		</div>

	</b-form-group>
</template>

<script>
export default {
	name: "TaskHeader",
	data() {
		return {
			isEdit: false,
			oldName: ``
		}
	},
	computed: {
		name: {
			get() {
				return this.value
			},
			set(v) {
				this.$emit(`input`, v);
			}
		}
	},
	methods: {
		onStartEditClick() {
			this.isEdit = true;
			this.oldName = this.name;
		},
		onCancelEditClick() {
			this.isEdit = false;
			this.$emit(`input`, this.oldName);
		},
		onOkClick() {
			this.isEdit = false;
		}
	},
	props: {
		value: String
	}
}
</script>

<style scoped>
#name::placeholder {
	opacity: 0.7;
}
</style>