<template>
    <div>
        Доступные фильтра:
        <ul v-if="!isBuildingFilter">
            <li v-for="el in data" :key="el.id">
                <span class="font-weight-bold">{{el.name}}</span>
                <i class="fas fa-plus-circle text-success h6 mx-1" @click="() => onClick(el)"/>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "AvailableFilters",
        props: {
            data: {
                type: Array
            }
        },
        computed: {
            isBuildingFilter() {
                return this.data[0].id === `buildingId`
            }
        },
        methods: {
            onClick({id, name, key, multiple}) {
                this.$emit(`addFilter`, {
                    id,
                    name,
                    rowId: Math.floor(Math.random() * 100000),
                    key,
                    multiple
                });
            }
        },
        mounted() {
            if (this.isBuildingFilter) {
                this.onClick({id: `buildingId`, name: ``, key: `building_id`})
            }
        }
    }
</script>

<style scoped>
    i:hover {
        transform: scale(1.3);
    }
</style>