import Vue from './core/Vue';
import { abilitiesPlugin } from "@casl/vue";
import abilities from './utils/Abilities/abilities';
Vue.use(abilitiesPlugin, abilities(store.getters.currentUser));
import router from './router'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import App from './App.vue';
import store from './store/store';
import vSelect from 'vue-select';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './static/css/dashboard.css'
import './static/css/index.styl'
import './static/fontawesome-free-5.12.1-web/css/all.css'
import 'vue-select/dist/vue-select.css';
import underscore from 'vue-underscore';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.component(`vSelect`, vSelect);
Vue.use(underscore);

const moment = require('moment')
require('moment/locale/ru');
Vue.use(require('vue-moment'), {
    moment
})
// Vue.prototype.$moment = moment;
Vue.use(require(`chartjs-adapter-moment`));
new Vue({
    router,
    render: h => h(App),
    store,
}).$mount('#app');
