<template>
    <div id="app">
        <TelegramDatepicker v-if="isWebAppPage"/>
        <main-component class="py-4" v-else/>
    </div>
</template>

<script>
import mainComponent from './views/Template.vue';
import interceptors from './utils/interceptors';
import TelegramDatepicker from "@/views/TelegramDatepicker/TelegramDatepicker";

export default {
    name: 'app',
    computed: {
        isWebAppPage() {
            return this.$route.path === `/telegram-datepicker`;
        }
    },
    components: {
        mainComponent,
        TelegramDatepicker
    },
    mixins: [
        interceptors
    ],
    mounted() {
        this.$store.dispatch(`updateAbility`, this);
        window.addEventListener('resize', () => this.$store.dispatch(`onScreenResize`));
    }
}
</script>
<style lang="stylus">
</style>
