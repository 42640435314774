<template>
    <HorizontalStepper
            :steps="steps"
            locale="ru"
            :reset="true"
            @stepper-finished="onFinishClick"
            :keep-alive="false"
    />
</template>

<script>
import HorizontalStepper from "vue-stepper";
import OneStep from "./Steps/OneStep";
import TwoStep from "./Steps/TwoStep";
import ThreeStep from "./Steps/ThreeStep";

export default {
    name: "RequestCreate",
    components: {
        HorizontalStepper
    },
    computed: {
        selectedShifts() {
            return this.$store.state.deleteShiftReport.selectedShifts;
        },
        selectedObject() {
            return this.$store.state.deleteShiftReport.selectedObject;
        },
        isWebApp() {
            return this.$store.getters.isTelegramWebApps;
        },
        sendOnCreate() {
            return this.$store.state.deleteShiftReport.sendOnCreate;
        },
        editRequest() {
            return this.$store.state.deleteShiftReport.editRequest;
        }
    },
    data() {
        return {
            shifts: [],
            selected: [],
            objects: [],
            objectFind: ``,
            onProcess: false,
            selectedProcess: null,
            steps: [
                {
                    icon: 'home',
                    name: 'oneStep',
                    title: 'Строительный объект',
                    subtitle: '',
                    component: OneStep,
                    completed: false
                },
                {
                    icon: 'list',
                    name: 'twoStep',
                    title: 'Доступные смены',
                    subtitle: '',
                    component: TwoStep,
                    completed: false
                },
                {
                    icon: 'done',
                    name: 'threeStep',
                    title: 'Выбранные смены',
                    subtitle: '',
                    component: ThreeStep,
                    completed: false
                }
            ]
        }
    },
    methods: {
        async onFinishClick() {
            const {createRequest, updateRequest, $store, $bvModal, isWebApp, sendOnCreate, editRequest} = this;
            if (editRequest && (editRequest.status === 0)) {
                updateRequest()
                    .then(() => $bvModal.msgBoxOk(`Заявка успешно обновлена!`))
                    .then(() => $store.dispatch(`requests_clearStepper`))
                    .then(() => {
                        if (isWebApp) {
                            window.Telegram.WebApp.close();
                        } else {
                            this.$router.push(`/requests/delete-shift-report#${editRequest.id}`);
                        }
                    })
            } else {
                const request = await createRequest();
                if ((request.author === request.inspector) && sendOnCreate) {
                    $store.dispatch(`requests_approve`, {
                        requestId: Number(request.id)
                    })
                        .then(() => $bvModal.msgBoxOk(`Заявка вами согласованна и исполнена системой!`))
                        .then(() => $store.dispatch(`requests_clearStepper`))
                        .then(() => {
                            if (isWebApp) {
                                window.Telegram.WebApp.close();
                            } else {
                                this.$router.push(`/requests/delete-shift-report#${request.id}`);
                            }
                        })
                } else {
                    const msg = sendOnCreate
                        ? `Заявка успешно направлена куратору на согласование!`
                        : `Заявка успешно создана в статусе Черновик!`;
                    $bvModal.msgBoxOk(msg)
                        .then(() => {
                            if (sendOnCreate) {
                                return $store.dispatch(`requests_sendToApprove`, {
                                    requestId: Number(request.id)
                                })
                            } else {
                                return Promise.resolve();
                            }
                        })
                        .then(() => $store.dispatch(`requests_clearStepper`))
                        .then(() => {
                            if (isWebApp) {
                                window.Telegram.WebApp.close();
                            } else {
                                this.$router.push(`/requests/delete-shift-report#${request.id}`);
                            }
                        })
                }
            }
        },
        createRequest() {
            const {selectedShifts, selectedObject: {building_process_id, curator_id}} = this;
            const payload = {
                inspector: curator_id,
                buildingProcess: building_process_id,
                shifts: selectedShifts.map(({shift_id, cause, telegram_message_id, date_time_begin}) => ({shift_id, cause, telegram_message_id, date_time_begin}))
            };
            return this.$store.dispatch(`requests_createRequest`, payload);
        },
        updateRequest() {
            const {selectedShifts, selectedObject: {building_process_id, curator_id}, editRequest} = this;
            const payload = {
                inspector: curator_id,
                buildingProcess: building_process_id,
                shifts: selectedShifts.map(({shift_id, cause, telegram_message_id, date_time_begin}) => ({shift_id, cause, telegram_message_id, date_time_begin}))
            };
            return this.$store.dispatch(`requests_updateRequest`, {id: editRequest.id, payload});
        }
    }
}
</script>

<style>
.w30px {
    width: 30px;
}
</style>