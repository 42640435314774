<template>
    <div>
        <div class="mx-auto my-2 w-25">
            <h3>Группы работ</h3>
        </div>
        <div class="d-flex justify-content-end my-2">
            <b-button
                    v-b-modal.groupWorkModal
                    size="sm"
                    variant="success"
                    v-if="$can('create', 'workGroups')"
            >
                Создать группу работ
            </b-button>
        </div>
        <div class="d-flex my-2 justify-content-between">
            <b-input-group size="sm">
                <b-form-input
                        v-model="filter"
                        type="search"
                        id="filterInput"
                        placeholder="Введите для поиска"
                ></b-form-input>
            </b-input-group>
            <b-col sm="7" md="2" class="my-1">
                <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                ></b-pagination>
            </b-col>
            <b-col sm="5" md="3" class="my-1">
                <b-form-group
                        label="На странице"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="4"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="perPageSelect"
                        class="mb-0"
                >
                    <b-form-select
                            v-model="perPage"
                            id="perPageSelect"
                            size="sm"
                            :options="pageOptions"
                    ></b-form-select>
                </b-form-group>
            </b-col>
        </div>
        <b-table
                :fields="fields"
                striped
                small
                :items="items"
                :current-page="currentPage"
                :per-page="perPage"
                @filtered="onFiltered"
                :filter="filter"
        >
            <template v-slot:cell(actions)="data">
                <i class="fas fa-pen text-center text-success mx-2"
                   @click="onEditClick(data)"
                   v-if="$can('update', 'workGroups')"
                ></i>
            </template>
        </b-table>
        <b-modal id="groupWorkModal"
                 title="Создание группы работ"
                 @ok="submit()"
                 @hide="clearItem()"
                 ok-title="Ок"
                 ok-variant="success"
                 cancel-title="Отмена"
                 cancel-variant="danger"
                 no-close-on-backdrop
        >
            <WorkGroupItem :item="item" ref="workGroupRef"/>
        </b-modal>
    </div>
</template>

<script>
    import WorkGroupItem from "./WorkGoupItem/WorkGroupItem.vue";

    export default {
        components: {
            WorkGroupItem
        },
        computed: {
            items() {
                return this.$store.state.workGroups;
            }
        },
        data() {
            return {
                fields: [
                    {
                        key: `name`,
                        label: `Имя`,
                        sortable: true
                    },
                    {
                        key: `group`,
                        label: `Группа`,
                        sortable: true
                    },
                    {
                        key: `unit`,
                        label: `Ед. изм.`,
                        sortable: true
                    },
                    {
                        key: `method`,
                        label: `Метод`,
                        sortable: true
                    },
                    {
                        key: `target`,
                        label: `Целевой объект`,
                        sortable: true
                    },
                    {
                        key: `material`,
                        label: `Материал`,
                        sortable: true
                    },
                    {
                        key: `equipment`,
                        label: `Инструмент`,
                        sortable: true
                    },
                    {
                        key: `actions`,
                        label: ``,
                        sortable: false
                    }
                ],
                isEdit: false,
                item: null,
                filter: null,
                perPage: 200,
                pageOptions: [5, 10, 15, 20, 100, 200],
                totalRows: 1,
                currentPage: 1,
                filterOn: []
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.$store.dispatch(`getWorkGroups`);
                this.totalRows = this.items.length;
            },
            async submit() {
                const data = this.$refs.workGroupRef.$data;
                if (this.item) {
                    await this.editGroup(data);
                } else {
                    await this.createGroup(data);
                }
                this.init();
            },
            clearItem() {
                this.item = null;
            },
            onEditClick({item}) {
                this.item = item;
                this.$bvModal.show(`groupWorkModal`);
            },
            createGroup(data) {
                this.$store.dispatch(`createWorkGroup`, data);
            },
            editGroup(data) {
                const {id} = this.item;
                this.$store.dispatch(`editWorkGroup`, {id, data});
            },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length
                this.currentPage = 1
            }
        },
        name: "WorkGroups"
    }
</script>

<style scoped>

</style>