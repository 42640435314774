<template>
    <div>
        <div class="d-flex justify-content-end my-2">
            <b-button
                    v-b-modal.targetModal
                    size="sm"
                    variant="success"
                    v-if="$can('create', 'targets')"
            >Добавить работу в договор
            </b-button>
        </div>
        <div class="d-flex my-2 justify-content-between">
            <b-input-group size="sm">
                <b-form-input
                        v-model="filter"
                        type="search"
                        id="filterInput"
                        placeholder="Введите для поиска"
                ></b-form-input>
            </b-input-group>
            <b-col sm="7" md="2" class="my-1">
                <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                ></b-pagination>
            </b-col>
            <b-col sm="5" md="3" class="my-1">
                <b-form-group
                        label="На странице"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="4"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="perPageSelect"
                        class="mb-0"
                >
                    <b-form-select
                            v-model="perPage"
                            id="perPageSelect"
                            size="sm"
                            :options="pageOptions"
                    ></b-form-select>
                </b-form-group>
            </b-col>
        </div>
        <b-table
                :fields="fields"
                :filter="filter"
                small
                striped
                :items="items"
                :current-page="currentPage"
                :per-page="perPage"
                @filtered="onFiltered"
        >
            <template v-slot:cell(actions)="data">
                <i class="fas fa-pen text-center text-success mx-2"
                   @click="onEdit(data)"
                   v-if="$can('update', 'targets')"
                ></i>
                <i class="fas fa-trash-alt text-danger text-center mx-2"
                   @click="onDelete(data)"
                   v-if="$can('delete', 'targets')"
                ></i>
            </template>
        </b-table>
        <div class="d-flex justify-content-end">
            <b-modal id="targetModal"
                     :title="isEdit ? `Редактирование работы` : `Создание работы`"
                     @ok="submit()"
                     @hide="clearForm()"
                     ok-title="Ок"
                     ok-variant="success"
                     :ok-disabled="!Boolean(selectedObject) || !Boolean(value)"
                     cancel-title="Отмена"
                     cancel-variant="danger"
                     @show="beforeOpen"
                     no-close-on-backdrop
            >
                <label for="objectName">Имя объекта</label>
                <v-select
                        :class="selectedObject ? 'green-state' : 'red-state'"
                        :disabled="isEdit"
                        label="object_name"
                        value="building_process_id"
                        :options="objectsInProcess"
                        v-model="selectedObject">
                </v-select>

                <label for="workType">Работа</label>
                <v-select
                        :class="selectedWorkType ? 'green-state' : 'red-state'"
                        :disabled="isEdit"
                        label="name"
                        :options="workTypes"
                        v-model="selectedWorkType"
                        :filter="(workTypes, search) => searchText(workTypes, search, [`group_name`, `name`])"
                >
                    <template #option="{name, group_name}">
                        <span class="small font-weight-bold">Имя:</span> {{name}}
                        <span v-if="group_name">
                        <span class="small font-weight-bold">Группа:</span>
                         {{ group_name}}
                    </span>
                    </template>
                </v-select>

                <label for="alternativeWorkName">Имя работы проектное</label>
                <b-form-input
                        v-model="alternativeWorkName"
                        placeholder="Ремонт швов сжатия на перроне"
                        id="alternativeWorkName"
                        size="sm"
                        :state="Boolean(alternativeWorkName)"
                ></b-form-input>
                <div v-if="Boolean(selectedWorkType) && selectedMeasurement">
                    <label for="value">
                        Необходимо выполнить в единицах
                        <span class="text-success font-weight-bold text-capitalize">
                            {{selectedMeasurement.unit_of_measurement}}
                        </span>
                    </label>
                    <b-form-input
                            v-model="value"
                            id="value"
                            size="sm"
                            :state="Boolean(value)"
                            type="number"
                    ></b-form-input>
                </div>
            </b-modal>
            <b-modal id="deleteModal"
                     @ok="deleteTarget"
                     ok-title="Ок"
                     ok-variant="success"
                     cancel-title="Отмена"
                     cancel-variant="danger"
            >
                <p class="my-4" v-if="target">
                    Вы действительно хотите работу по договору
                    <span class="text-danger">{{ target.work_name }}</span>
                    объекта
                    <span class="text-danger">{{ target.object_name }}</span>
                    из системы?
                </p>
            </b-modal>
        </div>
    </div>
</template>

<script>
    import searchText from "../../utils/searchText";

    export default {
        computed: {
            items() {
                return this.$store.state.targets;
            },
            objectsInProcess() {
                return this.$store.state.objectsInProcess;
            },
            workTypes() {
                return this.$store.state.workTypes;
            },
            selectedMeasurement() {
                return this.workTypes.find(el => el.work_id === this.selectedWorkType?.work_id);
            }
        },
        data() {
            return {
                alternativeWorkName: null,
                fields: [
                    {
                        key: `object_name`,
                        label: `Имя объекта`,
                        sortable: true
                    },
                    {
                        key: `work_name`,
                        label: `Имя работы`,
                        sortable: true
                    },
                    {
                        key: `work_name_project`,
                        label: `Имя работы проектное (рабочее)`,
                        sortable: true
                    },
                    {
                        key: `value`,
                        label: `Необходимо выполнить`,
                        sortable: true
                    },
                    {
                        key: `unit_of_measurement`,
                        label: `Единица измерения`,
                        sortable: true
                    },
                    {
                        label: ``,
                        key: `actions`,
                        class: `w-80px`
                    }
                ],
                filterOn: [],
                isEdit: false,
                filter: null,
                perPage: 200,
                pageOptions: [5, 10, 15, 20, 100, 200],
                totalRows: 1,
                currentPage: 1,
                selectedObject: null,
                selectedWorkType: null,
                target: null,
                value: null
            }
        },
        methods: {
            beforeOpen() {
                this.$store.dispatch(`getObjectsInProcess`);
                this.$store.dispatch(`getWorkTypes`);
            },
            clearForm() {
                this.isEdit = false;
                this.alternativeWorkName = null;
                this.selectedObject = null;
                this.selectedWorkType = null;
                this.target = null;
                this.value = null;
            },
            deleteTarget() {
                this.$store.dispatch(`deleteTarget`, this.target);
                this.$store.dispatch(`getTargets`);
            },
            onDelete(data) {
                this.target = data.item;
                this.$bvModal.show(`deleteModal`);
            },
            onEdit(data) {
                this.isEdit = true;
                const {work_name_project, value} = data.item;
                this.alternativeWorkName = work_name_project;
                data.item.name = data.item.work_name;
                this.selectedObject = data.item;
                this.selectedWorkType = data.item;
                this.target = data.item;
                this.value = value;
                this.$bvModal.show(`targetModal`);
            },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
            searchText,
            async submit() {
                const {alternativeWorkName, selectedObject, selectedWorkType, value} = this;
                const data = {
                    building_process_id: selectedObject.building_process_id,
                    building_name: selectedObject.object_name,
                    work_id: selectedWorkType.work_id,
                    work_name_project: alternativeWorkName,
                    old_work_name_project: selectedObject.work_name_project,
                    value,
                    old_value: selectedObject.value,
                    work_name: selectedObject.work_name
                };
                const action = this.isEdit ? `editTarget` : `createTarget`;
                await this.$store.dispatch(action, data).then(() => {
                    this.clearForm();
                    this.$store.dispatch(`getTargets`);
                }).catch(err => {
                    this.clearForm();
                    this.$store.dispatch(`showErrorModal`, err.response.data);
                });
            }
        },
        async mounted() {
            await this.$store.dispatch(`getTargets`);
            this.totalRows = this.items.length;
        },
        name: "Targets"
    }
</script>

<style lang="scss">
    .red-state {
        .vs__dropdown-toggle {
            border: 1px solid #dc3545;
        }
    }

    .green-state {
        .vs__dropdown-toggle {
            border: 1px solid #28a745;
        }
    }

    .vs__dropdown-menu {
        width: fit-content !important;
    }
</style>