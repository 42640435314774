<template>
    <div>
        <div class="d-flex my-2 justify-content-between">
            <b-input-group size="sm">
                <b-form-input
                        v-model="filter"
                        type="search"
                        id="filterInput"
                        placeholder="Введите для поиска"
                ></b-form-input>
            </b-input-group>
            <b-col sm="7" md="2" class="my-1">
                <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                ></b-pagination>
            </b-col>
            <b-col sm="5" md="3" class="my-1">
                <b-form-group
                        label="На странице"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="4"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="perPageSelect"
                        class="mb-0"
                >
                    <b-form-select
                            v-model="perPage"
                            id="perPageSelect"
                            size="sm"
                            :options="pageOptions"
                    ></b-form-select>
                </b-form-group>
            </b-col>
        </div>
        <b-table
                :fields="fields"
                striped
                small
                :items="items"
                :current-page="currentPage"
                :per-page="perPage"
                @filtered="onFiltered"
                :filter="filter"
        ></b-table>
    </div>
</template>

<script>
    export default {
        computed: {
            items() {
                return this.$store.state.fuelLimits;
            }
        },
        data() {
            return {
                fields: [
                    {
                        key: `object_name`,
                        label: `Имя объекта`,
                        sortable: true
                    },
                    {
                        key: `name`,
                        label: `Имя топлива`,
                        sortable: true
                    },
                    {
                        key: `value`,
                        label: `Лимит`,
                        sortable: true
                    },
                    {
                        key: `unit`,
                        label: `Единица измерения`,
                        sortable: true
                    }
                ],
                filter: null,
                perPage: 200,
                pageOptions: [5, 10, 15, 20, 100, 200],
                totalRows: 1,
                currentPage: 1,
                filterOn: []
            }
        },
        mounted() {
            this.$store.dispatch(`getFuelLimits`);
            this.totalRows = this.items.length;
        },
        name: "FuelLimits",
        methods: {
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length
                this.currentPage = 1
            }
        }
    }
</script>

<style scoped>

</style>