var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((Object.entries(_vm.availableObjectsFromShifts)),function(ref){
var name = ref[0];
var shifts_ = ref[1];
return _c('b-card',{key:name,staticClass:"m-0 scroll-container",attrs:{"title":name}},[_c('b-table',{attrs:{"items":shifts_,"small":"","striped":"","fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(shift_id)",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("https://t.me/c/" + (item.bp.common_chat_id.replace("-100", "")) + "/" + (item.telegram_message_id)),"target":"_blank"}},[_vm._v(" "+_vm._s(item.shift_id)+" ")])]}},{key:"cell(date_time_begin)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$moment(data.item.date_time_end).format("DD.MM.YYYY (dd) HH:mm"))+" ")]}},{key:"cell(date_time_end)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$moment(data.item.date_time_end).format("DD.MM.YYYY (dd) HH:mm"))+" ")]}},{key:"cell(Author)",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("https://t.me/+" + (item.Author.phone)),"target":"_blank"}},[_vm._v(" "+_vm._s(item.Author.first_name)+" "+_vm._s(item.Author.last_name)+" ")])]}},{key:"cell(bp.MainStaff)",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("https://t.me/+" + (item.bp.MainStaff.phone)),"target":"_blank"}},[_vm._v(" "+_vm._s(item.bp.MainStaff.first_name)+" "+_vm._s(item.bp.MainStaff.last_name)+" ")])]}},{key:"cell(bp.Curator)",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("https://t.me/+" + (item.bp.Curator.phone)),"target":"_blank"}},[_vm._v(" "+_vm._s(item.bp.Curator.first_name)+" "+_vm._s(item.bp.Curator.last_name)+" ")])]}},{key:"cell(cause)",fn:function(ref){
var item = ref.item;
return [_c('b-form-textarea',{staticStyle:{"min-width":"200px"},attrs:{"size":"sm","value":item.cause,"formatter":_vm.formatter},on:{"input":function (v) { return _vm.onCauseChange(item, v); }}}),(item.cause)?_c('span',{staticClass:"text-info small"},[_vm._v("Использовано "+_vm._s(item.cause.length)+" из "+_vm._s(_vm.limit)+" символов")]):_vm._e(),(!item.cause)?_c('span',{staticClass:"text-danger small"},[_vm._v("Обязательно для заполнения")]):_vm._e()]}}],null,true)})],1)}),(_vm.showSendOrCreateBlock)?_c('div',{staticClass:"d-flex justify-content-end p-3"},[_c('b-form-group',[_c('b-form-radio',{attrs:{"name":"send-request","value":false},model:{value:(_vm.sendOnCreate),callback:function ($$v) {_vm.sendOnCreate=$$v},expression:"sendOnCreate"}},[_vm._v(" Сохранить как черновик "),_c('small',{staticClass:"text-muted d-block mb-3"},[_vm._v(" Ваша заявка будет сохранена, но не отправлена на согласование."),_c('br'),_vm._v(" Черновик вы сможете отредактировать и отправить в любой момент. ")])]),_c('b-form-radio',{attrs:{"name":"send-request","value":true},model:{value:(_vm.sendOnCreate),callback:function ($$v) {_vm.sendOnCreate=$$v},expression:"sendOnCreate"}},[_vm._v(" "+_vm._s(_vm.sendText)+" ")])],1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }