<template>
    <b-container>
        <b-row>
            <b-col>
                <div class="d-flex justify-content-end">
                    <b-button size="sm" variant="success" class="mx-2" @click.once="onFillReportTableClick">
                        Добавить все активные стройки в график отчетов
                    </b-button>
                    <b-button size="sm" variant="success" @click.once="onRunReportsForciblyClick">
                        Запустить отчеты принудительно
                    </b-button>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group label="Выберите отчеты:" v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                        id="availableReports"
                        v-model="selected"
                        :options="availableReports"
                        name="availableReports"
                    ></b-form-checkbox-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-table small :items="items" :fields="fields">
                <template #cell(reportId)="row">
                    {{ getReportName(row.item.reportId) }}
                </template>
                <template #cell(createdAt)="row">
                    {{ Date.parse(row.item.createdAt) | moment("DD.MM.YYYY dd HH:mm:ss") }}
                </template>
                <template #cell(updatedAt)="row">
                    {{ Date.parse(row.item.createdAt) | moment("DD.MM.YYYY dd HH:mm:ss") }}
                </template>
                <template #cell(actions)="row">
                    <i class="fas fa-trash-alt" @click="onDeleteClick(row.item)"></i>
                </template>
            </b-table>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: "ReportsManagement",
    computed: {
        items() {
            return this.activeReports.filter(el => this.selected.includes(el.reportId));
        }
    },
    data() {
        return {
            selected: [],
            availableReports: [],
            activeReports: [],
            fields: [
                {
                    key: `reportId`,
                    label: `Имя отчета`
                },
                {
                    key: `buildingName`,
                    label: `Имя объекта`,
                    sortable: true
                },
                {
                    key: `createdAt`,
                    label: `Отчет добавлен`,
                    sortable: true
                },
                {
                    key: `updatedAt`,
                    label: `Отчет изменен`,
                    sortable: true
                },
                {
                    key: `actions`,
                    label: ``
                }
            ]
        }
    },
    mounted() {
        this.$store.dispatch(`getAvailableReportsManagement`)
            .then(reports => this.availableReports = reports.map(({id, name}) => ({text: name, value: id})))
            .then(() => this.availableReports.forEach(el => this.selected.push(el.value)));
        this.getActiveReports();
    },
    methods: {
        getReportName(id) {
            return this.availableReports.find(el => el.value === id)?.text;
        },
        onFillReportTableClick() {
            //TODO пока хардкодом, потому что работаем только с одним типом отчетов по этой таблице. Далее будем
            // спрашивать у пользователя каким отчетом заполнить таблицу
            const reportId = 1;
            this.$store.dispatch(`fillReportsTableByCurrentProcesses`, reportId).then(this.getActiveReports);
        },
        getActiveReports() {
            this.$store.dispatch(`getActiveReports`).then(reports => this.activeReports = reports);
        },
        onDeleteClick({id}) {
            this.$bvModal.msgBoxConfirm(`Вы действительно хотите отключить отчет ${id}?`)
                .then(confirm => {
                   if (confirm) {
                       this.$store.dispatch(`deleteActiveReport`, id).then(this.getActiveReports)
                   }
                })
        },
        onRunReportsForciblyClick() {
            this.$store.dispatch(`runReportsForcibly`);
        }
    }
}
</script>

<style scoped>
.fa-trash-alt:hover {
    color: red
}
</style>