<template>
	<div class="p-2">
		<div class="d-flex justify-content-end my-2">
			<b-button
				v-b-modal.userModal
				size="sm"
				variant="success"
				v-if="$can('create', 'users')"
			>Создать сотрудника
			</b-button>
		</div>
		<div class="d-flex my-2 justify-content-between">
			<b-input-group size="sm">
				<b-form-input
					v-model="filter"
					type="search"
					id="filterInput"
					placeholder="Введите для поиска"
				></b-form-input>
			</b-input-group>
			<b-col sm="7" md="2" class="my-1">
				<b-pagination
					v-model="currentPage"
					:total-rows="totalRows"
					:per-page="perPage"
					align="fill"
					size="sm"
					class="my-0"
				></b-pagination>
			</b-col>
			<b-col sm="5" md="3" class="my-1">
				<b-form-group
					label="На странице"
					label-cols-sm="6"
					label-cols-md="4"
					label-cols-lg="4"
					label-align-sm="right"
					label-size="sm"
					label-for="perPageSelect"
					class="mb-0"
				>
					<b-form-select
						v-model="perPage"
						id="perPageSelect"
						size="sm"
						:options="pageOptions"
					></b-form-select>
				</b-form-group>
			</b-col>
		</div>
		<b-table
			striped
			hover
			:items="users"
			:fields="fields"
			small
			:current-page="currentPage"
			:per-page="perPage"
			@filtered="onFiltered"
			:filter="filter"
			v-if="showTable"
		>
			<template v-slot:cell(active)="data">
				<b-form-checkbox :checked="data.item.active" @change="v => onActiveChange(v, data.item)"/>
			</template>
			<template v-slot:cell(actions)="data">
				<i class="fas fa-pen text-center text-success" @click="onEdit(data)" v-if="$can('update', 'users')"></i>
				<i class="fas fa-trash-alt text-danger text-center mx-2" @click="onDelete(data)" v-if="$can('delete', 'users')"/>
			</template>
		</b-table>
		<div class="d-flex justify-content-end">
			<b-modal
				id="userModal"
				:title="isEdit ? `Редактирование сотрудника` : `Создание сотрудника`"
				@ok="submit()"
				@hide="clearForm()"
				@hidden="clearForm()"
				:ok-disabled="!isEmailValid"
				ok-title="Ок"
				ok-variant="success"
				cancel-title="Отмена"
				cancel-variant="danger">
				<label for="firstName">Имя</label>
				<b-form-input v-model="firstName" id="firstName"></b-form-input>
				<label for="lastName">Фамилия</label>
				<b-form-input v-model="lastName" id="lastName"></b-form-input>
				<label for="phone">Телефон</label>
				<b-form-input v-model="phone" id="phone"></b-form-input>
				<label for="Gmail">Gmail</label>
				<b-form-input v-model="gmail" id="Gmail" :state="isEmailValid"></b-form-input>
				<label for="isBot">Является ботом</label>
				<b-form-checkbox v-model="isBot" name="isBot" switch>
					{{ isBot ? `да` : `нет` }}
				</b-form-checkbox>
			</b-modal>
			<b-modal
				id="deleteUser"
				@ok="deleteUser"
				ok-title="Ок"
				ok-variant="success"
				cancel-title="Отмена"
				cancel-variant="danger">
				<p class="my-4" v-if="user">
					Вы действительно хотите удалить сотрудника <span class="text-danger">{{ user.full_name }}</span> из
					системы?
				</p>
			</b-modal>
		</div>
	</div>
</template>

<script>
const gmailRegexp = new RegExp(`^[a-z0-9](\\.?[a-z0-9]){5,}@g(oogle)?mail\\.com$`);
export default {
	name: "Users",
	data() {
		return {
			fields: [
				{
					key: 'user_id',
					label: 'ИД'
				},
				{
					key: 'first_name',
					label: 'Имя'
				},
				{
					key: 'last_name',
					label: 'Фамилия'
				},
				{
					key: 'phone',
					label: 'Телефон'
				},
				{
					key: `chat_id`,
					label: `Telegram id`
				},
				{
					key: `active`,
					label: `Действующий`
				},
				{
					key: `gmail`,
					label: `gmail`,
					sorted: true
				},
				{
					key: 'actions',
					label: ''
				}
			],
			firstName: null,
			isBot: false,
			isEdit: false,
			lastName: null,
			phone: null,
			user: null,
			userId: null,
			filter: null,
			perPage: 200,
			pageOptions: [5, 10, 15, 20, 100, 200],
			totalRows: 1,
			currentPage: 1,
			filterOn: [],
			gmail: null,
			showTable: true
		}
	},
	computed: {
		users() {
			return this.$store.state.users;
		},
		isEmailValid() {
			if (!this.gmail) {
				return true;
			}
			return gmailRegexp.test(this.gmail);
		}
	},
	mounted() {
		this.$store.dispatch(`getUsers`, {full: true});
		this.totalRows = this.users.length;
	},
	methods: {
		clearForm() {
			this.firstName = null;
			this.isBot = false;
			this.isEdit = false;
			this.lastName = null;
			this.phone = null;
			this.user = null;
			this.userId = null;
			this.gmail = null;
		},
		async deleteUser() {
			const userId = this.user[`user_id`];
			await this.$store.dispatch(`deleteUser`, userId)
				.catch(err => this.$store.dispatch(`showErrorModal`, err));
			await this.$store.dispatch(`deleteRolesByUserId`, userId)
				.catch(err => this.$store.dispatch(`showErrorModal`, err));
			this.$store.dispatch(`getUsers`, {full: true});
		},
		async submit() {
			const data = {
				first_name: this.firstName,
				last_name: this.lastName,
				phone: this.phone,
				userId: this.userId,
				isBot: this.isBot,
				gmail: this.gmail
			};
			const action = this.isEdit ? `editUser` : `createUser`;
			await this.$store.dispatch(action, data)
				.catch(err => this.$store.dispatch(`showErrorModal`, err));
			this.$store.dispatch(`getUsers`, {full: true});
		},
		onDelete(data) {
			this.user = data.item;
			this.$bvModal.show(`deleteUser`);
		},
		onEdit(data) {
			this.isEdit = true;
			const {first_name, isBot, last_name, phone, user_id, gmail} = data.item;
			this.firstName = first_name;
			this.isBot = isBot;
			this.lastName = last_name;
			this.phone = phone;
			this.userId = user_id
			this.gmail = gmail
			this.$bvModal.show(`userModal`);
		},
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
		onActiveChange(v, user) {
			const {$store} = this;
			const msg = v
				? `Установив пользователю статус Активный - вы предоставляете ему доступ ко всему функционалу системы.
				Продолжить?`
				: `Деактивация пользователя приведет к его немедленному разлогину и отключению ему всех уведомлений.
				Вы уверены что хотите запретить доступ пользователю?`
			this.$bvModal.msgBoxConfirm(msg, {
				cancelTitle: `Отмена`,
				okVariant: `success`
			}).then(confirm => {
				if (confirm) {
					$store.dispatch(`changeUserActive`, {id: user.user_id, active: v})
						.then(() => $store.dispatch(`getUsers`, {full: true}))
						.finally(this.rerenderTable);
				} else {
					this.rerenderTable();
				}
			})
		},
		rerenderTable() {
			this.showTable = false;
			this.$nextTick(() => this.showTable = true);
		}
	}
}
</script>

<style scoped>
.fa-trash-alt {
	cursor: pointer;
}
</style>