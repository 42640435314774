<template>
    <div>
        <b-form-group label-class="small" label="Комментарии">
            <b-form-checkbox
                    v-if="canCreateReportComment"
                    id="reportComment"
                    v-model="isReportComment"
                    name="reportComment"
                    :value="true"
                    :unchecked-value="false"
                    size="sm"
            >
                <span class="small">Это отчёт по задаче</span>
                <i class="far fa-question-circle small text-info ml-1"
                   v-b-tooltip.hover
                   title="Этот комментарий является моим отчётом перед постановщиком задачи, и служит основанием для её закрытия или продления"
                ></i>
            </b-form-checkbox>
            <div v-if="uploadFilesInProcess">
                <b-spinner label="Spinning"></b-spinner>
            </div>
            <div v-else class="new-comment">
                <b-form-textarea v-model="newComment"
                                 size="sm"
                                 placeholder="Напишите свой комментарий..."
                                 rows="3"
                                 ref="newComment"
                                 @contextmenu="e => $emit(`rightClick`, {e, t: this.$refs[`newComment`]})"
                />
                <div class="mt-2 action-block d-flex justify-content-end align-items-center">
                    <i class="fas fa-paperclip mr-3" @click="choseFile"></i>
                    <b-button variant="success" size="sm" title="Отправить" @click="addComment">
                        Отправить
                    </b-button>
                </div>
                <input type="file" ref="fileUpload" class="d-none" @change="onFileSelected">
            </div>
            <FileList :list="files" :can-edit="canEdit" class="mt-2"></FileList>
            <div class="p-2 mt-5">
                <CommentItem v-for="item in items" :key="item.id" :item="item" class="mb-5"
                             :can-edit="canEdit"
                             @removeItem="() => removeComment(item.id)"
                             @editItem="text => editComment(item.id, text)"
                             @removeFile="id => removeFile({id, commentId: item.id})"
                             @rightClick="d => $emit(`rightClick`, d)"
                />
            </div>
        </b-form-group>
    </div>
</template>

<script>
import CommentItem from "@/views/Tasks/TaskCard/CommentsBlock/CommentItem/CommentItem";
import FileList from "@/views/Tasks/TaskCard/TaskFiles/FileList/FileList";

export default {
    name: "CommentsBlock",
    computed: {
        items() {
            const {comments, logs, $_} = this;
            const arr = comments.concat(logs);
            return $_.sortBy(arr, `createdAt`).reverse();
        }
    },
    components: {
        CommentItem,
        FileList
    },
    props: {
        comments: Array,
        logs: Array,
        canEdit: {
            type: Boolean,
            default: false
        },
        canCreateReportComment: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            newComment: ``,
            files: [],
            isReportComment: false,
            uploadFilesInProcess: false
        }
    },
    methods: {
        addComment() {
            const {newComment, clearForm, files, isReportComment} = this;
            if (!newComment.trim().length) {
                this.$bvModal.msgBoxOk(`Комментарий не может быть пустым. Добавьте текст.`);
            } else {
                this.$emit(`addComment`, {
                    value: newComment,
                    files: files.map(el => el.id),
                    isReportComment
                });
                clearForm();
            }
        },
        clearForm() {
            this.newComment = ``;
            this.files = [];
        },
        removeComment(id) {
            this.$emit(`removeComment`, id);
        },
        editComment(id, text) {
            this.$emit(`editComment`, {id, text})
        },
        removeFile(payload) {
            this.$emit(`removeFile`, payload);
        },
        choseFile() {
            this.$refs['fileUpload'].click();
        },
        onFileSelected({target}) {
            this.uploadFilesInProcess = true;
            let fd = new FormData();
            const {files} = target;
            const file = files[0];
            fd.append(`file`, file, file.name);
            this.$store.dispatch(`uploadTaskFile`, {payload: fd})
                .then(files => this.files = this.files.concat(files))
                .finally(() => this.uploadFilesInProcess = false);
        }
    }
}
</script>

<style scoped lang="scss">
.new-comment {
  .action-block {
    i {
      cursor: pointer;
      font-size: 22px;
    }

    .fa-paperclip:hover {
      color: #0088cc;
    }

    .fa-telegram-plane:hover {
      color: #0088cc;
    }
  }
}
</style>