<template>
    <b-card class="my-2">
        <h3 class="text-success">Мастер создания группы в Telegram</h3>
        <b-form-group
            id="chatName"
            label="Имя группы в Telegram:"
            label-for="chatName"
        >
            <b-form-input
                id="chatName"
                type="text"
                placeholder="Укажите имя группы"
                required
                v-model="chatName"
                size="sm"
                :disabled="cantEdit"
            ></b-form-input>
        </b-form-group>
        <b-tabs content-class="mt-3" v-model="selectedTab">
            <b-tab title="Группы сотрудников" active>
                <b-card v-for="group in groups" :key="group.id" class="my-1 w-50" body-class="p-2" :id="`popover-${group.id}`">
                  <div class="d-flex justify-content-between">
                     <span>Группа: <b>{{group.name}}</b></span>
                      <b-button size="sm" variant="success" @click="() => addFromGroup(group.users)">
                          Добавить участников группы
                      </b-button>
                  </div>
                    <b-popover
                        :target="`popover-${group.id}`"
                        triggers="hover focus"
                    >
                        <ul>
                            <li v-for="user in group.users" :key="user.id">
                                <span>{{ user.first_name }} {{ user.last_name }}</span>
                            </li>
                        </ul>
                    </b-popover>
                </b-card>
            </b-tab>
            <b-tab title="Выбранные для добавления в Telegram">
                <div class="d-flex">
                    <b-card class="w-50" style="height: 300px; overflow: auto">
                        <b-form-input size="sm" v-model="userSearch" placeholder="Введите для поиска сотрудника"></b-form-input>
                        <b-card v-for="user in users" :key="user.phone" class="my-1" body-class="p-1 d-flex justify-content-between">
                            <span>{{user.last_name}} {{user.first_name}} {{user.phone}}</span>
                            <i class="fas fa-user-plus" @click="addToGroup(user)" v-if="!cantEdit && !participants.some(el => el.user_id === user.user_id)"></i>
                        </b-card>
                    </b-card>
                    <b-card class="w-50 ml-2">
                        <b-table :items="participants" :fields="fields" small>
                            <template v-slot:cell(full_name)="data">
                                <b-spinner variant="primary" label="Spinning" v-if="inProcess" small></b-spinner>
                                <i class="fas fa-check text-success" v-if="successUsers.includes(data.item.phone)"></i>
                                <i class="fas fa-times text-danger" v-if="successUsers.length && !successUsers.includes(data.item.phone)"></i>
                                <a :href="`https://t.me/+${data.item.phone}`" target="_blank">
                                    {{data.item.last_name}} {{data.item.first_name}}
                                </a>
                            </template>
                            <template v-slot:cell(isAdmin)="data">
                                <b-form-checkbox v-model="data.item.isAdmin" switch :disabled="cantEdit"></b-form-checkbox>
                            </template>
                            <template v-slot:cell(actions)="data">
                                <i class="fas fa-user-minus" @click="removeFromGroup(data.item)" v-if="!successUsers.length"></i>
                            </template>
                        </b-table>
                    </b-card>
                </div>
            </b-tab>
        </b-tabs>
        <div class="d-flex justify-content-end mt-2" v-if="selectedTab === 1">
            <b-button size="sm" variant="success" @click.once="onCreateGroupClick" :disabled="cantEdit">
                Создать группу в Телеграм
            </b-button>
        </div>
    </b-card>
</template>

<script>
export default {
    name: "SuperChat",
    computed: {
        users() {
            const {userSearch} = this;
            if (!userSearch || userSearch.length < 3) {
                return this.$store.state.users;
            } else {
                return this.$store.state.users.filter(({first_name, last_name, phone}) => {
                    const s = userSearch.toLowerCase();
                    return first_name.toLowerCase().includes(s)
                        || last_name.toLowerCase().includes(s)
                        || String(phone).toLowerCase().includes(s);
                })
            }
        },
        cantEdit() {
            return Boolean(this.successUsers.length);
        }
    },
    data() {
        return {
            fields: [
                {
                    key: `full_name`,
                    label: `Фамилия Имя`
                },
                {
                    key: `phone`,
                    label: `Телефон`
                },
                {
                    key: `isAdmin`,
                    label: `Администратор группы`
                },
                {
                    key: `actions`,
                    label: ``
                }
            ],
            chatName: `АДС - ${this.$store.state.wizard.selectedObject.name}`,
            participants: [],
            userSearch: ``,
            successUsers: [],
            inProcess: false,
            groups: [],
            selectedTab: 0
        }
    },
    methods: {
        onCreateGroupClick() {
            const payload = {
                name: this.chatName,
                participants: this.participants
            }
            this.inProcess = true;
            this.$store.dispatch(`createTelegramGroup`, payload)
                .then(res => {
                    this.successUsers = res.users;
                    const hasNotAddedUsers = this.hasNotAddedUsers();
                    this.$emit(`chatCreated`, {chatId: res.chatId, hasNotAddedUsers});
                }).finally(() => this.inProcess = false);
        },
        addToGroup(user) {
            this.participants.push(user);
        },
        removeFromGroup({user_id}) {
            this.participants = this.participants.filter(el => el.user_id !== user_id);
        },
        hasNotAddedUsers() {
            const {participants, successUsers} = this;
            return !participants.every(el => successUsers.includes(el.phone));
        },
        getGroups() {
            this.$store.dispatch(`getUserGroups`)
                .then(groups => groups.filter(el => el.users.length))
                .then(groups => this.groups = groups);
        },
        addFromGroup(users) {
            users.forEach(el => {
                if (!this.participants.some(({user_id}) => user_id === el.user_id)) {
                    el.isAdmin = el.ChatGroups.IsAdmin;
                    this.participants.push(el);
                }
            });
            this.selectedTab = 1;
        },
        fillDefaultsParticipants() {
            const {wizard} = this.$store.state;
            if (wizard.selectedCurator) {
                this.participants.push(wizard.selectedCurator);
            }
            if (wizard.selectedStaff) {
                if (wizard.selectedCurator) {
                    if (wizard.selectedStaff.user_id !== wizard.selectedCurator.user_id) {
                        this.participants.push(wizard.selectedStaff);
                    }
                } else {
                    this.participants.push(wizard.selectedStaff);
                }
            }
        }
    },
    mounted() {
        this.fillDefaultsParticipants();
        this.getGroups();
    }
}
</script>
<style scoped>
    .fa-user-plus:hover {
        color: #218838;
    }
    .fa-user-minus:hover {
        color: darkred;
    }

</style>