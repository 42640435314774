<template>
    <b-container fluid>
        <b-row>
            <b-col sm="12" md="4">
                <b-form-group
                    label-for="filter-input"
                    content-cols="12"
                    label-size="sm"
                    class="mt-n2"
                >
                    <b-input-group size="sm">
                        <b-form-input
                            id="filter-input"
                            v-model="_filter"
                            type="search"
                            placeholder="Поиск"
                        ></b-form-input>
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="onClearFilter">Очистить</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="6">
                <b-form-group
                    label="Сортировка:"
                    label-for="sort-by-select"
                    label-cols-sm="6"
                    content-cols-sm="6"
                    label-size="sm"
                    label-align-md="right"
                    v-slot="{ ariaDescribedby }"
                >
                    <b-input-group size="sm">
                        <b-form-select
                            id="sort-by-select"
                            v-model="_sortBy"
                            :options="sortOptions"
                            :aria-describedby="ariaDescribedby"
                            size="sm"
                        >
                        </b-form-select>
                        <i class="fas fa-sort-amount-down" v-if="sortDesc" @click="onDescClick"></i>
                        <i class="fas fa-sort-amount-up" v-else @click="onDescClick"></i>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="2">
                <b-button @click="onCreateClick" size="sm" variant="success">
                    Создать заявку
                </b-button>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: "RequestsNavbar",
    computed: {
        _sortBy: {
            get() {
                return this.sortBy;
            },
            set(v) {
                this.$emit(`sortByChange`, v);
            }
        },
        _filter: {
            get() {
                return this.filter;
            },
            set(v) {
                this.$emit(`filterChange`, v);
            }
        }
    },
    props: {
        sortOptions: Array,
        sortBy: String,
        sortDesc: Boolean,
        filter: String
    },
    methods: {
        onDescClick() {
            this.$emit(`sortDescChange`, !this.sortDesc);
        },
        onClearFilter() {
            this.$emit(`filterChange`, ``);
        },
        onCreateClick() {
            this.$store.dispatch(`requests_clearStepper`);
            this.$router.push('/requests/delete-shift-report/create');
        }
    }
}
</script>

<style scoped>
.fas {
    font-size: 20px;
}
</style>