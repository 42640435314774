<template>
    <div class="w-50 mx-auto p-5 border border-success mt-5">
        <label for="folder" class="mt-2">Рабочая папка объекта</label>
        <b-form-input size="sm"
                      v-model="folder"
                      placeholder="Укажите рабочую папку"
                      :formatter="formatter"
                      lazy-formatter
        ></b-form-input>
    </div>
</template>

<script>
    export default {
        computed: {
            folder: {
                get() {
                    return this.$store.state.wizard.folder;
                },
                set(val) {
                    this.$store.commit(`setWizardFolder`, val);
                }
            }
        },
        mounted() {
            this.$emit(`can-continue`, {
                value: Boolean(this.folder)
            });
        },
        name: "StepFour",
        watch: {
            folder(val) {
                const can = val && val !== `/`;
                this.$emit(`can-continue`, {
                    value: Boolean(can)
                });
            }
        },
        methods: {
            formatter(val) {
                let mutatedString = val.replace(/\\/g, "/");
                if (mutatedString.slice(-1) !== `/`) {
                    mutatedString += `/`;
                }
                return mutatedString;
            }
        }
    }
</script>

<style scoped>

</style>