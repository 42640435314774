<template>
    <div>
        <b-modal id="createMaterialGroup"
                 title="Создание группы материалов"
                 @ok="submit"
                 @hide="clearForm"
                 ok-title="Ок"
                 ok-variant="success"
                 cancel-title="Отмена"
                 cancel-variant="danger"
                 no-close-on-backdrop
        >
            <label for="name">Имя группы</label>
            <b-form-input
                    v-model="name"
                    placeholder="Смеси сыпучие"
                    id="name"
                    size="sm"
            ></b-form-input>
        </b-modal>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                name: null
            }
        },
        methods: {
            clearForm() {
                this.name = null;
            },
            submit() {
                const data = {
                    name: this.name
                }
                this.$store.dispatch(`createMaterialGroups`, data)
                    .then(() => this.$store.dispatch(`getMaterialGroups`));
            }
        },
        name: "CreateMaterialGroup"
    }
</script>

<style scoped>

</style>