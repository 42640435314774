<template>
    <div>
        <b-card body-class="position-relative p-2" v-if="visible">
            <div>
                <span class="font-weight-bold">Разовая задача</span>
                <div class="mt-2 px-2" v-if="isFixedDate">
                    <b-form-group
                            label="Окончание:"
                            label-cols-sm="4"
                            label-cols-md="4"
                            label-cols-lg="4"
                            label-size="sm"
                            :disabled="!canEdit || !isFixedDate"
                            label-for="end"
                    >
                        <input type="datetime-local"
                               @input="onEndChange"
                               :value="end"
                               :disabled="!canEdit || !isFixedDate"
                               id="end"
                               :class="`ml-2 mt-1 ${canEdit || isFixedDate ? 'available-input' : 'disabled-input'}`"
                        >
                        <i v-if="canEdit" class="fas fa-times text-danger ml-2" title="Очистить" @click="clearEndDate"/>
                    </b-form-group>
                    <b-form-group
                            label="Промежуточный контроль:"
                            label-cols-sm="4"
                            label-cols-md="4"
                            label-cols-lg="4"
                            label-size="sm"
                            :disabled="!canEdit || !isFixedDate"
                            label-for="control"
                    >
                        <input type="datetime-local"
                               @input="onControlChange"
                               :value="control"
                               :disabled="!canEdit || !isFixedDate || !end"
                               :class="`ml-2 ${canEdit || isFixedDate ? 'available-input' : 'disabled-input'}`"
                               id="control"
                               :max="end"
                        >
                        <i v-if="canEdit" class="fas fa-times text-danger ml-2" title="Очистить" @click="clearControl"/>
                    </b-form-group>
                </div>
            </div>
            <b-form-radio v-model="variant" name="dateVariant" value="fixed" class="mt-n3 dateVariant"
                          :disabled="!canEdit"/>
        </b-card>
        <b-card class="mt-1" body-class="position-relative p-2">
            <div>
                <span class="font-weight-bold">Повторяющаяся задача</span>
                <MyCron v-if="!isFixedDate" @change="cronChanged" :disabled="!canEdit" :initCron="initCron"
                        class="mt-3 px-3"/>
            </div>
            <b-form-radio v-model="variant" name="dateVariant" value="period" class="mt-n3 dateVariant"
                          :disabled="!canEdit"
            />
        </b-card>
    </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import MyCron from "@/views/Tasks/TaskCard/TaskDate/MyCron/MyCron";

export default {
    name: "TaskDate",
    components: {
        MyCron
    },
    computed: {
        isFixedDate() {
            return this.dateVariant === `fixed`;
        },
        variant: {
            get() {
                return this.dateVariant;
            },
            set(v) {
                this.$emit(`dateVariantChange`, v);
            }
        }
    },
    data() {
        return {
            visible: true
        }
    },
    props: {
        dateVariant: String,
        end: String,
        control: String,
        canEdit: {
            type: Boolean,
            default: false
        },
        cron: String,
        initCron: {
            type: String,
        }
    },
    methods: {
        cronChanged(v) {
            this.$emit(`cronChanged`, v);
        },
        onEndChange(v) {
            this.$emit(`endChange`, v.target.value);
        },
        clearEndDate() {
            this.$emit(`endChange`, null);
            this.$emit(`controlChange`, null);
        },
        onControlChange(v) {
            const {end, $moment} = this;
            const selected = $moment(v.target.value);
            if (selected.isAfter(end)) {
                this.visible = false;
                this.$bvModal.msgBoxOk(`Время промежуточного контроля не может быть позднее времени окончания.`)
                    .then(() => this.$emit(`controlChange`, null))
                    .then(() => this.visible = true);
            } else {
                this.$emit(`controlChange`, v.target.value);
            }
        },
        clearControl() {
            this.visible = false;
            this.$emit(`controlChange`, null);
            this.visible = true;
        }
    }
}
</script>

<style scoped>
    .available-input {
        -webkit-text-fill-color: #000000;
        opacity: 1;
        background-color: #ffffff;
        border: 1px solid #000000;
    }
    .disabled-input {
        -webkit-text-fill-color: #000000;
        opacity: 1;
        background-color: #d1d1d1;
        border: 1px solid #d1d1d1;
    }

</style>