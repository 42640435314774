<template>
    <div>
        <b-modal id="deleteMaterial"
                 @ok="submit"
                 @show="beforeOpen"
                 ok-title="Ок"
                 ok-variant="success"
                 cancel-title="Отмена"
                 cancel-variant="danger"
        >
            <p class="my-4">
                Вы действительно хотите удалить материал
                <span class="text-danger">{{ name }}</span>
                из системы?
            </p>
        </b-modal>
    </div>
</template>

<script>
    export default {
        computed: {
            selectedMaterialId() {
                return this.$store.state.materials.selectedMaterialId;
            }
        },
        data() {
            return {
                name: null
            }
        },
        methods: {
            clearForm() {
                this.name = null;
            },
            async beforeOpen() {
                const material = await this.$store.dispatch(`getMaterial`, this.selectedMaterialId);
                this.setMaterial(material);
            },
            setMaterial({name}) {
                this.name = name;
            },
            submit() {
                this.$store.dispatch(`deleteMaterial`, this.selectedMaterialId)
                    .then(() => this.$store.dispatch(`getDirectory`));
            }
        },
        name: "DeleteMaterial"
    }
</script>

<style scoped>

</style>