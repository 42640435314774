<template>
    <div class="parent">
        <div v-for="file in files" :key="file.id" class="file-container mx-1">
            <i v-if="canEdit" class="fas fa-times text-danger small remove-icon" title="Удалить"
               @click="() => onRemoveClick(file)"/>
            <div class="d-flex justify-content-center align-items-center" @click="() => onContainerClick(file)">
                <img v-if="[`image/png`, `image/jpeg`, `image/gif`].includes(file.mimeType)" :src="file.url" :alt="file.originalName">
                <i v-else class="fas fa-file"></i>
            </div>
            <div class="link-text">
                <a :href="file.url" target="_blank" class="small" :title="file.originalName">
                    {{ file.originalName }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FileList",
    computed: {
        files() {
            const {list, $store} = this;
            return list.map(el => {
                el.url += `?gjuid=${$store.state.token}`;
                return el;
            })
        },

    },
    props: {
        list: Array,
        canEdit: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onContainerClick({url}) {
            window.open(url, '_blank');
        },
        onRemoveClick(file) {
            this.$bvModal.msgBoxConfirm(`Вы действительно хотите удалить файл?`, {
                cancelTitle: `Отмена`
            })
                .then(confirm => {
                    if (confirm) {
                        this.$emit(`remove`, file.id);
                    }
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.parent {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.file-container {
  width: 100px;
  height: auto;
  text-align: center;
  position: relative;

  .remove-icon {
    position: absolute;
    right: 0;
  }

  .remove-icon:hover {
    font-size: 100%;
  }

  img {
    width: 100%;
    height: auto;
    margin: 0;
  }

  .fa-file {
    font-size: 40px;
  }

  .link-text {
    a {
      display: block; /* or inline-block */
      text-overflow: ellipsis;
      word-wrap: break-word;
      overflow: hidden;
      max-height: 1.8em;
      line-height: 1.8em;
    }
  }

}
</style>