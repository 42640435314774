<template>
  <b-overlay :show="onProcess" rounded="sm">
    <b-card
        class="mt-3"
        header-class="font-weight-bold"
        header-bg-variant="dark"
        header-text-variant="light"
        @click="closeContextMenu"
    >
      <b-container fluid v-if="!onProcess">
        <b-row>
          <b-col lg="4" order-md="2" class="mb-3">
            <b-form-group
                label="Статус"
                label-for="status"
                label-class="small font-weight-bold"
            >
              <b-badge class="ml-1" :variant="getStatusVariant()">
                {{ getStatusText() }}
              </b-badge>
            </b-form-group>
            <b-form-group
                label="Объект"
                label-for="object"
                label-class="small font-weight-bold"
                v-if="bp"
            >
              <span>{{ bp.bo.name }}</span>
            </b-form-group>
            <b-form-group
                label="Автор заявки"
                label-for="author"
                label-class="small font-weight-bold"
            >
              <UserCard :value="author" v-if="author"/>
            </b-form-group>
            <b-form-group
                label="Согласующий заявку"
                label-for="inspector"
                label-class="small font-weight-bold"
            >
              <UserCard :value="inspector" v-if="inspector"/>
            </b-form-group>
            <ActionBlock
                @close="goToRequestsPage"
                @reject="onRejectClick"
                @approve="onApproveClick"
                @sendToApprove="onSendToApproveClick"
                @edit="onEditClick"
                @repeat="onRepeatClick"
                :canApprove="canApprove"
                :canEdit="status === 0"
                :canRepeat="status === 3"
                :canSendToApprove="canEdit && !canApprove"
            />
          </b-col>
          <b-col lg="8" order-md="1">
            <b-form-group
                label="Смены для удаления"
                label-for="causes"
                label-class="small"
            >
              <ul>
                <li v-for="(c, i) in causes" :key="i">
                  <span v-html="getLink(c)"></span> - {{ c.cause }}
                </li>
              </ul>
            </b-form-group>
            <CommentsBlock :comments="comments"
                           :logs="logs"
                           :can-edit="canEdit"
                           @addComment="onAddComment"
                           @removeComment="onRemoveComment"
                           @editComment="onEditComment"
                           @removeFile="onRemoveFile"
                           @onUpload="uploadFile"
                           class="mt-4"
                           @rightClick="onRightClick"
            />
          </b-col>
        </b-row>
      </b-container>
      <b-toast id="context-menu" static no-auto-hide
               :style="`position: absolute; left:${toastX}; top:${toastY};`"
               header-class="d-none"
               v-model="showContextMenu"
      >
        <div class="p-1 text-center">
          <b-button size="sm" title="Копировать" @click="onCopyClick" class="m-2">
            Копировать
            <i class="fas fa-copy mx-1"></i>
          </b-button>
          <b-button size="sm" title="Вставить" variant="info" :disabled="!rightClickTarget"
                    @click="onPasteClick">
            Вставить
            <i class="fas fa-paste mx-1"></i>
          </b-button>
        </div>
      </b-toast>
    </b-card>
  </b-overlay>
</template>

<script>
import CommentsBlock from "@/views/Tasks/TaskCard/CommentsBlock/CommentsBlock";
import ActionBlock from "./ActionBlock/ActionBlock";
import Vue from "vue";
import daysPlural from "@/utils/daysPlural";
import UserCard from "@/views/Tasks/UserCard/UserCard";

export default {
  name: "RequestDeleteReportCard",
  components: {
    CommentsBlock,
    ActionBlock,
    UserCard
  },
  computed: {
    onProcess() {
      return this.$store.state.onProcess;
    },
    canEdit() {
      const {status} = this;
      return status === 0;
    },
    isCreate() {
      return this.$route.params.id === `new`;
    },
    users() {
      return this.$store.state.tasksManagement.shortUsers;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    objects() {
      return this.$store.state.objectsInProcess;
    },
    availableStatuses() {
      return this.$store.state.tasksManagement.availableStatuses;
    },
    canCreateTask() {
      const {name, responsible, isFixed, end, cron} = this;
      return name && responsible && (isFixed ? end : cron);
    },
    isFixed() {
      return this.dateVariant === `fixed`;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    canCreateReportComment() {
      const {author, responsible, currentUser} = this;
      return currentUser?.userId === author?.user_id || currentUser?.userId === responsible?.user_id;
    },
    isTaskClosed() {
      return this.savedStatus === 1;
    },
    isTaskExpired() {
      const {end, isTaskClosed, $moment} = this;
      return (end && !isTaskClosed) ? $moment().isAfter(end) : false;
    },
    getDaysToEnd() {
      const {end, cronDateFormat, isTaskClosed, $moment} = this;
      if (end && !isTaskClosed) {
        return $moment(end).diff($moment(), 'days');
      } else if (cronDateFormat) {
        return $moment(cronDateFormat).diff($moment(), 'days');
      } else {
        return -1;
      }
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    isTelegramWebApps() {
      return this.$store.getters.isTelegramWebApps;
    },
    isInspector() {
      const {currentUser, inspector} = this;
      if (!currentUser) {
        return false;
      }
      if (!inspector) {
        return false;
      }
      return currentUser.userId === inspector.user_id;
    },
    canApprove() {
      const {isInspector, status, isAdmin} = this;
      return [0, 1].includes(status) && (isInspector || isAdmin)
    }
  },
  data() {
    return {
      showContextMenu: false,
      name: ``,
      description: ``,
      files: [],
      comments: [],
      logs: [],
      status: 0,
      savedStatus: 0,
      author: null,
      inspector: null,
      executors: [],
      object: null,
      uploadData: null,
      end: this.$moment().add(1, 'days').set({hour: 14, minute: 0}).format("YYYY-MM-DDTkk:mm"),
      dateVariant: `fixed`,
      control: null,
      cron: '00 14 * * *',
      uploadFilesInProcess: false,
      initCron: `00 14 * * *`,
      toastX: null,
      toastY: null,
      selection: null,
      rightClickTarget: null,
      indexPosition: null,
      causes: [],
      bp: null
    }
  },
  mounted() {
    this.runPreloader();
    this.init()
        .catch(this.showErrorModal)
        .finally(this.stopPreloader);
  },
  methods: {
    getLink({shift, telegram_message_id, date_time_begin, ws}) {
      const {bp, $moment} = this;
      const time = $moment(ws ? ws.date_time_begin : date_time_begin).format(`DD.MM.YYYY dd HH:mm`);
      return `<a href="https://t.me/c/${bp.common_chat_id.replace(`-100`, ``)}/${ws ? ws.telegram_message_id : telegram_message_id}"
                target="_blank">${shift} от ${time}</a>`
    },
    daysPlural,
    onRejectClick() {
      const {$route: {params}, goToRequestsPage, $bvModal} = this;
      const cause = window.prompt(`Укажите причину отказа.`);
      if (cause) {
        this.$store.dispatch(`requests_reject`, {
          requestId: Number(params.id),
          cause
        })
            .then(() => $bvModal.msgBoxOk(`Вы отказали в согласовании заявки по причине - ${cause}!`))
            .then(goToRequestsPage);
      } else {
        this.$bvModal.msgBoxOk(`Причина отказа не может быть пустой!`, {
          okVariant: 'danger',
          title: ``
        })
      }
    },
    onApproveClick() {
      const {$route: {params}, goToRequestsPage, $bvModal} = this;
      this.$store.dispatch(`requests_approve`, {
        requestId: Number(params.id)
      })
          .then(() => $bvModal.msgBoxOk(`Заявка вами согласованна и исполнена системой!`))
          .then(goToRequestsPage);
    },
    onSendToApproveClick() {
      const {$route: {params}, goToRequestsPage, $bvModal} = this;
      this.$store.dispatch(`requests_sendToApprove`, {
        requestId: Number(params.id)
      })
          .then(() => $bvModal.msgBoxOk(`Заявка успешно направлена куратору на согласование!`))
          .then(goToRequestsPage);
    },
    goToRequestsPage() {
      const {$router, $route} = this;
      const {params} = $route;
      $router.push(`/requests/delete-shift-report#${params.id}`);
    },
    _preparePayload() {
      const {
        name, description, responsible, executors, object, end, control, cron, $moment, $store,
        isFixed, files, status
      } = this;
      const payload = {
        name,
        description,
        responsible,
        executors,
        object,
        status: status,
        files: files.map(el => el.id)
      }
      if (isFixed) {
        payload.endDate = $moment(end);
        if (control) {
          payload.controlDate = $moment(control);
        }
      } else {
        payload.repeatCron = cron;
      }
      return payload;
    },
    init() {
      this.runPreloader();
      const {$route: {params}, $store} = this;
      return $store.dispatch(`requests_getRequestById`, params.id)
          .then(res => {
            const {
              name,
              description,
              status,
              Author,
              building_process,
              files,
              executors,
              Building,
              Inspector,
              causes,
              bp
            } = res;
            this.name = name;
            this.description = description;
            this.savedStatus = status;
            this.status = status;
            this.author = Author;
            this.inspector = Inspector;
            this.files = files;
            this.executors = this.users.filter(el => executors.some(e => e.user_id === el.user_id));
            this.causes = causes;
            this.bp = bp;
            if (building_process) {
              const f = this.objects.find(el => el.building_process_id === building_process);
              const b = Building && Building.bo ? {object_name: Building.bo.name} : null;
              this.object = f || b;
            }
          })
          .then(this.getComments)
          .then(comments => {
            this.comments = comments;
            return Promise.resolve();
          })
          .then(this.getLogs)
          .then(logs => {
            this.logs = logs;
            return Promise.resolve();
          });
    },
    uploadFile(file) {
      this.uploadFilesInProcess = true;
      let fd = new FormData();
      fd.append(`file`, file)
      this.$store.dispatch(`uploadTaskFile`, {payload: fd})
          .then(file_ => this.files.push(file_))
          .finally(() => this.uploadFilesInProcess = false);
    },
    addExecutor(data) {
      this.executors.push(data);
      //Добавим Помощника к задаче
    },
    removeExecutor(data) {
      console.log(data);
      this.executors = this.executors.filter(el => el.user_id !== data.user_id);
      //Удалим Помощника из задачи
    },
    onDateVariantChange(v) {
      this.dateVariant = v;
    },
    onEndChange(v) {
      this.end = v;
    },
    onControlChange(v) {
      this.control = v;
    },
    onCronChanged(v) {
      this.cron = v;
    },
    onAddComment(v) {
      const {$route: {params}} = this;
      this.$store.dispatch(`requests_addComment`, {
        requestId: Number(params.id),
        value: v.value,
        files: v.files,
        isReportComment: v.isReportComment
      })
          .then(this.getComments)
          .then(comments => {
            this.comments = comments;
            return Promise.resolve();
          })
          .then(this.getLogs)
          .then(logs => {
            this.logs = logs;
            return Promise.resolve();
          });
    },
    onRemoveComment(id) {
      const {$store, init, $route: {params}} = this;
      $store.dispatch(`requests_deleteComment`, {id, request: {id: Number(params.id)}}).then(init);
    },
    onEditComment({id, text}) {
      const {$store, init, $route: {params}} = this;
      $store.dispatch(`requests_editComment`, {
        id,
        payload: {value: text, request: {id: Number(params.id)}}
      }).then(init);
    },
    onRemoveFile(payload) {
      this.$store.dispatch(`deleteFile`, payload)
          .then(() => {
            this.files = this.files.filter(el => el.id !== payload.id);
            return Promise.resolve();
          })
          .then(this.getComments)
          .then(comments => {
            this.comments = comments;
            return Promise.resolve();
          })
          .then(this.getLogs)
          .then(logs => {
            this.logs = logs;
            return Promise.resolve();
          });
    },
    getComments() {
      this.runPreloader()
      const {$route: {params}} = this;
      return this.$store.dispatch(`requests_getComments`, params.id).finally(this.stopPreloader);
    },
    getLogs() {
      this.runPreloader()
      const {$route: {params}} = this;
      return this.$store.dispatch(`requests_getLogs`, params.id).finally(this.stopPreloader);
    },
    runPreloader() {
      this.$store.commit(`setOnProcess`, true);
    },
    stopPreloader() {
      this.$store.commit(`setOnProcess`, false);
    },
    showErrorModal() {
      this.$bvModal.msgBoxOk(`Произошла ошибка сервера. Перезагрузите страницу.`, {
        okVariant: `danger`
      })
    },
    onRightClick({e, t}) {
      const {isTelegramWebApps, isMobile} = this;
      if (isTelegramWebApps && !isMobile) {
        this.rightClickTarget = t.$el;
        this.indexSelectionStart = document.activeElement.selectionStart;
        this.indexSelectionEnd = document.activeElement.selectionEnd;
        this.selection = window.getSelection().toString();
        this.toastX = `${e.layerX}px`;
        this.toastY = `${e.layerY}px`;
        e.preventDefault();
        this.showContextMenu = true;
      }
    },
    closeContextMenu() {
      this.showContextMenu = false;
      this.selection = null;
    },
    async onCopyClick() {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(this.selection);
      }
    },
    async onPasteClick() {
      if (navigator.clipboard && window.isSecureContext) {
        let element = this.rightClickTarget;
        let origString = element.value;
        let stringToAdd = await navigator.clipboard.readText();
        const {indexSelectionStart, indexSelectionEnd} = this;
        const text = origString.slice(0, indexSelectionStart) + stringToAdd + origString.slice(indexSelectionEnd);
        Vue.set(element, 'value', text);
        element.dispatchEvent(new Event('input'))
      }
    },
    onEditClick() {
      this.goToEditMasterPage();
    },
    onRepeatClick() {
      this.goToEditMasterPage();
    },
    goToEditMasterPage() {
      const {$router, $route: {params}} = this;
      $router.push(`/requests/delete-shift-report/create?request=${params.id}`);
    },
    getStatusText() {
      const {status} = this;
      switch (status) {
        case 0:
          return `Черновик`;
        case 1:
          return `На согласовании`;
        case 2:
          return `Согласована и исполнена`;
        case 3:
          return `Отказано`;
        case 4:
          return `Аннулирована`;
        default:
          return ``;
      }
    },
    getStatusVariant() {
      const {status} = this;
      switch (status) {
        case 0:
          return 'secondary';
        case 1:
          return 'warning';
        case 2:
          return 'success';
        case 3:
          return 'danger';
        case 4:
          return 'danger';
        default:
          return 'secondary';
      }
    }
  }
}
</script>

<style lang="scss">
.btn.dropdown-toggle-no-caret {
  padding: 0 5px;
  border: 0;
  box-shadow: none !important;
  font-size: 14px;
  color: black;
  text-decoration: none;
}

.dateVariant {
  position: absolute !important;
  top: 15px;
  right: 0;
}
</style>
