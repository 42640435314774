export default {
    mounted() {
        this.$http.interceptors.response.use(
            res => {
                return Promise.resolve(res);
            },
            async err => {
                const originalRequest = err.config;
                if (err.response.status === 401 && err.response.data === `jwt expired` && !originalRequest._retry) {
                    originalRequest._retry = true;
                    const {accessToken, refreshToken} = await this.$store.dispatch(`refreshToken`, {vm: this});
                    if (!accessToken) {
                        this.$store.commit(`removeToken`);
                        this.$store.commit(`removeRefreshToken`);
                        this.$router.go(`/login`);
                        return;
                    }
                    this.$store.commit(`setToken`, accessToken);
                    this.$store.commit(`setRefreshToken`, refreshToken);
                    this.$http.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                    originalRequest.headers.Authorization = `Bearer ${accessToken}`;
                    return this.$http(originalRequest);
                }
                return Promise.reject(err);
            }
        );
    }
}