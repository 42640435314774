<template>
    <div>
        <div class="d-flex my-2 justify-content-between">
            <b-input-group size="sm">
                <b-form-input
                        v-model="filter"
                        debounce="200"
                        type="search"
                        id="filterInput"
                        placeholder="Введите для поиска"
                ></b-form-input>
            </b-input-group>
            <span class="text-success small mx-2">Найдено записей: {{ totalRows }}</span>
            <b-col sm="7" md="2" class="my-1">
                <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                ></b-pagination>
            </b-col>
            <b-col sm="5" md="3" class="my-1">
                <b-form-group
                        label="На странице"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="4"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="perPageSelect"
                        class="mb-0"
                >
                    <b-form-select
                            v-model="perPage"
                            id="perPageSelect"
                            size="sm"
                            :options="pageOptions"
                    ></b-form-select>
                </b-form-group>
            </b-col>
        </div>
        <b-table
                :fields="fields"
                :filter="filter"
                small
                striped
                :items="items"
                :current-page="currentPage"
                :per-page="perPage"
                @filtered="onFiltered"
                :busy="isBusy"
        >
            <template v-slot:cell(availablePercents)="data">
                {{ data.item.availablePercents }}%
            </template>
            <template v-slot:cell(fact.percents)="data">
                {{ data.item.fact.percents }}%
            </template>
            <template v-slot:cell(limitError)="data">
                <b-form-checkbox
                        :id="`trigger-${data.index}`"
                        :checked="data.item.limitError"
                        disabled
                >
                </b-form-checkbox>
            </template>
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle mx-2"></b-spinner>
                    <strong>Загрузка данных...</strong>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
    export default {
        computed: {
            items() {
                return this.$store.state.materials.usage;
            }
        },
        data() {
            return {
                fields: [
                    {
                        key: `shift.id`,
                        label: `Ид смены`,
                        sortable: true
                    },
                    {
                        key: `shift.begin`,
                        label: `Дата и время начала смены`
                    },
                    {
                        key: `object.name`,
                        label: `Имя объекта`,
                        sortable: true
                    },
                    {
                        key: `work.name`,
                        label: `Имя работы`,
                        sortable: true
                    },
                    {
                        key: `work.measures`,
                        label: `ЕИ раб.`
                    },
                    {
                        key: `material.name`,
                        label: `Имя материала`,
                        sortable: true
                    },
                    {
                        key: `material.measures`,
                        label: `ЕИ мат.`
                    },
                    {
                        key: `targets.work`,
                        label: `Объем работ по договору`
                    },
                    {
                        key: `targets.material`,
                        label: `Объем материала по договору`
                    },
                    {
                        key: `completed.work`,
                        label: `Выполнено работ за смену`
                    },
                    {
                        key: `completed.material`,
                        label: `Затрачено материала за смену`
                    },
                    {
                        key: `plans.summary`,
                        label: `Планируемые затраты материала (без учёта отклонений прошлых смен)`
                    },
                    {
                        key: `deviation.perMeasures`,
                        label: `Отлонение в ЕИ мат.`
                    },
                    {
                        key: `summary.work`,
                        label: `Накопительный итог РАБОТА`
                    },
                    {
                        key: `summary.material`,
                        label: `Накопительный итог МАТЕРИАЛ`
                    },
                    {
                        key: `plans.perMeasures`,
                        label: `Планируемый расход (на ЕИ)`
                    },
                    {
                        key: `fact.value`,
                        label: `Реальный расход (на ЕИ)`
                    },
                    {
                        key: `availablePercents`,
                        label: `Граница тревоги (%)`
                    },
                    {
                        key: `fact.percents`,
                        label: `Фактическая погрешность (%)`
                    },
                    {
                        key: `limitError`,
                        label: `Срабатывание тригера`
                    }
                ],
                filter: null,
                filterOn: [],
                perPage: 100,
                pageOptions: [5, 10, 15, 20, 100, 200],
                totalRows: 1,
                currentPage: 1,
                isBusy: true
            }
        },
        async mounted() {
            await this.$store.dispatch(`getMaterialUsage`);
            this.totalRows = this.items.length;
            this.isBusy = false;
        },
        methods: {
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            }
        },
        name: "MaterialUsing"
    }
</script>

<style scoped>
    table {
        font-size: 12px;
    }

</style>