import Vue from '../core/Vue';
import Vuex from 'vuex';
import ajax from '../utils/ajax';
import jwt_decode from "jwt-decode";
import Materials from './Materials';
import Charts from './Charts'
import TasksManagement from './TasksManagement'
import DeleteShitReport from './Requests/DeleteShiftReport';
import Rules from "@/utils/Abilities/rules";
import {AbilityBuilder} from "@casl/ability";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        materials: Materials,
        charts: Charts,
        tasksManagement: TasksManagement,
        deleteShiftReport: DeleteShitReport
    },
    actions: {
        async initializeApp({commit, getters, dispatch}) {
            if (getters.isTelegramWebApps) {
                const payload = {initData: global?.Telegram?.WebApp?.initData};
                await dispatch(`login`, payload);
                commit(`setAppInitialized`, true);
            } else {
                commit(`setAppInitialized`, true);
            }
        },
        changeUserActive(_, {id, active}) {
            return ajax.changeUserActive(id, {active});
        },
        updateAbility({getters}, ctx) {
            const {rules} = Rules(new AbilityBuilder(), getters.currentUser);
            ctx.$ability.update(rules);
        },
        abortWizard({dispatch, state}) {
            const {createdObject, createdStaff, createdCurator} = state.wizard;
            if (createdObject) {
                dispatch(`deleteObject`, createdObject?.building_id);
            }
            if (createdStaff) {
                dispatch(`deleteUser`, createdStaff?.user_id);
            }
            if (createdCurator) {
                dispatch(`deleteUser`, createdCurator?.user_id);
            }
            dispatch(`clearWizard`);
        },
        clearWizard({state}) {
            state.wizard.selectedCurator = null;
            state.wizard.chatId = null;
            state.wizard.createdObject = null;
            state.wizard.createdStaff = null;
            state.wizard.createdCurator = null;
            state.wizard.folder = null;
            state.wizard.selectedObject = null;
            state.wizard.selectedStaff = null;
            state.wizard.targets = [];
            state.wizard.time = {
                HH: '09',
                mm: '00'
            };
            state.wizard.contract = {
                name: null,
                date: null
            }
        },
        async changeActivityObjectInProcess(_, {building_process_id, value}) {
            return await ajax.changeActivityObjectInProcess(building_process_id, value).then(res => res.data);
        },
        async changeNotificationObjectInProcess(_, {building_process_id, value}) {
            return await ajax.changeNotificationObjectInProcess(building_process_id, value).then(res => res.data);
        },
        async createObject(_, data) {
            return await ajax.createObject(data);
        },
        async createObjectsInProcess(_, data) {
            return await ajax.createObjectsInProcess(data);
        },
        async createSystemUser(_, user) {
            const userId = user.user_id;
            await ajax.createSystemUser(user).then(async () => {
                for (const role of user.roles) {
                    await ajax.addRoleToUser(userId, role);
                }
            })
        },
        async createTarget(_, data) {
            return await ajax.createTarget(data);
        },
        createUserGroup(_, payload) {
            return ajax.createUserGroup(payload);
        },
        createTelegramGroup(_, payload) {
            return ajax.createTelegramGroup(payload);
        },
        addUsersToTelegram(_, {id, payload}) {
            return ajax.addUsersToTelegram(id, payload);
        },
        async createWorkType(_, data) {
            return await ajax.createWorkType(data);
        },
        async createWorkGroup(_, data) {
            return await ajax.createWorkGroup(data);
        },
        async createUser(_, user) {
            return await ajax.createUser(user).then(res => res.data);
        },
        async deleteSystemUser(_, user) {
            return new Promise(async (resolve, reject) => {
                user.login = null;
                user.password = null;
                user.user_id = user.userId;
                await ajax.deleteSystemUser(user.userId).catch((err) => reject(err));
                await ajax.deleteRolesByUser(user.userId).catch((err) => reject(err));
                resolve();
            })
        },
        async deleteObject(_, objectId) {
            return await ajax.deleteObject(objectId);
        },
        async deleteObjectInProcess(_, processId) {
            return await ajax.deleteObjectInProcess(processId);
        },
        async deleteRolesByUserId(_, userId) {
            return ajax.deleteRolesByUserId(userId);
        },
        async deleteTarget(_, data) {
            return await ajax.deleteTarget(data);
        },
        async deleteUser(_, userId) {
            return await ajax.deleteUser(userId);
        },
        deleteUserGroup(_, id) {
            return ajax.deleteUserGroup(id);
        },
        async deleteWorkType(_, workId) {
            return await ajax.deleteWorkType(workId);
        },
        async editObject(_, data) {
            const {objectId} = data;
            return await ajax.editObject(objectId, data);
        },
        async editObjectInProcess(_, data) {
            const {building_process_id} = data;
            return await ajax.editObjectInProcess(building_process_id, data);
        },
        async editTarget(_, data) {
            return await ajax.editTarget(data);
        },
        async editUser(_, data) {
            const {userId} = data;
            return await ajax.updateUser(userId, data);
        },
        editUserGroup(_, {groupId, payload}) {
            return ajax.editUserGroup(groupId, payload);
        },
        async editSystemUser(_, data) {
            const {user_id} = data;
            return await ajax.editSystemUser(user_id, data).then(async () => {
                await ajax.deleteRolesByUserId(user_id);
                for (const role of data.roles) {
                    await ajax.addRoleToUser(user_id, role);
                }
            });
        },
        async editWorkType(_, data) {
            const {work_id} = data;
            return await ajax.editWorkType(work_id, data);
        },
        async editWorkGroup(_, {id, data}) {
            return await ajax.editWorkGroup(id, data);
        },
        async getCompletedWorks({commit}) {
            await ajax.getCompletedWorks().then(data => commit(`setCompletedWorks`, data));
        },
        async getCompletedWorksMainAndSeconds({commit, state}) {
            const data = await ajax.getCompletedWorksWithSecondWorks(state.showOnlyArchive);
            commit(`setCompletedWorksMainAndSeconds`, data);
            return data;
        },
        getUserGroups() {
            return ajax.getUserGroups();
        },
        async getFuelLimits({commit, state}) {
            await ajax.getFuelLimits(state.showOnlyArchive).then(data => commit(`setFuelLimits`, data));
        },
        async getImages(_, {limit, skip, query_building}) {
            return await ajax.getImages(limit, skip, query_building);
        },
        async getImagesForBuilding(_, {id, limit, skip}) {
            return await ajax.getImagesForBuilding(id, limit, skip)
        },
        async getImage(_, id) {
            return await ajax.getImage(id);
        },
        async getMiniImage(_, id) {
            return await ajax.getMiniImage(id);
        },
        async getObjects({commit}) {
            await ajax.getObjects().then(data => commit(`setObjects`, data));
        },
        async getObjectsInProcess({commit, state}) {
            await ajax.getObjectsInProcess(state.showOnlyArchive).then(data => commit(`setObjectsInProcess`, data));
        },
        async getSecondWorks({commit}) {
            await ajax.getSecondWorks().then(data => commit(`setSecondWorks`, data));
        },
        async getRoles({commit}) {
            commit(`setRoles`, await ajax.getRoles());
        },
        async getSystemUsers({commit}) {
            await ajax.getSystemUsers().then(users => {
                commit(`setSystemUsers`, users);
            })
        },
        async getSystemUser(_, userId) {
            return await ajax.getSystemUser(userId).then(res => res.data[0]);
        },
        async getTargets({commit, state}) {
            await ajax.getTargets(state.showOnlyArchive).then(data => commit(`setTargets`, data));
        },
        async login({commit, dispatch}, payload) {
            return await ajax.login(payload).then(res => {
                const {accessToken, refreshToken} = res.data;
                commit('setToken', accessToken);
                commit('setRefreshToken', refreshToken);
                dispatch('setAuthorizationHeader', accessToken);
                return Promise.resolve();
            }).catch(res => {
                commit('removeToken');
                return Promise.reject(res);
            });
        },
        setAuthorizationHeader(_, token) {
            ajax.setHeaderAuth(token);
        },
        async refreshToken(_, {vm}) {
            return await vm.$http.post('/auth/refresh', {
                refreshToken: localStorage.getItem('refreshToken')
            }).then(res => {
                return Promise.resolve(res.data);
            }).catch(res => {
                return Promise.reject(res);
            });
        },
        async getUsedFuels({commit, dispatch, state}) {
            await ajax.getUsedFuels(state.showOnlyArchive)
                .then(data => commit(`setUsedFuels`, data))
                .catch(res => dispatch(`showErrorModal`, res.response.data));
        },
        async getUsers({commit}, data) {
            commit(`setUsers`, await ajax.getUsers(data?.full));
        },
        async getWorkShifts({commit, state}) {
            await ajax.getWorkShifts(state.showOnlyArchive).then(data => {
                commit(`setWorkShifts`, data);
            });
        },
        async getWorkTypes({commit}) {
            await ajax.getWorkTypes().then(data => commit(`setWorkTypes`, data));
        },
        async getWorkGroups({commit}) {
            await ajax.getWorkGroups().then(data => commit(`setWorkGroups`, data));
        },
        hideErrorModal({commit}) {
            commit(`setErrorModalText`, null);
            commit(`setShowErrorModal`, false);
        },
        async restartNotifications() {
            await ajax.restartNotifications();
        },
        showErrorModal({commit}, text) {
            commit(`setErrorModalText`, text);
            commit(`setShowErrorModal`, true);
        },
        getAvailableReportsManagement() {
            return ajax.getAvailableReportsManagement();
        },
        getActiveReports() {
            return ajax.getActiveReports();
        },
        fillReportsTableByCurrentProcesses(_, reportId) {
            return ajax.fillReportsTableByCurrentProcesses(reportId);
        },
        deleteActiveReport(_, id) {
            return ajax.deleteActiveReport(id);
        },
        deleteActiveReportByProcess(_, id) {
            return ajax.deleteActiveReportByProcess(id);
        },
        runReportsForcibly() {
            return ajax.runReportsForcibly();
        },
        createReportManagement(_, building_process_id) {
            const payload = {
                building_process_id,
                reportId: 1
            }
            return ajax.createReportManagement(payload)
        },
        getAvailableAlertsManagement() {
            return ajax.getAvailableAlertsManagement();
        },
        getActiveAlerts() {
            return ajax.getActiveAlerts();
        },
        fillAlertsTableByCurrentProcesses(_, alertId = 0) {
            return ajax.fillAlertsTableByCurrentProcesses(alertId);
        },
        deleteActiveAlert(_, id) {
            return ajax.deleteActiveAlert(id);
        },
        deleteActiveAlertByProcess(_, id) {
            return ajax.deleteActiveAlertByProcess(id);
        },
        createAlertManagement(_, building_process_id) {
            const payload = {
                building_process_id,
                alertId: 0
            }
            return ajax.createAlertManagement(payload)
        },
        sendDatePicker(_, payload) {
            return ajax.sendDatePicker(payload);
        },
        onScreenResize({commit}) {
            commit(`setIsMobile`, window.innerWidth < 576);
        }
    },
    getters: {
        isAdmin({token}) {
            if (token) {
                const {roles} = jwt_decode(token);
                return roles.includes(`Admin`)
            } else {
                return false;
            }
        },
        isAuthorized({token}) {
            if (token) {
                const {exp} = jwt_decode(token);
                const current_time = new Date().getTime() / 1000;
                return current_time < exp;
            }
        },
        currentUser({token}) {
            if (token) {
                const {firstName, lastName, userId, login, phone, roles} = jwt_decode(token);
                return {
                    firstName,
                    lastName,
                    userId,
                    login,
                    phone,
                    roles
                }
            }
        },
        isTelegramWebApps() {
            if (global.Telegram) {
                const {Telegram: {WebApp: {initDataUnsafe}}} = global;
                return Boolean(Object.keys(initDataUnsafe).length) && initDataUnsafe.constructor === Object;
            } else {
                return false;
            }
        },
        isMobile() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },
        selection() {
            return global.getSelection()?.toString();
        }
    },
    state: {
        appInitialized: false,
        completedWorks: [],
        completedWorksMainAndSeconds: [],
        errorModalText: null,
        fuelLimits: [],
        images: [
            `/images/1`,
            `/images/1`,
            `/images/1`,
            `/images/1`,
            `/images/1`,
            `/images/1`,
            `/images/1`,
            `/images/1`,
            `/images/1`,
            `/images/1`,
        ],
        objects: [],
        objectsInProcess: [],
        refreshToken: null,
        token: localStorage.getItem('token') || '',
        roles: [],
        secondWorks: [],
        showOnlyArchive: false,
        systemUsers: [],
        showErrorModal: false,
        targets: [],
        usedFuels: [],
        user: {},
        users: [],
        workShifts: [],
        workTypes: [],
        workGroups: [],
        wizard: {
            selectedCurator: null,
            chatId: null,
            createdObject: null,
            createdStaff: null,
            createdCurator: null,
            folder: null,
            selectedObject: null,
            selectedStaff: null,
            targets: [],
            time: {
                HH: '09',
                mm: '00'
            },
            contract: {
                name: null,
                date: null
            }
        },
        isMobile: window.innerWidth < 576,
        onProcess: false
    },
    mutations: {
        setAppInitialized(state, value) {
            state.appInitialized = value;
        },
        removeRefreshToken(state) {
            localStorage.removeItem('refreshToken');
            state.refreshToken = null;
        },
        removeToken(state) {
            localStorage.removeItem('token');
            state.token = null;
            document.cookie = `token=`;
        },
        setCompletedWorks(state, value) {
            state.completedWorks = value;
        },
        setCompletedWorksMainAndSeconds(state, value) {
            state.completedWorksMainAndSeconds = value;
        },
        setErrorModalText(state, value) {
            state.errorModalText = value;
        },
        setFuelLimits(state, value) {
            state.fuelLimits = value;
        },
        setImages(state, value) {
            state.images = value;
        },
        setObjects(state, value) {
            state.objects = value;
        },
        setObjectsInProcess(state, value) {
            state.objectsInProcess = value;
        },
        setSecondWorks(state, value) {
            state.secondWorks = value;
        },
        setShowOnlyArchive(state, value) {
            state.showOnlyArchive = value;
        },
        setSystemUsers(state, value) {
            state.systemUsers = value;
        },
        setShowErrorModal(state, value) {
            state.showErrorModal = value;
        },
        setTargets(state, value) {
            state.targets = value;
        },
        setToken(state, token) {
            localStorage.setItem('token', token);
            state.token = token;
            document.cookie = `token=${token}`;
        },
        setRefreshToken(state, refreshToken) {
            localStorage.setItem('refreshToken', refreshToken);
            state.refreshToken = refreshToken;
        },
        setRoles(state, value) {
            state.roles = value;
        },
        setUsedFuels(state, value) {
            state.usedFuels = value;
        },
        setUsers(state, value) {
            state.users = value;
        },
        setWorkShifts(state, value) {
            state.workShifts = value;
        },
        setWorkTypes(state, value) {
            state.workTypes = value;
        },
        setWorkGroups(state, value) {
            state.workGroups = value;
        },
        setWizardCreatedObject(state, value) {
            state.wizard.createdObject = value;
        },
        setWizardSelectedObject(state, value) {
            state.wizard.selectedObject = value;
        },
        setWizardSelectedStaff(state, value) {
            state.wizard.selectedStaff = value;
        },
        setWizardSelectedCurator(state, value) {
            state.wizard.selectedCurator = value;
        },
        setWizardCreatedStaff(state, value) {
            state.wizard.createdStaff = value;
        },
        setWizardCreatedCurator(state, value) {
            state.wizard.createdCurator = value;
        },
        setWizardFolder(state, value) {
            state.wizard.folder = value;
        },
        setWizardTime(state, value) {
            state.wizard.time = value;
        },
        setWizardChatId(state, value) {
            state.wizard.chatId = value;
        },
        setWizardTargets(state, value) {
            state.wizard.targets = value;
        },
        setWizardContractName(state, value) {
            state.wizard.contract.name = value;
        },
        setWizardContractDate(state, value) {
            state.wizard.contract.date = value;
        },
        setIsMobile(state, value) {
            state.isMobile = value
        },
        setOnProcess(state, value) {
            state.onProcess = value;
        }
    }
})